<template>
  <v-container
    @click.stop.prevent
    fluid
    class="pa-0 rounded-lg"
    style="height: 100% !important"
  >
    <template v-if="rightDrawer.data.products.length">
      <v-window
        v-model="rightDrawer.data.cartWindow"
        style="width: 100% !important"
        touchless
      >
        <v-window-item :value="0">
          <v-container
            fluid
            class="pa-0 rounded-lg"
            style="height: 100% !important"
          >
            <v-row
              dense
              class="d-flex mx-1 px-2 align-center justify-space-between"
            >
              <v-col cols="1" class="text-start pa-1 flex-grow-1"
                ><span
                  class="text-caption text-lg-body-2 font-weight-bold text--secondary"
                  style="line-height: 0.75rem !important"
                  >#</span
                ></v-col
              >
              <v-col
                :cols="editMode ? 4 : 5"
                class="text-start pa-1 flex-grow-1"
              >
                <span
                  class="text-caption text-lg-body-2 font-weight-bold text--secondary"
                  style="line-height: 0.75rem !important"
                  >Item</span
                >
              </v-col>
              <v-col
                :cols="editMode ? 2 : 1"
                :class="editMode ? 'text-end' : 'text-end'"
                class="pa-1 flex-grow-1"
              >
                <span
                  class="text-caption text-lg-body-2 font-weight-bold text--secondary"
                  style="line-height: 0.75rem !important"
                  >Qty</span
                >
              </v-col>
              <v-col
                cols="2"
                :class="editMode ? 'text-end' : 'text-end'"
                class="pa-1 flex-grow-1"
              >
                <span
                  class="text-caption text-lg-body-2 font-weight-bold text--secondary"
                  style="line-height: 0.75rem !important"
                  >Price</span
                >
              </v-col>
              <v-col cols="2" class="text-end pa-1 flex-grow-1">
                <v-fade-transition>
                  <span
                    v-if="!editMode"
                    class="text-caption text-lg-body-2 font-weight-bold text--secondary"
                    style="line-height: 0.75rem !important"
                    >Total</span
                  >
                </v-fade-transition>
              </v-col>
            </v-row>
            <v-slide-y-transition group>
              <template v-for="(item, index) in products">
                <v-row
                  :key="`${item.id}_${item.promo}_${item.discountApplied}`"
                  class="d-flex mx-1 px-2 rounded-lg my-1 align-center justify-space-between v-row--dense"
                  :style="
                    item.promo
                      ? `background: #4a20ff24; color: #7f32a8 !important`
                      : item.sample
                      ? `background: #8adcff24; color: #0088ff !important`
                      : ''
                  "
                  group
                  :class="item.promo || item.sample ? `` : ''"
                >
                  <v-col cols="1" class="text-start pa-1 flex-grow-1"
                    ><span
                      class="text-caption text-lg-body-2 font-weight-medium"
                      style="line-height: 0.75rem !important"
                      >{{ index + 1 }}</span
                    ></v-col
                  >
                  <v-col
                    :cols="editMode ? 4 : 5"
                    class="text-start d-flex align-center justify-start pa-1 flex-grow-1"
                    style="gap: 8px"
                  >
                    <v-avatar
                      v-if="!item.imageSrc"
                      color="grey lighten-3"
                      :size="$vuetify.breakpoint.width < 500 ? 20 : 24"
                      tile
                      style="border: solid 1px #dedede !important"
                      class="rounded d-flex align-center justify-center"
                    >
                      <v-icon style="font-size: 10px !important"
                        >mdi-image</v-icon
                      >
                    </v-avatar>
                    <v-avatar
                      v-else
                      :size="$vuetify.breakpoint.width < 500 ? 20 : 24"
                      tile
                      style="border: solid 1px #dedede"
                      class="rounded white"
                    >
                      <v-img
                        contain
                        :src="item.imageSrc"
                        :alt="`${$options.filters.capitalize(
                          item.name
                        )} Product Image`"
                      >
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height white ma-0"
                            align="center"
                            justify="center"
                          >
                            <v-progress-circular
                              indeterminate
                              size="10"
                              width="2"
                              color="grey darken-2"
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-avatar>
                    <div
                      class="d-flex flex-column align-start justify-center"
                      style="gap: 4px"
                    >
                      <span
                        class="text-caption text-lg-body-2 d-flex font-weight-bold"
                        style="line-height: 0.75rem !important"
                        >{{ item.name | capitalize }}</span
                      >
                      <span
                        class="text-caption text-lg-body-2 d-flex font-weight-bold"
                        style="line-height: 0.75rem !important; opacity: 0.6"
                        :style="
                          $vuetify.breakpoint.mdAndDown
                            ? 'font-size: 10px !important'
                            : 'font-size: 12px !important'
                        "
                        >{{ item.category | capitalize }}</span
                      >
                      <span
                        class="text-caption text-lg-body-2 d-flex font-weight-normal"
                        style="line-height: 0.75rem !important"
                        :style="
                          $vuetify.breakpoint.mdAndDown
                            ? 'font-size: 10px !important'
                            : 'font-size: 12px !important'
                        "
                        >{{
                          item.qty.single > 0 && item.qty.case > 0
                            ? "Cases & Singles"
                            : item.qty.case > 0
                            ? "Cases"
                            : "Singles" | capitalize
                        }}</span
                      >
                    </div>
                  </v-col>
                  <v-col
                    :cols="editMode ? 2 : 1"
                    :class="editMode ? 'text-end' : 'text-end'"
                    class="pa-1 flex-grow-1"
                  >
                    <v-menu
                      top
                      :nudge-left="100"
                      rounded="lg"
                      transition="slide-y-reverse-transition"
                      :close-on-content-click="false"
                      color="white"
                      v-model="item.menu.qty"
                      class="menuParent"
                    >
                      <template v-slot:activator="{ on, value }">
                        <div
                          class="d-flex justify-end align-center"
                          style="gap: 4px"
                          :style="editMode ? 'cursor: pointer' : ''"
                          @click="
                            item.unitWindow = 1;
                            openQTY(item);
                          "
                        >
                          <v-slide-x-transition leave-absolute hide-on-leave>
                            <v-icon
                              v-if="editMode"
                              color="blue darken-1"
                              :style="
                                $vuetify.breakpoint.width < 500
                                  ? `font-size: 10px`
                                  : `font-size: 14px`
                              "
                            >
                              mdi-pencil
                            </v-icon>
                          </v-slide-x-transition>
                          <span>
                            <span
                              class="d-flex align-center text--primary text-caption text-lg-body-2 text-no-wrap justify-end"
                            >
                              <span
                                :class="
                                  item.promo
                                    ? 'font-weight-bold'
                                    : !item.totalQTY
                                    ? 'font-weight-black red--text text--lighten-1'
                                    : 'font-weight-medium'
                                "
                                :style="
                                  item.promo
                                    ? 'color: #9421d1FF !important'
                                    : item.sample
                                    ? `color: #0088ff !important`
                                    : ''
                                "
                                v-text="
                                  $options.filters.addComma(
                                    item.qty.single +
                                      item.caseQTY * item.qty.case
                                  )
                                "
                              />
                            </span>
                          </span>
                        </div>
                      </template>
                      <v-window
                        v-model="item.unitWindow"
                        style="width: 100%"
                        class="white"
                      >
                        <v-window-item :value="1">
                          <div
                            class="d-flex flex-column white align-center pa-4 justify-center"
                            style="width: 100%; row-gap: 12px"
                          >
                            <div
                              class="d-flex flex-column text-body-1 font-weight-black align-center justify-space-between"
                            >
                              <v-sheet
                                class="d-flex flex-column align-center justify-center"
                                @click="
                                  item.unitWindow = 2;
                                  openQTY(item);
                                "
                                style="cursor: pointer"
                                ><span
                                  class="text-title text-center font-weight-medium"
                                  >Total Units</span
                                >
                                <span
                                  class="d-flex align-center text--disabled text-caption text-no-wrap justify-end justify-md-start"
                                  style="font-size: x-small !important"
                                >
                                  <span
                                    class="text-body-1 text-sm-h6 font-weight-black black--text"
                                    v-text="
                                      $options.filters.addComma(
                                        item.qty.single +
                                          item.caseQTY * item.qty.case
                                      )
                                    "
                                  />
                                </span>
                                <span
                                  class="text-caption text-md-body-2 blue--text text--darken-1 font-weight-medium"
                                  style="
                                    font-size: 10px !important;
                                    line-height: 0.85rem !important;
                                  "
                                  >Edit</span
                                >
                              </v-sheet>
                            </div>
                            <div style="width: 100%" class="d-flex">
                              <v-divider />
                            </div>
                            <div
                              class="d-flex flex-column justify-center align-center"
                              style="row-gap: 12px"
                            >
                              <div
                                class="d-flex justify-space-between align-center"
                                style="width: 100%; column-gap: 8px"
                              >
                                <v-btn
                                  text
                                  small
                                  :disabled="
                                    item.qty.single +
                                      item.caseQTY * item.qty.case -
                                      1 <
                                    0
                                  "
                                  color="primary"
                                  @click="decrement('single', item)"
                                >
                                  <v-icon small dark> mdi-minus </v-icon>
                                </v-btn>
                                <div
                                  class="d-flex flex-column align-center justify-space-between"
                                >
                                  <span class="font-weight-medium">{{
                                    item.qty.single
                                  }}</span>
                                  <span
                                    class="text-caption font-weight-bold text--disabled"
                                    >Single Unit{{
                                      item.qty.single === 1 ? "" : "s"
                                    }}</span
                                  >
                                </div>
                                <v-btn
                                  text
                                  small
                                  color="primary"
                                  @click="increment('single', item)"
                                >
                                  <v-icon small dark> mdi-plus </v-icon>
                                </v-btn>
                              </div>
                              <template
                                v-if="orderType === 'sales' && item.caseQTY"
                              >
                                <div
                                  class="d-flex justify-space-between align-center"
                                  style="width: 100%; column-gap: 8px"
                                >
                                  <v-btn
                                    text
                                    small
                                    :disabled="item.qty.case - 1 < 0"
                                    color="primary"
                                    @click="decrement('case', item)"
                                  >
                                    <v-icon small dark> mdi-minus </v-icon>
                                  </v-btn>
                                  <div
                                    class="d-flex flex-column align-center justify-space-between"
                                  >
                                    <span class="font-weight-medium">{{
                                      item.qty.case
                                    }}</span>
                                    <span
                                      class="text-caption font-weight-bold text--disabled"
                                      >Case{{
                                        item.qty.case === 1 ? "" : "s"
                                      }}</span
                                    >
                                  </div>
                                  <v-btn
                                    text
                                    small
                                    color="primary"
                                    @click="increment('case', item)"
                                  >
                                    <v-icon small dark> mdi-plus </v-icon>
                                  </v-btn>
                                </div>
                                <span
                                  class="text-caption text-center font-weight-medium"
                                  >1 Case = {{ item.caseQTY }} Units</span
                                >
                              </template>
                            </div>
                          </div>
                        </v-window-item>
                        <v-window-item :value="2">
                          <div
                            class="d-flex white align-center justify-center pa-4"
                            style="max-width: fit-content !important"
                          >
                            <div
                              class="d-flex flex-column align-center justify-center"
                              style="width: 100%; row-gap: 12px"
                            >
                              <span class="d-flex text-title font-weight-medium"
                                >Units</span
                              >
                              <div
                                class="d-flex flex-column align-center justify-center"
                                style="row-gap: 6px"
                              >
                                <v-text-field
                                  single-line
                                  outlined
                                  ref="qtyInput"
                                  placeholder="0"
                                  persistent-placeholder
                                  type="number"
                                  min="0"
                                  step="1"
                                  inputmode="decimal"
                                  v-model.number="item.newQTY"
                                  :color="$vuetify.theme.themes.dark.primary"
                                  dense
                                  hide-spin-buttons
                                  hide-details
                                  @wheel.prevent.self
                                  style="min-width: 100px"
                                  @keydown.esc.stop="
                                    item.unitWindow = 1;
                                    item.newQTY = item.totalQTY;
                                  "
                                  @keydown.enter="
                                    handleProductQTYChange(
                                      item,
                                      Number(
                                        String(item.newQTY).replace('.', '')
                                      )
                                    )
                                  "
                                >
                                </v-text-field>
                              </div>
                              <div
                                class="d-flex justify-space-between align-center"
                                style="width: 100%; column-gap: 8px"
                              >
                                <v-btn
                                  text
                                  small
                                  color="red"
                                  @click="item.unitWindow = 1"
                                >
                                  cancel
                                </v-btn>
                                <v-btn
                                  text
                                  small
                                  color="blue"
                                  :disabled="item.newQTY === null"
                                  @click="
                                    handleProductQTYChange(
                                      item,
                                      Number(
                                        String(item.newQTY).replace('.', '')
                                      )
                                    );
                                    item.menu.qty = false;
                                  "
                                >
                                  save
                                </v-btn>
                              </div>
                            </div>
                          </div>
                        </v-window-item>
                      </v-window>
                    </v-menu>
                  </v-col>
                  <v-col
                    cols="2"
                    :class="editMode ? 'text-end' : 'text-end'"
                    class="pa-1 flex-grow-1"
                  >
                    <v-menu
                      top
                      :nudge-left="80"
                      :nudge-bottom="100"
                      rounded="lg"
                      transition="slide-y-transition"
                      :close-on-content-click="false"
                      color="white"
                      v-model="item.menu.price"
                    >
                      <template v-slot:activator="{ on, value }">
                        <div
                          class="d-flex flex-column align-end justify-center"
                          @click="openPrice(item)"
                          style="min-width: 40px !important; width: 100%"
                          :style="
                            editMode && !item.promo && !item.sample
                              ? 'cursor: pointer'
                              : ''
                          "
                        >
                          <div
                            class="d-flex justify-end align-center"
                            style="gap: 4px"
                          >
                            <v-slide-x-transition leave-absolute hide-on-leave>
                              <v-icon
                                v-if="
                                  orderType === 'sales' &&
                                  !item.promo &&
                                  editMode
                                "
                                color="blue darken-1"
                                :style="
                                  $vuetify.breakpoint.width < 500
                                    ? `font-size: 10px`
                                    : `font-size: 14px`
                                "
                              >
                                mdi-pencil
                              </v-icon>
                            </v-slide-x-transition>
                            <span
                              class="d-flex flex-column justify-center align-center"
                            >
                              <span
                                class="d-flex align-center text--primary text-caption text-lg-body-2 text-no-wrap justify-end"
                              >
                                <span
                                  v-if="item.discountApplied"
                                  class="text-decoration-line-through text--disabled"
                                  :class="
                                    item.promo
                                      ? 'font-weight-bold'
                                      : 'font-weight-medium'
                                  "
                                  :style="
                                    item.promo
                                      ? 'color: #9421d1FF !important'
                                      : item.sample
                                      ? `color: #0088ff !important`
                                      : ''
                                  "
                                  v-text="$options.filters.currency(item.price)"
                                />
                              </span>
                              <span
                                class="d-flex align-center text--primary text-caption text-lg-body-2 text-no-wrap justify-end"
                              >
                                <span
                                  :class="
                                    item.promo
                                      ? 'font-weight-bold'
                                      : 'font-weight-medium'
                                  "
                                  :style="
                                    item.promo
                                      ? 'color: #9421d1FF !important'
                                      : item.sample
                                      ? `color: #0088ff !important`
                                      : item.discountApplied
                                      ? `color: #00695C !important`
                                      : ''
                                  "
                                  v-text="
                                    $options.filters.currency(
                                      item.discountApplied
                                        ? item.price -
                                            discountedValueOfItem(item)
                                        : item.price
                                    )
                                  "
                                />
                              </span>
                            </span>
                          </div>
                        </div>
                      </template>
                      <v-sheet class="elevation-3">
                        <div
                          class="d-flex flex-column white align-center pa-4 justify-center"
                          style="width: 100%; row-gap: 12px"
                        >
                          <div class="d-flex align-center justify-center">
                            <div
                              class="d-flex flex-column align-center justify-center"
                              style="row-gap: 12px"
                            >
                              <span class="d-flex text-title font-weight-medium"
                                >Price</span
                              >
                              <v-text-field
                                single-line
                                outlined
                                :placeholder="
                                  $options.filters
                                    .currency(item.originalPrice)
                                    .substring(1)
                                "
                                persistent-placeholder
                                :ref="`priceInput_${item.id}${
                                  item.promo ? '_promo' : ''
                                }`"
                                type="number"
                                min="1"
                                step=".01"
                                inputmode="decimal"
                                prefix="$"
                                v-model.number="item.newPrice"
                                :error="item.newPrice <= 0.01"
                                :color="$vuetify.theme.themes.dark.primary"
                                dense
                                hide-details
                                hide-spin-buttons
                                style="min-width: 125px !important"
                                @wheel.prevent.self
                                @keydown.enter="
                                  Number(item.newPrice) > 0.01
                                    ? (item.price = Number(item.newPrice))
                                    : '';
                                  item.menu.price = item.newPrice <= 0.01;
                                "
                              ></v-text-field>
                              <div
                                v-if="item.newPrice <= 0.01"
                                class="d-flex flex-column align-start text-caption red--text text--darken-1 justify-start"
                                style="width: 100%"
                              >
                                <div class="d-flex justify-start align-center">
                                  <v-icon
                                    small
                                    :color="$vuetify.theme.themes.dark.error"
                                    >mdi-alert</v-icon
                                  >
                                  <span
                                    class="ml-1 text-no-wrap font-weight-black"
                                    >Error: Price</span
                                  >
                                </div>
                                <span class="text-wrap"
                                  >The price of this product should be greater
                                  than $0.01.</span
                                >
                              </div>
                              <div
                                class="d-flex justify-space-between align-center"
                                style="width: 100%; column-gap: 8px"
                              >
                                <v-btn
                                  text
                                  small
                                  color="red"
                                  @click="closePrice(item, 'cancel')"
                                >
                                  cancel
                                </v-btn>
                                <v-btn
                                  text
                                  small
                                  color="blue"
                                  :disabled="item.newPrice <= 0.01"
                                  @click="closePrice(item, 'save')"
                                >
                                  save
                                </v-btn>
                              </div>
                            </div>
                          </div>
                        </div>
                      </v-sheet>
                    </v-menu>
                  </v-col>
                  <v-col
                    cols="2"
                    :class="editMode ? 'text-end' : 'text-end'"
                    class="pa-1 flex-grow-1"
                  >
                    <v-slide-x-reverse-transition
                      leave-absolute
                      group
                      hide-on-leave
                    >
                      <template v-if="!editMode">
                        <span
                          v-if="item.totalQTY"
                          class="text-caption text--primary text-lg-body-2"
                          style="line-height: 0.75rem !important"
                          :class="
                            item.promo || item.sample
                              ? 'font-weight-bold'
                              : 'font-weight-medium'
                          "
                          :key="`${item.id}${item.promo}_productRow`"
                          :style="
                            item.promo
                              ? 'color: #9421d1FF !important'
                              : item.sample
                              ? `color: #0088ff !important`
                              : ''
                          "
                          >{{
                            ((item.qty.single + item.caseQTY * item.qty.case) *
                              (item.discountApplied
                                ? item.price - discountedValueOfItem(item)
                                : item.price))
                              | currency
                          }}</span
                        >
                        <div
                          v-else
                          :key="`${item.id}${item.promo}_error`"
                          class="d-flex justify-end align-center"
                          style="gap: 6px"
                        >
                          <v-icon
                            color="red lighten-2"
                            :style="
                              $vuetify.breakpoint.width < 500
                                ? `font-size: 10px`
                                : `font-size: 14px`
                            "
                          >
                            mdi-alert
                          </v-icon>
                          <span
                            class="font-weight-black text-caption text-lg-body-2 red--text text--lighten-2 text-center"
                            >Error</span
                          >
                        </div>
                      </template>
                      <div
                        v-else
                        :key="`${item.id}${item.promo}_editActions`"
                        class="d-flex flex-no-wrap align-center justify-space-around"
                        style="gap: 4px"
                      >
                        <v-menu
                          offset-y
                          rounded="lg"
                          :nudge-bottom="8"
                          transition="scroll-y-transition"
                        >
                          <template v-slot:activator="{ on, attrs, value }">
                            <v-btn
                              v-on="on"
                              color="red lighten-2 white--text"
                              :xSmall="$vuetify.breakpoint.width < 500"
                              :small="$vuetify.breakpoint.width > 500"
                            >
                              <v-icon
                                :xSmall="$vuetify.breakpoint.width < 500"
                                :small="$vuetify.breakpoint.width > 500"
                                dark
                              >
                                mdi-trash-can
                              </v-icon>
                            </v-btn>
                          </template>
                          <v-container fluid class="white">
                            <div
                              dense
                              class="d-flex align-center flex-column"
                              style="gap: 12px"
                            >
                              <div
                                class="d-flex justify-center text--primary font-weight-medium text-body-2 text-sm-body-1"
                              >
                                Remove Item?
                              </div>
                              <div
                                class="d-flex justify-center"
                                style="gap: 12px"
                              >
                                <div class="d-flex justify-center">
                                  <v-btn small text color="blue darken-1">
                                    Cancel
                                  </v-btn>
                                </div>
                                <div class="d-flex justify-center">
                                  <v-btn
                                    small
                                    color="red lighten-2 white--text"
                                    @click="removeItem(item)"
                                  >
                                    Remove
                                  </v-btn>
                                </div>
                              </div>
                            </div>
                          </v-container>
                        </v-menu>
                      </div>
                    </v-slide-x-reverse-transition>
                  </v-col>
                  <v-col
                    cols="12"
                    v-if="!item.totalQTY"
                    class="d-flex justify-center pa-1"
                  >
                    <span
                      class="font-weight-medium text-caption text-lg-body-2 red--text text--lighten-2 text-center"
                      >Add units or remove this item to continue to
                      checkout.</span
                    >
                  </v-col>
                </v-row>
              </template>
            </v-slide-y-transition>
            <v-row
              dense
              class="d-flex mx-2 align-center justify-end mt-1 mt-sm-3 my-2"
            >
              <v-col cols="12" class="text-end">
                <v-divider class="mb-3"></v-divider>
                <div
                  class="d-flex justify-space-between align-center"
                  style="min-height: 28px"
                >
                  <div
                    class="d-flex flex-shrink-1 flex-grow-0 justify-space-between align-start"
                    style="width: 100% !important"
                  >
                    <v-slide-x-transition leave-absolute>
                      <div v-if="editMode" key="removeAll">
                        <v-menu
                          offset-y
                          top
                          rounded="lg"
                          :nudge-right="125"
                          transition="slide-x-transition"
                        >
                          <template v-slot:activator="{ on, attrs, value }">
                            <v-btn
                              v-on="on"
                              color="red lighten-2 white--text"
                              text
                              small
                            >
                              <div class="d-flex align-center" style="gap: 6px">
                                <v-icon dark small> mdi-trash-can </v-icon>
                                <span
                                  class="font-weight-medium text-caption text-lg-body-2 red--text text--lighten-2 text-center"
                                  >Remove All Items</span
                                >
                              </div>
                            </v-btn>
                          </template>
                          <v-container fluid class="white">
                            <div
                              dense
                              class="d-flex align-center flex-column"
                              style="gap: 12px"
                            >
                              <div
                                class="d-flex justify-center text--primary font-weight-medium text-body-2 text-sm-body-1"
                              >
                                Remove All Items?
                              </div>
                              <div
                                class="d-flex justify-center"
                                style="gap: 12px"
                              >
                                <div class="d-flex justify-center">
                                  <v-btn small text color="blue darken-1">
                                    Cancel
                                  </v-btn>
                                </div>
                                <div class="d-flex justify-center">
                                  <v-btn
                                    small
                                    color="red lighten-2 white--text"
                                    @click="clearCart"
                                  >
                                    Remove All Items
                                  </v-btn>
                                </div>
                              </div>
                            </div>
                          </v-container>
                        </v-menu>
                      </div>
                      <div
                        v-else
                        key="unitInfo"
                        class="d-flex flex-column flex-shrink-1 flex-grow-0 align-start justify-center text-caption font-weight-bold text--primary mt-2"
                        style="line-height: 0.75rem !important; gap: 6px"
                      >
                        <div class="d-flex">
                          <span
                            >Units:
                            {{ totalUnits | addComma }}
                          </span>
                        </div>
                        <div class="d-flex" v-if="promoPercentage">
                          <span style="color: #7f32a8 !important"
                            >Promo Units:
                            {{
                              rightDrawer.data.products
                                .filter((item) => item.promo)
                                .reduce(
                                  (sum, item) =>
                                    sum +
                                    (item.qty.single +
                                      item.qty.case * item.caseQTY),
                                  0
                                ) | addComma
                            }}
                            ({{
                              Number(parseFloat(promoPercentage).toFixed(2))
                            }}%)
                          </span>
                        </div>
                      </div>
                    </v-slide-x-transition>
                    <div
                      class="d-flex text--primary flex-column justify-end align-end text-caption text-sm-body-2"
                      style="line-height: 0.75rem !important; gap: 16px"
                    >
                      <table>
                        <tr>
                          <td class="pa-1" style="text-align: left">
                            Cart Value
                          </td>
                          <td
                            class="pa-1 text-capitalize"
                            style="text-align: right"
                          >
                            {{ (productsTotal + totalDiscounts) | currency }}
                          </td>
                        </tr>
                        <tr class="text--primary">
                          <td class="pa-1" style="text-align: left">
                            Case Discounts
                          </td>
                          <td
                            class="pa-1 text-capitalize"
                            style="text-align: right"
                          >
                            {{ totalDiscounts | currency }}
                          </td>
                        </tr>
                        <tr
                          class="font-weight-black text-caption text-sm-body-2"
                        >
                          <td class="pa-1" style="text-align: left">
                            Subtotal
                          </td>
                          <td
                            class="pa-1 text-capitalize"
                            style="text-align: right"
                          >
                            {{ productsTotal | currency }}
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-window-item>
        <v-window-item :value="1">
          <v-container
            fluid
            class="pa-0 rounded-lg"
            style="height: 100% !important"
          >
            <v-row
              class="fill-height"
              align-self="end"
              v-if="productsUnavailable.length"
            >
              <v-col cols="12">
                <v-alert
                  prominent
                  hide-details
                  :dense="isMobileDevice"
                  type="error"
                  class="rounded-lg mb-0"
                >
                  <v-row align="center">
                    <v-col class="grow">
                      <v-layout column>
                        <div
                          class="font-weight-bold text-body-2 text-md-body-1"
                        >
                          <strong>Unavailable Products</strong>
                        </div>
                        <div
                          class="text-caption text-md-body-2 font-weight-medium"
                        >
                          Your cart includes products that are not available at
                          the quantity requested.
                        </div>
                      </v-layout>
                    </v-col>
                  </v-row>
                </v-alert>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <div class="d-flex align-center justify-space-between">
                  <span
                    class="font-weight-bold text-body-2 text-md-body-1 d-flex text--primary d-flex justify-start"
                    style="
                      cursor: pointer;
                      gap: 8px;
                      font-size: 18px !important;
                      min-height: 24px;
                    "
                    @click="activeViews.products = !activeViews.products"
                  >
                    <div class="d-flex" style="gap: 6px">
                      <span class="d-flex align-center" style="gap: 6px"
                        ><span
                          class="text-body-2 text-md-body-1 font-weight-black black--text"
                          >Products</span
                        >
                        <v-slide-x-transition leave-absolute>
                          <span
                            v-if="rightDrawer.data.products.length"
                            class="text--secondary text-body-2 text-md-body-1 font-weight-medium"
                            >({{
                              rightDrawer.data.products.length | addComma
                            }})</span
                          >
                        </v-slide-x-transition>
                      </span>
                      <span
                        :class="{
                          activeRotate: activeViews.products,
                        }"
                        style="
                          display: flex;
                          align-items: center;
                          align-self: center;
                        "
                      >
                        <v-icon size="16">mdi-chevron-down</v-icon>
                      </span>
                      <v-slide-y-transition leave-absolute>
                        <v-icon
                          key="alert"
                          v-if="productsUnavailable.length"
                          color="red lighten-1"
                          >mdi-alert-circle</v-icon
                        >
                      </v-slide-y-transition>
                    </div>
                  </span>
                </div>
                <v-slide-y-transition leave-absolute group>
                  <div
                    v-if="activeViews.products"
                    class="d-flex flex-column mt-1"
                    key="productsTable"
                  >
                    <v-container fluid class="pa-0">
                      <v-row
                        dense
                        class="d-flex mx-1 px-2 align-center justify-space-between"
                      >
                        <v-col cols="1" class="text-start"
                          ><span
                            class="text-caption font-weight-bold text--secondary"
                            style="line-height: 0.75rem !important"
                            >#</span
                          ></v-col
                        >
                        <v-col cols="5" class="text-start">
                          <span
                            class="text-caption font-weight-bold text--secondary"
                            style="line-height: 0.75rem !important"
                            >Item</span
                          >
                        </v-col>
                        <v-col cols="1" class="text-end">
                          <span
                            class="text-caption font-weight-bold text--secondary"
                            style="line-height: 0.75rem !important"
                            >Qty</span
                          >
                        </v-col>
                        <v-col cols="2" class="text-end">
                          <span
                            class="text-caption font-weight-bold text--secondary"
                            style="line-height: 0.75rem !important"
                            >Price</span
                          >
                        </v-col>
                        <v-col cols="2" class="text-end">
                          <span
                            class="text-caption font-weight-bold text--secondary"
                            style="line-height: 0.75rem !important"
                            >Total</span
                          >
                        </v-col>
                      </v-row>
                      <v-row
                        dense
                        v-for="(item, index) in rightDrawer.data.products"
                        :key="index"
                        class="d-flex mx-1 px-2 rounded-lg my-1 align-center justify-space-between"
                        :style="
                          item.promo
                            ? `background: #4a20ff24; color: #7f32a8 !important`
                            : item.sample
                            ? `background: #8adcff24; color: #0088ff !important`
                            : ''
                        "
                      >
                        <v-col cols="1" class="text-start"
                          ><span
                            class="text-caption font-weight-medium"
                            style="line-height: 0.75rem !important"
                            >{{ index + 1 }}</span
                          ></v-col
                        >
                        <v-col
                          cols="5"
                          class="text-start d-flex align-center justify-start"
                          style="gap: 8px"
                        >
                          <v-avatar
                            v-if="!item.imageSrc"
                            color="grey lighten-3"
                            :size="isMobileDevice ? 20 : 22"
                            tile
                            style="border: solid 1px #dedede !important"
                            class="rounded d-flex align-center justify-center"
                          >
                            <v-icon style="font-size: 10px !important"
                              >mdi-image</v-icon
                            >
                          </v-avatar>
                          <v-avatar
                            v-else
                            :size="isMobileDevice ? 20 : 22"
                            tile
                            style="border: solid 1px #dedede"
                            class="rounded white"
                          >
                            <v-img
                              contain
                              :src="item.imageSrc"
                              :alt="`${$options.filters.capitalize(
                                item.name
                              )} Product Image`"
                            >
                              <template v-slot:placeholder>
                                <v-row
                                  class="fill-height white ma-0"
                                  align="center"
                                  justify="center"
                                >
                                  <v-progress-circular
                                    indeterminate
                                    size="10"
                                    width="2"
                                    color="grey darken-2"
                                  ></v-progress-circular>
                                </v-row>
                              </template>
                            </v-img>
                          </v-avatar>
                          <div
                            class="d-flex flex-column align-start justify-center"
                            style="gap: 4px"
                          >
                            <span
                              class="text-caption text-lg-body-2 d-flex font-weight-bold"
                              style="line-height: 0.75rem !important"
                              :class="
                                productsUnavailable.includes(item.id)
                                  ? 'red--text font-weight-black'
                                  : ''
                              "
                              >{{ item.name | capitalize }}</span
                            >
                            <span
                              class="text-caption text-lg-body-2 d-flex font-weight-bold"
                              style="
                                line-height: 0.75rem !important;
                                opacity: 0.6;
                              "
                              :class="
                                productsUnavailable.includes(item.id)
                                  ? 'red--text'
                                  : ''
                              "
                              :style="
                                $vuetify.breakpoint.mdAndDown
                                  ? 'font-size: 10px !important'
                                  : 'font-size: 12px !important'
                              "
                              >{{ item.category | capitalize }}</span
                            >
                            <span
                              class="text-caption text-lg-body-2 d-flex font-weight-normal"
                              style="line-height: 0.75rem !important"
                              :class="
                                productsUnavailable.includes(item.id)
                                  ? 'red--text'
                                  : ''
                              "
                              :style="
                                $vuetify.breakpoint.mdAndDown
                                  ? 'font-size: 10px !important'
                                  : 'font-size: 12px !important'
                              "
                              >{{
                                item.qty.single > 0 && item.qty.case > 0
                                  ? "Cases & Singles"
                                  : item.qty.case > 0
                                  ? "Cases"
                                  : "Singles" | capitalize
                              }}</span
                            >
                          </div>
                        </v-col>
                        <v-col cols="1" class="d-flex justify-end flex-no-wrap">
                          <v-icon
                            v-if="productsUnavailable.includes(item.id)"
                            size="12"
                            color="red lighten-1"
                            class="mr-1"
                            >mdi-alert-circle</v-icon
                          >
                          <span
                            class="text-caption font-weight-medium"
                            style="line-height: 0.75rem !important"
                            >{{
                              (item.qty.single + item.qty.case * item.caseQTY)
                                | addComma
                            }}</span
                          >
                        </v-col>
                        <v-col cols="2" class="text-end">
                          <span
                            class="d-flex flex-column justify-center align-end"
                          >
                            <span
                              class="d-flex align-center text--primary text-caption text-no-wrap justify-end"
                            >
                              <span
                                v-if="item.discountApplied"
                                class="text-decoration-line-through text--disabled"
                                :class="
                                  item.promo
                                    ? 'font-weight-bold'
                                    : 'font-weight-medium'
                                "
                                :style="
                                  item.promo
                                    ? 'color: #9421d1FF !important'
                                    : item.sample
                                    ? `color: #0088ff !important`
                                    : ''
                                "
                                v-text="$options.filters.currency(item.price)"
                              />
                            </span>
                            <span
                              class="d-flex align-center text--primary text-caption text-no-wrap justify-end"
                            >
                              <span
                                :class="
                                  item.promo
                                    ? 'font-weight-bold'
                                    : 'font-weight-medium'
                                "
                                :style="
                                  item.promo
                                    ? 'color: #9421d1FF !important'
                                    : item.sample
                                    ? `color: #0088ff !important`
                                    : item.discountApplied
                                    ? `color: #00695C !important`
                                    : ''
                                "
                                v-text="
                                  $options.filters.currency(
                                    item.discountApplied
                                      ? item.price - discountedValueOfItem(item)
                                      : item.price
                                  )
                                "
                              />
                            </span>
                          </span>
                        </v-col>
                        <v-col cols="2" class="text-end">
                          <span
                            class="text-caption font-weight-medium"
                            style="line-height: 0.75rem !important"
                            >{{
                              ((item.discountApplied
                                ? item.price - discountedValueOfItem(item)
                                : item.price) *
                                (item.qty.single +
                                  item.qty.case * item.caseQTY))
                                | currency
                            }}</span
                          >
                        </v-col>
                      </v-row>
                      <v-row
                        dense
                        class="d-flex mx-2 align-center justify-end mt-1 my-2"
                      >
                        <v-col cols="12" class="text-end">
                          <v-divider class="mb-3"></v-divider>
                          <div class="d-flex justify-space-between align-start">
                            <div
                              class="d-flex flex-column align-start justify-center text-caption font-weight-bold text--primary"
                              style="line-height: 0.75rem !important; gap: 6px"
                            >
                              <div class="d-flex">
                                <span
                                  >Units:
                                  {{ totalUnits | addComma }}
                                </span>
                              </div>
                              <div class="d-flex" v-if="promoPercentage">
                                <span style="color: #7f32a8 !important"
                                  >Promo Units:
                                  {{
                                    rightDrawer.data.products
                                      .filter((item) => item.promo)
                                      .reduce(
                                        (sum, item) =>
                                          sum +
                                          (item.qty.single +
                                            item.qty.case * item.caseQTY),
                                        0
                                      ) | addComma
                                  }}
                                  ({{
                                    Number(
                                      parseFloat(promoPercentage).toFixed(2)
                                    )
                                  }}%)
                                </span>
                              </div>
                            </div>
                            <div
                              class="d-flex text--primary justify-end align-end text-body-2 font-weight-bold"
                              style="line-height: 0.75rem !important; gap: 6px"
                            >
                              <span>Subtotal: </span>
                              <span>
                                {{ productsTotal | currency }}
                              </span>
                            </div>
                          </div>
                        </v-col>
                      </v-row>
                    </v-container>
                    <v-divider class="mb-0" />
                  </div>
                </v-slide-y-transition>
              </v-col>
              <v-col cols="12" v-if="orderNeedsApproval">
                <div class="d-flex align-center justify-space-between">
                  <span
                    class="font-weight-bold text-body-2 text-md-body-1 d-flex text--primary d-flex justify-start"
                    style="
                      cursor: pointer;
                      gap: 8px;
                      font-size: 18px !important;
                      min-height: 24px;
                    "
                    @click="
                      activeViews.approvalConfirmation =
                        !activeViews.approvalConfirmation
                    "
                  >
                    <div class="d-flex" style="gap: 6px">
                      <span class="d-flex align-center" style="gap: 6px"
                        ><span
                          class="text-body-2 text-md-body-1 font-weight-black black--text"
                          >Order Approval Acknowledgement</span
                        >
                      </span>
                      <span
                        :class="{
                          activeRotate: activeViews.approvalConfirmation,
                        }"
                        style="
                          display: flex;
                          align-items: center;
                          align-self: center;
                        "
                      >
                        <v-icon size="16">mdi-chevron-down</v-icon>
                      </span>
                      <v-slide-y-transition leave-absolute>
                        <v-icon
                          key="check"
                          v-if="approvalConfirmation"
                          color="blue darken-1"
                          >mdi-check-circle</v-icon
                        >
                        <v-icon key="alert" v-else color="red lighten-1"
                          >mdi-alert-circle</v-icon
                        >
                      </v-slide-y-transition>
                    </div>
                  </span>
                </div>
                <v-slide-y-transition leave-absolute group>
                  <div
                    v-if="activeViews.approvalConfirmation"
                    class="d-flex flex-column mt-1"
                    key="approvalConfirmation"
                  >
                    <v-container fluid class="pa-0">
                      <v-row dense class="d-flex my-1">
                        <v-col cols="12">
                          <span
                            class="text-caption text-md-body-2 font-weight-bold text--secondary text-center"
                            >This order will need to be approved by a Manager
                            due to the status of the account, the balance of the
                            account, and/or a price reduction on some products.
                          </span>
                        </v-col>
                        <v-col cols="12">
                          <v-checkbox
                            v-model="approvalConfirmation"
                            @change="
                              approvalConfirmation
                                ? (activeViews.approvalConfirmation = false)
                                : ''
                            "
                            :error="!approvalConfirmation"
                            :error-messages="
                              approvalConfirmation ? '' : 'Required.'
                            "
                            class="mt-1"
                            hide-details="auto"
                            color="blue darken-1"
                          >
                            <template v-slot:label>
                              <span
                                class="text-caption text-md-body-2 font-weight-bold text--primary"
                                >I Understand</span
                              >
                            </template>
                          </v-checkbox>
                        </v-col>
                      </v-row>
                    </v-container>
                    <v-divider class="mb-0 mt-3" />
                  </div>
                </v-slide-y-transition>
              </v-col>
              <v-col cols="12">
                <div class="d-flex" style="gap: 6px">
                  <span class="d-flex align-center" style="gap: 6px"
                    ><span
                      class="text-body-2 text-md-body-1 font-weight-black black--text"
                      >Metadata</span
                    >
                  </span>
                </div>
              </v-col>
              <v-col cols="12">
                <div class="d-flex" style="gap: 6px">
                  <span class="d-flex align-center" style="gap: 6px">
                    <v-checkbox
                      label="Send order email?"
                      :color="$vuetify.theme.themes.dark.success"
                      v-model="sendEmail"
                    ></v-checkbox>
                  </span>
                </div>
              </v-col>
              <v-col cols="6">
                <v-select
                  label="Buyer"
                  :color="$vuetify.theme.themes.dark.primary"
                  :item-color="$vuetify.theme.themes.dark.primary"
                  :background-color="contactSet ? '' : 'yellow lighten-3'"
                  no-data-text="No Contacts"
                  hide-details
                  outlined
                  clearable
                  :items="getRightDrawer.data?.contacts ?? []"
                  class="white"
                  v-model="rightDrawer.data.orderDetails.contact"
                  menu-props="closeOnContentClick"
                >
                  <template v-slot:item="{ item }">
                    <v-list-item-content>
                      <div class="d-flex justify-space-between">
                        <div
                          class="d-flex align-center justify-start"
                          style="gap: 12px"
                        >
                          <v-avatar
                            tile
                            class="rounded-lg"
                            color="grey lighten-3"
                          >
                            <v-icon>{{ $vuetify.icons.values.user }}</v-icon>
                          </v-avatar>
                          <span
                            class="d-flex justify-space-between flex-shrink-1"
                          >
                            <div
                              class="align-start justify-start align-self-start flex-column d-flex text-body-2"
                              style="gap: 4px"
                            >
                              <span
                                class="font-weight-bold text-body-2"
                                style="font-size: 16px !important"
                              >
                                <span class="text-capitalize">{{
                                  item.fullName
                                }}</span>
                                <span
                                  v-if="item.isParentContact"
                                  class="font-weight-light text-caption"
                                >
                                  (via Parent Account)
                                </span>
                              </span>
                              <span
                                v-if="item.title"
                                class="text-capitalize text-caption text--secondary font-weight-bold"
                                v-text="item.title?.text"
                                style="line-height: 0.5rem !important"
                              />
                              <span
                                class="text-caption text--secondary font-weight-normal"
                                style="line-height: 1.15rem !important"
                              >
                                {{
                                  item.email?.length > 0
                                    ? item.email
                                    : "No Email Set"
                                }}
                              </span>
                            </div>
                          </span>
                        </div>
                      </div>
                    </v-list-item-content>
                  </template>
                  <template v-slot:selection="{ item }">
                    <span class="text-capitalize">{{ item.text }}</span>
                  </template>
                  <template v-slot:append-item>
                    <v-divider class="mb-2"></v-divider>
                    <v-list-item @click="openContactDialog">
                      <v-list-item-content>
                        <div class="d-flex justify-space-between">
                          <div
                            class="d-flex align-center justify-start"
                            style="gap: 12px"
                          >
                            <v-avatar
                              tile
                              class="rounded-lg"
                              color="grey lighten-3"
                            >
                              <v-icon color="blue darken-1"> mdi-plus </v-icon>
                            </v-avatar>
                            <span
                              class="d-flex justify-space-between flex-shrink-1"
                            >
                              <div
                                class="align-start justify-start align-self-start flex-column d-flex text-body-2"
                                style="gap: 4px"
                              >
                                <span
                                  class="font-weight-medium blue--text text-darken-1 text-body-2"
                                  style="font-size: 16px !important"
                                >
                                  <span class="text-capitalize"
                                    >New Contact</span
                                  >
                                </span>
                              </div>
                            </span>
                          </div>
                        </div>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-select>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  label="Shipping Fee"
                  type="number"
                  min="0"
                  step=".01"
                  placeholder="0.00"
                  hide-spin-buttons
                  inputmode="decimal"
                  persistent-placeholder
                  outlined
                  prefix="$"
                  :color="$vuetify.theme.themes.dark.primary"
                  hide-details
                  v-model.number="shippingFee"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  label="Delivery Preferences"
                  outlined
                  rows="3"
                  background-color="grey lighten-4"
                  :color="$vuetify.theme.themes.dark.primary"
                  hide-details
                  v-model="
                    rightDrawer.data.accountDetails.account.deliveryPreferences
                      .notes
                  "
                >
                </v-textarea>
              </v-col>
              <v-col cols="6">
                <v-textarea
                  label="Order Notes"
                  :color="$vuetify.theme.themes.dark.primary"
                  :background-color="orderNotesSet ? '' : 'yellow lighten-3'"
                  :error="!orderNotesSet"
                  :error-messages="
                    orderNotesSet
                      ? undefined
                      : `Notes are required due to ${$options.filters.capitalize(
                          rightDrawer.data.accountDetails.account.name
                        )}'s open balance.`
                  "
                  outlined
                  rows="3"
                  hide-details="auto"
                  v-model="rightDrawer.data.orderDetails.notes"
                >
                </v-textarea>
              </v-col>
              <v-col cols="6">
                <v-textarea
                  label="Internal Notes"
                  outlined
                  rows="3"
                  :color="$vuetify.theme.themes.dark.primary"
                  hide-details
                  v-model="rightDrawer.data.accountDetails.account.notes"
                >
                </v-textarea>
              </v-col>
              <v-col cols="12">
                <div class="d-flex justify-end" style="gap: 6px">
                  <span class="d-flex align-center justify-end" style="gap: 6px"
                    ><span class="text-body-1 font-weight-black black--text"
                      >Total: </span
                    ><span class="text-body-1 font-weight-black black--text">{{
                      orderTotal | currency
                    }}</span>
                  </span>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-window-item>
      </v-window>
    </template>
    <template v-else>
      <div class="d-flex justify-center align-center flex-column">
        <div class="d-flex justify-start mt-2 text--disabled">
          <v-icon large>mdi-cart-remove</v-icon>
        </div>
        <span
          class="mt-1 mb-3 text-body-1 text-md-h6 font-weight-normal text--secondary"
        >
          No Products Added
        </span>
        <span
          class="d-block text-caption font-weight-medium text-md-body-2 text--disabled"
        >
          Add a product to your cart to checkout!</span
        >
      </div>
    </template>
    <v-dialog
      v-model="contactDialog"
      content-class="rounded-lg"
      scrollable
      max-width="600px"
    >
      <v-card style="background-color: #f9fafc">
        <v-card-title>
          <div style="gap: 12px" class="d-flex justify-start align-center">
            <v-icon>mdi-account-box-plus-outline</v-icon>
            <span class="text-h6 font-weight-black">New Contact</span>
          </div>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  label="First Name"
                  persistent-placeholder
                  placeholder="John"
                  hide-details
                  v-model="newContact.name.first"
                  :color="$vuetify.theme.themes.dark.primary"
                  :background-color="
                    newContact.name.first?.length ? '' : 'yellow lighten-3'
                  "
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  label="Last Name"
                  persistent-placeholder
                  placeholder="Doe"
                  hide-details
                  v-model="newContact.name.last"
                  :color="$vuetify.theme.themes.dark.primary"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-autocomplete
                  label="Title"
                  placeholder="Select"
                  persistent-placeholder
                  :color="$vuetify.theme.themes.dark.primary"
                  :item-color="$vuetify.theme.themes.dark.primary"
                  :background-color="newContact.title ? '' : 'yellow lighten-3'"
                  :items="getContactTitles.filter((item) => item.value)"
                  hide-details
                  clearable
                  return-object
                  v-model="newContact.title"
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  label="Phone #"
                  placeholder="(123) 456-7890"
                  persistent-placeholder
                  hide-details="auto"
                  type="tel"
                  :rules="[rules.phone]"
                  v-model="newContact.phoneNumber"
                  :color="$vuetify.theme.themes.dark.primary"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Email"
                  type="email"
                  placeholder="john.doe@gmail.com"
                  persistent-placeholder
                  hide-details="auto"
                  v-model="newContact.email"
                  :rules="[rules.email]"
                  :color="$vuetify.theme.themes.dark.primary"
                  :background-color="
                    newContactHasValidEmail ? '' : 'yellow lighten-3'
                  "
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <PlacesSearch
                  label="Address"
                  :required="true"
                  :placeholderText="`Input Address`"
                  :types="`address`"
                  :address="newContact.locationInput"
                  @place-search="placeSearch"
                />
              </v-col>
              <v-col cols="12">
                <v-textarea
                  label="Notes"
                  placeholder="Enter notes..."
                  persistent-placeholder
                  outlined
                  rows="3"
                  :color="$vuetify.theme.themes.dark.primary"
                  background-color="grey lighten-3"
                  hide-details
                  v-model="newContact.notes"
                >
                </v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="px-4 pb-4">
          <v-btn
            color="red lighten-1"
            text
            :small="$vuetify.breakpoint.smAndDown"
            :disabled="loading.submit"
            @click="handleContactSubmit()"
          >
            Cancel
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            :small="$vuetify.breakpoint.smAndDown"
            @click="handleContactSubmit(true)"
            :disabled="!validContact"
            :loading="loading.submit"
          >
            Create Contact
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="errorDialog"
      content-class="rounded-lg"
      persistent
      scrollable
      max-width="600px"
    >
      <v-card style="background-color: #f9fafc">
        <v-card-title>
          <div style="gap: 12px" class="d-flex justify-start align-center">
            <v-icon color="red">mdi-alert</v-icon>
            <span class="text-h6 font-weight-black">Error</span>
          </div>
        </v-card-title>
        <v-card-text class="pb-0">
          <v-container>
            <v-row>
              <v-col cols="12">
                <div
                  style="gap: 12px"
                  class="d-flex flex-column align-center justify-center text-center"
                >
                  <span
                    class="text-body-2 text-md-body-1 text--primary font-weight-black"
                    >There was an error submitting your order.</span
                  >
                  <span class="text-caption text-md-body-2 font-weight-medium"
                    >Please consider saving your order as a Draft Order and
                    trying to submit again in 15 minutes.
                  </span>
                  <span
                    class="text-caption text-md-body-2 font-weight-black text--secondary"
                    >If this error persists, please contact a
                    {{ getAppName }} administrator.</span
                  >
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="px-4 pb-4">
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            :small="$vuetify.breakpoint.smAndDown"
            @click="errorDialog = false"
          >
            Dismiss
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="leaflinkIDAlert"
      content-class="rounded-lg"
      persistent
      scrollable
      max-width="600px"
    >
      <v-card style="background-color: #f9fafc">
        <v-card-title>
          <div style="gap: 12px" class="d-flex justify-start align-center">
            <v-icon color="red">mdi-alert</v-icon>
            <span class="text-h6 font-weight-black">Error</span>
          </div>
        </v-card-title>
        <v-card-text class="pb-0">
          <v-container>
            <v-row>
              <v-col cols="12">
                <div
                  style="gap: 12px"
                  class="d-flex flex-column align-center justify-center text-center"
                >
                  <span
                    class="text-body-2 text-md-body-1 text--primary font-weight-black"
                    >There was an error submitting your order.</span
                  >
                  <span class="text-caption text-md-body-2 font-weight-medium"
                    >A Leaflink ID was not found for the selected account. This
                    must be set in order to submit this order.
                  </span>
                  <span
                    class="text-caption text-md-body-2 font-weight-black text--secondary"
                    >Please consider saving this order as a Draft Order and
                    trying to submit again after a Leaflink ID has been added to
                    this account.</span
                  >
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="px-4 pb-4">
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            :small="$vuetify.breakpoint.smAndDown"
            @click="leaflinkIDAlert = false"
          >
            Dismiss
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="reviewDialog"
      content-class="rounded-lg"
      scrollable
      persistent
      transition="scale-transition"
    >
      <v-card>
        <v-card-title
          class="justify-center"
          :style="`background: ${$vuetify.theme.themes.dark.success}; color: ${$vuetify.theme.themes.dark.secondary};`"
        >
          <span class="text-uppercase d-flex align-center"
            >order created<v-icon class="ml-1" dark>{{
              $vuetify.icons.values.submit
            }}</v-icon></span
          >
        </v-card-title>
        <v-card-text v-if="response.orderDoc" class="mt-3 pb-0">
          <v-card flat outlined class="rounded-lg">
            <v-card-title
              :style="`background: ${$vuetify.theme.themes.dark.primary}; color: ${$vuetify.theme.themes.dark.secondary};`"
            >
              <v-icon
                large
                class="mr-2"
                :color="$vuetify.theme.themes.dark.secondary"
                >{{ $vuetify.icons.values.order }}</v-icon
              >
              <span class="text-uppercase">order details</span>
            </v-card-title>
            <v-card-text>
              <v-container fluid>
                <v-row>
                  <v-col cols="12" md="3">
                    <v-text-field
                      label="Account"
                      :color="$vuetify.theme.themes.dark.primary"
                      :value="accountDetails.account.name | capitalize"
                      :hint="`DBA: ${$options.filters.capitalize(
                        accountDetails.account.dba
                      )}`"
                      persistent-hint
                      readonly
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-text-field
                      label="Buyer"
                      :color="$vuetify.theme.themes.dark.primary"
                      :value="
                        getRightDrawer.data?.contacts.find(
                          (item) =>
                            item.id === rightDrawer.data.orderDetails.contact
                        )?.text ?? null | capitalize
                      "
                      :hint="
                        getRightDrawer.data?.contacts.find(
                          (item) =>
                            item.id === rightDrawer.data.orderDetails.contact
                        )?.email?.length > 0
                          ? `Email: ${
                              getRightDrawer.data?.contacts.find(
                                (item) =>
                                  item.id ===
                                  rightDrawer.data.orderDetails.contact
                              )?.email
                            }`
                          : ''
                      "
                      persistent-hint
                      readonly
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="6" md="3">
                    <v-text-field
                      label="Internal Order #"
                      :color="$vuetify.theme.themes.dark.primary"
                      :value="
                        response.orderDoc.orderNumber.internal.toUpperCase()
                      "
                      :hint="
                        response.orderDoc.orderNumber.external?.length > 0
                          ? `External Order #: ${response.orderDoc.orderNumber.external.toUpperCase()}`
                          : ''
                      "
                      persistent-hint
                      readonly
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="6" md="3">
                    <v-text-field
                      label="Payment Term"
                      :color="$vuetify.theme.themes.dark.primary"
                      :value="
                        getPaymentTerms.find(
                          (item) => item.value === response.orderDoc.paymentTerm
                        )?.text ?? null
                      "
                      readonly
                    >
                    </v-text-field>
                  </v-col>

                  <v-col cols="12" md="4">
                    <v-textarea
                      label="Delivery Preferences"
                      outlined
                      :color="$vuetify.theme.themes.dark.primary"
                      background-color="grey lighten-3"
                      v-model.trim="
                        response.orderDoc.account.deliveryPreferences
                      "
                      readonly
                      hide-details
                      :disabled="
                        response.orderDoc.account.deliveryPreferences.length ===
                        0
                      "
                    ></v-textarea>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-textarea
                      label="Order Notes"
                      outlined
                      :color="$vuetify.theme.themes.dark.primary"
                      v-model.trim="response.orderDoc.notes"
                      readonly
                      hide-details
                      :disabled="response.orderDoc.notes.length === 0"
                    ></v-textarea>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-textarea
                      label="Internal Notes"
                      outlined
                      :color="$vuetify.theme.themes.dark.primary"
                      v-model.trim="response.orderDoc.internalNotes"
                      readonly
                      hide-details
                      :disabled="response.orderDoc.internalNotes.length === 0"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-container>
              <v-data-table
                :headers="response.headers.lineItems"
                :items="response.orderDoc.lineItems"
                no-data-text="No Line Items added"
                :items-per-page="-1"
                hide-default-footer
                disable-sort
              >
                <template
                  v-slot:group.header="{
                    group,
                    items,
                    isOpen,
                    toggle,
                    remove,
                    headers,
                  }"
                >
                  <GroupByHeaderRow
                    :headers="headers"
                    :group="group"
                    :items="items"
                    :isOpen="isOpen"
                    :showRemoveIcon="false"
                    @toggle="toggle"
                    @remove="remove"
                  />
                </template>
                <template v-slot:item.number="{ index }">
                  {{ index + 1 }}
                </template>
                <template v-slot:item.name="{ item }">
                  <span class="text-capitalize font-weight-bold d-block">{{
                    item.name
                  }}</span>
                  <span
                    class="text-capitalize text-caption text-no-wrap d-block"
                    >{{ item.categoryName }}</span
                  >
                  <span
                    v-if="item.promo"
                    class="text-caption text-no-wrap d-block my-1"
                  >
                    <span
                      class="font-weight-bold text-caption pa-1 rounded"
                      :style="`background-color: ${
                        $vuetify.theme.themes.dark.promoUnit
                      }; color: ${getTextColor(
                        $vuetify.theme.themes.dark.promoUnit
                      )}`"
                      >Promo
                      <v-icon
                        small
                        :style="`color: ${getTextColor(
                          $vuetify.theme.themes.dark.promoUnit
                        )}`"
                        >mdi-seal</v-icon
                      >
                    </span>
                  </span>
                  <span
                    v-if="item.flags.fulfilled || item.flags.delivered"
                    class="text-caption text-no-wrap d-block my-1"
                  >
                    <span
                      class="font-weight-bold text-caption pa-1 rounded"
                      :style="`background-color: ${
                        item.flags.delivered
                          ? $vuetify.theme.themes.dark.shipping
                          : $vuetify.theme.themes.dark.products
                      }; color: ${getTextColor(
                        item.flags.fulfilled
                          ? $vuetify.theme.themes.dark.shipping
                          : $vuetify.theme.themes.dark.products
                      )}`"
                      >{{
                        item.flags.delivered ? "Delivered" : "Fulfilled"
                      }}</span
                    >
                  </span>
                </template>
                <template v-slot:item.categoryName="{ item }">
                  <span class="text-capitalize">{{ item.categoryName }}</span>
                </template>
                <template v-slot:item.fulfilled="{ item }">
                  <v-icon
                    v-if="item.flags.fulfilled"
                    :color="$vuetify.theme.themes.dark.products"
                    >{{ $vuetify.icons.values.submit }}</v-icon
                  >
                </template>
                <template v-slot:item.delivered="{ item }">
                  <v-icon
                    v-if="item.flags.delivered"
                    :color="$vuetify.theme.themes.dark.shipping"
                    >{{ $vuetify.icons.values.submit }}</v-icon
                  >
                </template>
                <template v-slot:item.sample="{ item }">
                  <v-icon
                    v-if="item.sample"
                    :color="$vuetify.theme.themes.dark.review"
                    >mdi-alpha-s-circle
                  </v-icon>
                  <span v-else class="text--disabled">-</span>
                </template>
                <template v-slot:item.sku="{ item }">
                  <span class="text-uppercase">{{ item.sku }}</span>
                </template>
                <template v-slot:item.notes="{ item }">
                  <span v-if="item.notes?.length > 0">"{{ item.notes }}"</span>
                  <span v-else class="text--disabled">-</span>
                </template>
                <template v-slot:item.qty="{ item }">
                  {{ parseInt(item.qty) }}
                </template>
                <template v-slot:item.price="{ item }">
                  <span class="d-block">{{ item.amount | currency }}</span>
                  <span
                    v-if="item.promo"
                    class="d-block text-decoration-line-through"
                    >{{ item.price.normal | currency }}</span
                  >
                </template>
                <template v-slot:item.tax="{ item }">
                  {{ item.tax | currency }}
                </template>
                <template v-slot:item.total="{ item }">
                  {{ (item.qty * item.amount) | currency }}
                  <span
                    v-if="item.promo"
                    class="d-block text-decoration-line-through"
                    >{{ (item.qty * item.price.normal) | currency }}</span
                  >
                </template>
                <template v-slot:footer="{ item }">
                  <v-divider inset class="my-3"></v-divider>
                  <div class="d-flex justify-end">
                    <table>
                      <tr
                        v-if="totalPromotionDiscounts > 0"
                        class="d-flex justify-space-between my-1 align-center"
                        style="gap: 10px"
                      >
                        <td>
                          <span
                            class="font-weight-bold text-subtitle-2 rounded"
                            :style="`color: ${$vuetify.theme.themes.dark.promoUnit};`"
                            >Promo vs. Sales %:</span
                          >
                        </td>
                        <td>
                          {{
                            parseFloat(
                              (totalPromotionDiscounts / forSaleUnitValue) * 100
                            ).toFixed(2) | addComma
                          }}%
                        </td>
                      </tr>
                      <tr
                        class="d-flex justify-space-between my-1 align-center"
                        style="gap: 10px"
                      >
                        <td>
                          <span class="font-weight-bold text-subtitle-2 rounded"
                            >Total QTY:</span
                          >
                        </td>
                        <td>
                          {{ response.orderDoc.units | addComma }}
                        </td>
                      </tr>
                      <tr
                        class="d-flex justify-space-between my-1 align-center"
                        style="gap: 10px"
                      >
                        <td>
                          <span class="font-weight-bold text-subtitle-2 rounded"
                            >Subtotal:</span
                          >
                        </td>
                        <td>
                          {{
                            (response.orderDoc.balance.total -
                              response.orderDoc.balance.shipping)
                              | currency
                          }}
                        </td>
                      </tr>
                      <tr
                        class="d-flex justify-space-between my-1 align-center"
                        style="gap: 10px"
                      >
                        <td>
                          <span class="font-weight-bold text-subtitle-2 rounded"
                            >Shipping:</span
                          >
                        </td>
                        <td>
                          {{ response.orderDoc.balance.shipping | currency }}
                        </td>
                      </tr>
                      <tr
                        class="d-flex justify-space-between my-1 align-center"
                        style="gap: 10px"
                      >
                        <td>
                          <span class="font-weight-bold text-subtitle-2 rounded"
                            >Tax:</span
                          >
                        </td>
                        <td>
                          {{ response.orderDoc.balance.excise | currency }}
                        </td>
                      </tr>
                      <tr
                        class="d-flex justify-space-between my-1 align-center"
                        style="gap: 10px"
                      >
                        <td>
                          <span class="font-weight-bold text-subtitle-2 rounded"
                            >Total:</span
                          >
                        </td>
                        <td>
                          {{ response.orderDoc.balance.total | currency }}
                        </td>
                      </tr>
                    </table>
                  </div>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-card-text>
        <v-card-actions>
          <v-container fluid>
            <v-row>
              <v-col cols="12" md="6" sm="12">
                <v-btn
                  block
                  rounded
                  :style="`background: ${$vuetify.theme.themes.dark.review}; color: ${$vuetify.theme.themes.dark.secondary};`"
                  @click="traverse('nextStep-view')"
                >
                  <v-icon class="mr-2">{{ $vuetify.icons.values.view }}</v-icon>
                  <span>view</span>
                </v-btn>
              </v-col>
              <v-col cols="12" md="6" sm="12">
                <v-btn
                  block
                  rounded
                  outlined
                  @click="traverse('nextStep-newOrder')"
                  :color="$vuetify.theme.themes.dark.review"
                >
                  <v-icon class="mr-2">{{ $vuetify.icons.values.add }}</v-icon>
                  <span>create another order</span>
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
// Libraries
import { mapActions, mapGetters } from "vuex";
import {
  // Firestore
  firestore,
  collection,
  onSnapshot,
  getDoc,
  getDocs,
  doc,
  updateDoc,
  serverTimestamp,
  query,
  where,
  or,
  writeBatch,
  increment,
  limit,
  deleteDoc,
  runTransaction,
  // Functions
  functions,
  httpsCallable,
  // Storage
  storage,
  ref,
  getDownloadURL,
  getMetadata,
  getBytes,
  uploadBytes,
  // Remote Config
  remoteConfig,
  getValue,
} from "@/firebase/init";
import moment from "moment";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { Buffer } from "buffer";
import gmapsInit from "@/utils/gmaps";
// Components

import TextWithIconOverlay from "@/components/new/TextWithIconOverlay.vue";
import PlacesSearch from "@/components/api/google-maps/places/Places_Search";

export default {
  name: "OrderCart",
  data: () => ({
    // editMode: false,
    loading: {
      submit: false,
      draft: false,
      invoice: false,
    },
    errorDialog: false,
    contactDialog: false,
    reviewDialog: false,
    leaflinkIDAlert: false,
    sendEmail: true,
    notes: "",
    shippingFee: 0.0,
    approvalConfirmation: null,
    activeViews: {
      products: false,
      approvalConfirmation: false,
    },
    newContact: {
      name: {
        first: "",
        last: "",
      },
      phoneNumber: "",
      email: "",
      title: null,
      notes: "",
      address: null,
      locationInput: "",
      location: null,
    },
    google: null,
    geocoder: null,
    response: {
      headers: {
        lineItems: [
          { text: "#", value: "number", sortable: false, groupable: false },
          { text: "Name", value: "name", sortable: true, groupable: false },
          {
            text: "Sample?",
            value: "sample",
            sortable: false,
            groupable: false,
          },
          { text: "Notes", value: "notes", sortable: false, groupable: false },
          { text: "Cases", value: "cases", sortable: false, groupable: false },
          {
            text: "Single Units",
            value: "singles",
            sortable: false,
            groupable: false,
          },
          {
            text: "Total Units",
            value: "qty",
            sortable: true,
            groupable: false,
          },
          { text: "Price", value: "price", sortable: true, groupable: false },
          { text: "Total", value: "total", sortable: true, groupable: false },
        ],
      },
      priceOverrides: [],
      categories: [],
      orderDoc: null,
    },
    rules: {
      required: (value) => !!value || "Required.",
      email: (value) => {
        if (value === "") return true; // user exited text field when value is null, disable red coloring
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Invalid e-mail.";
      },
      phone: (value) => {
        if (value === "") return true; // user exited text field when value is null, disable red coloring
        const pattern = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
        return (
          pattern.test(value) ||
          "Invalid phone number: Area code required. Example: XXX-XXX-XXXX"
        );
      },
    },
  }),
  props: {
    products: {
      type: Array,
      required: true,
    },
    accountDetails: {
      type: Object,
      required: true,
    },
    orderType: {
      type: String,
      required: true,
    },
    editMode: {
      type: Boolean,
      default: false,
      required: true,
    },
  },
  components: {
    TextWithIconOverlay,
    PlacesSearch,
  },
  async created() {
    this.setup();
  },
  async mounted() {
    await this.setupGoogleAPI();
  },
  methods: {
    ...mapActions(["_rightDrawer"]),
    /* API */
    // GET
    async getProductCategories() {
      this.response.categories = [];

      const productsRef = collection(firestore, "productCategories"),
        forSale = where("flags", "array-contains", "forSale"),
        active = where("active", "==", true),
        brand = where(
          "brandID",
          "==",
          this.getRightDrawer.data.accountDetails.brandStats.brand.id
        );

      const q = query(productsRef, active, brand, forSale);
      const response = await getDocs(q);

      if (!response.empty) {
        this.response.categories = response.docs
          .filter(
            (category) =>
              category
                .data()
                .states?.includes(
                  this.getRightDrawer.data.accountDetails.account.location
                    .shipping.state.stateID
                ) && category.data().visibility !== "hidden"
          )
          .map((categories) => {
            let listPrice = null;

            let listPriceData = categories.data().listPrice;

            if (
              listPriceData &&
              Object.values(listPriceData).some((val) => val !== null)
            ) {
              listPrice = {
                cod: listPriceData.cod,
                net: listPriceData.net,
              };
            }
            return {
              id: categories.id,
              name: categories.data().name,
              flags: categories.data().flags,
              caseQTY: categories.data().caseQTY,
              price: categories.data().price,
              variablePrice: categories.data().variablePrice,
              listPrice,
            };
          })
          .sort((a, b) => (a.name > b.name ? 1 : -1));
      }
    },
    async getPriceOverrides() {
      this.loading.priceOverrides = true;
      this.response.priceOverrides = [];

      const priceOverridesRef = collection(firestore, "priceOverrides");
      const state = where(
        "state.stateID",
        "==",
        this.getRightDrawer.data.accountDetails.account.location.shipping.state
          .stateID
      );
      const brand = where(
        "brand.id",
        "==",
        this.getRightDrawer.data.accountDetails.brandStats.brand.id
      );
      const active = where("active", "==", true);
      const q = query(priceOverridesRef, state, active, brand);
      const response = await getDocs(q);

      if (!response.empty) {
        this.response.priceOverrides = response.docs.flatMap(
          (priceOverride) => {
            let category = this.response.categories.find(
              (category) => category.id === priceOverride.data().category.id
            );

            if (!category) return [];

            let categoryName = category.name;
            let pricing = category.flags.includes("hasVariablePricing")
              ? "Variable"
              : "Static";
            let accountSpecific = priceOverride
              .data()
              .hasOwnProperty("account");
            let productSpecific = priceOverride
              .data()
              .hasOwnProperty("product");

            let listPrice = null;

            let listPriceData = priceOverride.data().listPrice;

            if (
              listPriceData &&
              Object.values(listPriceData).some((val) => val !== null)
            ) {
              listPrice = {
                cod: listPriceData.cod,
                net: listPriceData.net,
              };
            }

            return {
              id: priceOverride.id,
              ...priceOverride.data(),
              category,
              pricing,
              accountSpecific,
              productSpecific,
              listPrice,
            };
          }
        );
      }

      this.loading.priceOverrides = false;
    },
    // POST
    async saveDraft() {
      this.loading.draft = true;
      const batch = writeBatch(firestore);

      const draftSalesOrderDoc = doc(
        collection(firestore, "salesOrders-draft")
      );
      let users = [this.getUser.uid];
      const selectedAccount =
        this.getRightDrawer.data.accountDetails.brandStats;
      const brand = this.getRightDrawer.data.accountDetails.brandStats.brand;

      if (
        selectedAccount.staff.manager.id &&
        selectedAccount.staff.manager.id.length > 0
      ) {
        users.push(selectedAccount.staff.manager.id);
      }
      if (
        selectedAccount.staff.executive.id &&
        selectedAccount.staff.executive.id.length > 0
      ) {
        users.push(selectedAccount.staff.executive.id);
      }

      const selectedUnits = this.getRightDrawer.data.products;

      let addedUnits = [...selectedUnits];
      users = [...new Set(users)]; //  Removes duplicates

      const lineItems = addedUnits
        .map((lineItem) => {
          let price = {
            normal: lineItem.originalPrice,
            sale: lineItem.price,
          };
          if (lineItem.discountApplied) {
            price.sale = lineItem.price - this.discountedValueOfItem(lineItem);
          }
          return {
            account: {
              id: this.getRightDrawer.data.accountDetails.account.id,
              name: this.getRightDrawer.data.accountDetails.account.name,
            },
            brand: {
              id: brand.id,
              name: brand.name,
            },
            state:
              this.getRightDrawer.data.accountDetails.account.location.shipping
                .state,
            amount: price.sale,
            notes: lineItem.notes ?? "",
            flags: {
              case: lineItem.soldByCase || lineItem.caseQTY,
              fulfilled: false,
              delivered: false,
              onSale: lineItem.price < lineItem.originalPrice,
              discountApplied: lineItem.discountApplied ?? false,
            },
            externalIDs: {
              leaflink: lineItem.externalIDs.leaflink,
            },
            name: lineItem.name,
            price,
            discount: lineItem.discount ?? null,
            productID: lineItem.id,
            categoryID: lineItem.categoryID,
            categoryName: lineItem.category,
            qty: lineItem.qty.case * lineItem.caseQTY + lineItem.qty.single,
            unitsPerCase: lineItem.caseQTY || 0,
            cases: lineItem.qty.case || 0,
            singles: lineItem.qty.single || 0,
            relatedID: draftSalesOrderDoc.id,
            sample: lineItem.sample,
            promo: lineItem.promo || false,
            sku: lineItem.sku.toLowerCase(),
            tax: 0,
          };
        })
        .sort((a, b) => (a.name > b.name ? 1 : -1));

      // Order
      let externalIDs = {};

      let contact = this.getRightDrawer.data.contacts.find(
        (contact) =>
          contact.value === this.getRightDrawer.data?.orderDetails?.contact
      );

      batch.set(
        draftSalesOrderDoc,
        {
          created: serverTimestamp(),
          updated: serverTimestamp(),
          createdBy: {
            name: this.getUser.name,
            uid: this.getUser.uid,
            email: this.getUser.email,
          },
          lastUpdated: {
            name: this.getUser.name,
            uid: this.getUser.uid,
          },
          flags: {
            external: false,
            paid: false,
            repGenerated: true,
            sample: this.orderType === "sample" ? true : false,
          },
          orderNumber: {
            external: "",
            internal: "",
          },
          externalIDs,
          brand: {
            id: brand.id,
            name: brand.name,
          },
          lineItems,
          account: {
            id: this.getRightDrawer.data.accountDetails.account.id,
            name: this.getRightDrawer.data.accountDetails.account.name,
            phone: this.getRightDrawer.data.accountDetails.account.phoneNumber,
            license:
              this.getRightDrawer.data.accountDetails.account.license.number,
            email: this.getRightDrawer.data.accountDetails.account.email,
            deliveryPreferences: this.deliveryPrefs,
          },
          location: {
            billing: {
              city: this.getRightDrawer.data.accountDetails.account.location
                .billing.city.name,
              state:
                this.getRightDrawer.data.accountDetails.account.location.billing
                  .state,
              street:
                this.getRightDrawer.data.accountDetails.account.location.billing
                  .street,
              zipCode:
                this.getRightDrawer.data.accountDetails.account.location.billing
                  .zipCode,
            },
            shipping: {
              city: this.getRightDrawer.data.accountDetails.account.location
                .shipping.city.name,
              state:
                this.getRightDrawer.data.accountDetails.account.location
                  .shipping.state,
              street:
                this.getRightDrawer.data.accountDetails.account.location
                  .shipping.street,
              zipCode:
                this.getRightDrawer.data.accountDetails.account.location
                  .shipping.zipCode,
            },
          },
          balance: {
            due: this.orderTotal,
            excise: 0,
            shipping: this.shippingFee,
            total: this.orderTotal,
          },
          paymentTerm: this.getRightDrawer.data.paymentTerm.value,
          shipDate: null,
          units: this.totalUnits,
          notes: this.getRightDrawer.data.orderDetails.notes,
          internalNotes: this.internalNotes,
          status: "draftOrder",
          buyerInformation: contact
            ? {
                name: contact.fullName,
                email: contact.email,
                id: contact.id,
              }
            : null,
          users,
        },
        { merge: true }
      );

      batch.set(
        doc(collection(firestore, "ledger")),
        {
          created: serverTimestamp(),
          relatedID: draftSalesOrderDoc.id,
          relatedTo: "salesOrders",
          action: `<span class="text-capitalize">${this.getUser.name.first} ${this.getUser.name.last}</span> created Draft Order for <span class="text-capitalize">${this.getRightDrawer.data.accountDetails.account.name}</span>`,
          user: {
            name: this.getUser.name,
            uid: this.getUser.uid,
          },
        },
        { merge: true }
      );

      await batch.commit();
      this.loading.draft = false;
      // Show feedback after draft save success
      this.rightDrawer.active = false;
    },
    async submit() {
      this.loading.submit = true;
      let batch = writeBatch(firestore);
      const orderSubmissionEmail = httpsCallable(functions, "sendgridEmail");

      let externalOrder = null,
        orderNumber = "",
        users = [this.getUser.uid];

      let payableToResponse = await getDocs(
        query(
          collection(firestore, "payableTo"),
          where(
            "location.state.stateID",
            "==",
            this.rightDrawer.data.accountDetails.account.location.shipping.state
              .stateID
          ),
          where("active", "==", true),
          where(
            "brandID",
            "==",
            this.rightDrawer.data.accountDetails.brandStats.brand.id
          )
        )
      );
      const salesOrderDoc = doc(collection(firestore, "salesOrders"));

      const stateDoc = doc(
        firestore,
        "states",
        this.rightDrawer.data.accountDetails.account.location.shipping.state
          .stateID
      );
      const stateResponse = await getDoc(stateDoc);

      let fulfillmentLocation = stateResponse.data().fulfillmentLocation;

      const locationRef = doc(
        firestore,
        "locationManufacturing",
        `${fulfillmentLocation}_${this.rightDrawer.data.accountDetails.brandStats.brand.id}`
      );

      let payableTo = null;
      if (!payableToResponse.empty) {
        payableTo = {
          id: payableToResponse.docs[0].id,
          ...payableToResponse.docs[0].data(),
        };
      }

      await this.getProductCategories();
      await this.getPriceOverrides();

      let getListPriceForProduct = (product) => {
        let result = null;

        let category = this.response.categories.find(
          (item) => item.id === product.categoryID
        );

        if (
          category?.listPrice &&
          Object.values(category.listPrice).some((val) => val !== null)
        ) {
          result = category.listPrice;
        }

        let priceOverride = this.getPriceOverrideForProduct(product);

        if (
          priceOverride?.listPrice &&
          Object.values(priceOverride.listPrice).some((val) => val !== null)
        ) {
          result = priceOverride.listPrice;
        }

        return result;
      };

      let activeDiscounts = this.rightDrawer.data.discounts ?? [];

      // TODO: Add support for multiple external systems on payableTo_View and use externalSystem from payableTo data
      let externalSystem = "leaflink"; // FIXME
      let isExternal = false;
      switch (externalSystem) {
        case "leaflink":
          // isExternal = payableTo?.flags?.leaflink && this.accountHasLeaflinkID;
          isExternal = payableTo?.flags?.leaflink;
          break;
        default:
          break;
      }

      let abort = false;
      let lineItems = [];
      let contact = null;
      try {
        const selectedItems = this.rightDrawer.data.products;
        lineItems = selectedItems
          .map((lineItem) => {
            let price = {
              normal: lineItem.originalPrice,
              sale: lineItem.price,
            };
            if (lineItem.discountApplied) {
              price.sale =
                lineItem.price - this.discountedValueOfItem(lineItem);
            }
            return {
              account: {
                id: this.rightDrawer.data.accountDetails.account.id,
                name: this.rightDrawer.data.accountDetails.account.name,
              },
              brand: {
                id: this.rightDrawer.data.accountDetails.brandStats.brand.id,
                name: this.rightDrawer.data.accountDetails.brandStats.brand
                  .name,
              },
              state:
                this.rightDrawer.data.accountDetails.account.location.shipping
                  .state,
              amount: price.sale,
              notes: lineItem.notes,
              flags: {
                case: lineItem.soldByCase || lineItem.caseQTY > 0,
                fulfilled: false,
                delivered: false,
                onSale: lineItem.price < lineItem.originalPrice,
                discountApplied: lineItem.discountApplied ?? false,
              },
              externalIDs: {
                leaflink: lineItem.externalIDs.leaflink,
              },
              name: lineItem.name,
              price,
              discount: lineItem.discount ?? null,
              listPrice: getListPriceForProduct(lineItem),
              productID: lineItem.id,
              categoryID: lineItem.categoryID,
              categoryName: lineItem.category,
              qty: lineItem.qty.case * lineItem.caseQTY + lineItem.qty.single,
              unitsPerCase: lineItem.caseQTY || 0,
              cases: lineItem.qty.case || 0,
              singles: lineItem.qty.single || 0,
              relatedID: salesOrderDoc.id,
              sample: lineItem.sample,
              promo: lineItem.promo || false,
              sku: lineItem.sku.toLowerCase(),
              tax: 0,
            };
          })
          .sort((a, b) => (a.name > b.name ? 1 : -1));

        contact = this.getRightDrawer.data.contacts.find(
          (contact) =>
            contact.value === this.getRightDrawer.data?.orderDetails?.contact
        );

        let externalIDs = {};

        try {
          await runTransaction(firestore, async (transaction) => {
            let payableToDocRef = doc(firestore, "payableTo", payableTo.id);
            const payableToDoc = await transaction.get(payableToDocRef);
            if (!payableToDoc.exists()) {
              throw new Error("Document does not exist!");
            }

            const newOrderNumber =
              (payableToDoc.data().records?.salesOrders ?? 0) + 1;
            transaction.set(
              payableToDocRef,
              {
                records: {
                  salesOrders: newOrderNumber,
                },
              },
              { merge: true }
            );

            transaction.set(salesOrderDoc, {
              created: serverTimestamp(),
              updated: serverTimestamp(),
              createdBy: {
                name: this.getUser.name,
                uid: this.getUser.uid,
                email: this.getUser.email,
              },
              lastUpdated: {
                name: this.getUser.name,
                uid: this.getUser.uid,
              },
              flags: {
                external: isExternal ? true : false,
                paid: false,
                repGenerated: true,
                sample: this.orderType === "sample" ? true : false,
              },
              orderNumber: {
                external: "",
                internal: `${this.rightDrawer.data.accountDetails.brandStats.brand.name
                  .split(" ")
                  .map((word) => word.charAt(0))
                  .join("")}-${
                  this.rightDrawer.data.accountDetails.account.location.shipping
                    .state.abbreviation
                }-so-${newOrderNumber}`,
              },
              externalIDs,
              brand: {
                id: this.rightDrawer.data.accountDetails.brandStats.brand.id,
                name: this.rightDrawer.data.accountDetails.brandStats.brand
                  .name,
              },
              lineItems,
              activeDiscounts,
              account: {
                id: this.rightDrawer.data.accountDetails.account.id,
                name: this.rightDrawer.data.accountDetails.account.name,
                phone: this.rightDrawer.data.accountDetails.account.phoneNumber,
                license:
                  this.rightDrawer.data.accountDetails.account.license.number,
                email: this.rightDrawer.data.accountDetails.account.email,
                staff: {
                  manager: {
                    email:
                      this.rightDrawer.data.accountDetails.brandStats.staff
                        .manager.email,
                    id:
                      this.rightDrawer.data.accountDetails.brandStats.staff
                        .manager.id?.length > 0
                        ? this.rightDrawer.data.accountDetails.brandStats.staff
                            .manager.id
                        : null,
                    name: this.rightDrawer.data.accountDetails.brandStats.staff
                      .manager.name,
                  },
                  executive: {
                    email:
                      this.rightDrawer.data.accountDetails.brandStats.staff
                        .executive.email,
                    id:
                      this.rightDrawer.data.accountDetails.brandStats.staff
                        .executive.id?.length > 0
                        ? this.rightDrawer.data.accountDetails.brandStats.staff
                            .executive.id
                        : null,
                    name: this.rightDrawer.data.accountDetails.brandStats.staff
                      .executive.name,
                  },
                },
                deliveryPreferences: this.deliveryPrefs,
              },
              location: {
                billing: {
                  city: this.rightDrawer.data.accountDetails.account.location
                    .billing.city.name,
                  state:
                    this.rightDrawer.data.accountDetails.account.location
                      .billing.state,
                  street:
                    this.rightDrawer.data.accountDetails.account.location
                      .billing.street,
                  zipCode:
                    this.rightDrawer.data.accountDetails.account.location
                      .billing.zipCode,
                },
                shipping: {
                  city: this.rightDrawer.data.accountDetails.account.location
                    .shipping.city.name,
                  state:
                    this.rightDrawer.data.accountDetails.account.location
                      .shipping.state,
                  street:
                    this.rightDrawer.data.accountDetails.account.location
                      .shipping.street,
                  zipCode:
                    this.rightDrawer.data.accountDetails.account.location
                      .shipping.zipCode,
                },
              },
              balance: {
                due: parseFloat(this.orderTotal).toFixed(2),
                excise: parseFloat(0).toFixed(2),
                shipping: parseFloat(this.shippingFee).toFixed(2),
                total: parseFloat(this.orderTotal).toFixed(2),
              },
              paymentTerm: this.rightDrawer.data.paymentTerm.value,
              shipDate: null,
              units: this.totalUnits,
              notes: this.getRightDrawer.data.orderDetails.notes,
              internalNotes: this.internalNotes,
              status: this.orderNeedsApproval ? "draft" : "submitted",
              buyerInformation: {
                name: contact.fullName,
                email: contact.email,
              },
              users,
              records: {
                awaitingApproval: this.orderNeedsApproval
                  ? moment().startOf("day").add(12, "hours").toDate()
                  : null,
                submitted: !this.orderNeedsApproval
                  ? moment().startOf("day").add(12, "hours").toDate()
                  : null,
                accepted: null,
                deliveryConfirmation: null,
                fulfilled: null,
                shipped: null,
                completed: null,
                cancelled: null,
              },
            });
          });
        } catch (e) {
          console.log("Transaction failed: ", e);
          abort = true;
        }
      } catch (error) {
        console.log(error);
        abort = true;
      } finally {
        // Check to make sure the order was successfully submitted
        //    - if the order was successfully submitted, add metrics to brandStats and push user to order view
        //    - if the order was not successfully submitted, delete the order on MyTrace and/or Leaflink.
        let orderResponse = await getDoc(salesOrderDoc);
        batch = writeBatch(firestore);
        if (orderResponse.exists()) {
          // Order successfully submitted
          this.response.orderDoc = {
            id: orderResponse.id,
            ...orderResponse.data(),
          };
          batch.set(
            doc(
              firestore,
              "brandStats",
              `${this.rightDrawer.data.accountDetails.account.id}_${this.rightDrawer.data.accountDetails.brandStats.brand.id}`
            ),
            {
              metrics: {
                last: {
                  order: moment().startOf("day").toDate(),
                },
                orders: {
                  total: increment(1),
                },
              },
            },
            { merge: true }
          );

          // Update status to Samples Sent
          if (
            this.orderType === "sample" &&
            ["lead_cold", "lead_hot"].includes(
              this.rightDrawer.data.accountDetails.brandStats.status.value
            )
          ) {
            const oldValue = this.getAccountStatus.find(
                (option) =>
                  option.value ===
                  this.rightDrawer.data.accountDetails.brandStats.status.value
              ),
              newValue = this.getAccountStatus.find(
                (option) => option.value === "samples_sent"
              );

            batch.set(
              doc(
                firestore,
                "brandStats",
                `${this.rightDrawer.data.accountDetails.account.id}_${this.rightDrawer.data.accountDetails.brandStats.brand.id}`
              ),
              {
                status: "samples_sent",
              },
              { merge: true }
            );
            batch.set(
              doc(collection(firestore, "ledger")),
              {
                created: serverTimestamp(),
                relatedID: `${this.rightDrawer.data.accountDetails.account.id}_${this.rightDrawer.data.accountDetails.brandStats.brand.id}`,
                relatedTo: "accounts",
                action: `<span class="text-capitalize">${
                  this.getUser.name.first
                } ${
                  this.getUser.name.last
                }</span> <span class="text-uppercase font-weight-bold" style="color: ${
                  this.$vuetify.theme.themes.dark.update
                };">updated</span> <span class="text-capitalize">${
                  this.rightDrawer.data.accountDetails.brandStats.brand.name
                }</span> with Order #${
                  this.rightDrawer.data.accountDetails.brandStats.metrics.orders
                    .total + 1
                } | Status - <span class="text-uppercase">old</span>: <span class="font-weight-bold" style="color: ${
                  oldValue.color
                };">${
                  oldValue.text
                }</span> => <span class="text-uppercase">new</span>: <span class="font-weight-bold" style="color: ${
                  newValue.color
                };">${newValue.text}</span>`,
                user: {
                  name: this.getUser.name,
                  uid: this.getUser.uid,
                },
              },
              { merge: true }
            );
          }
          let approvalReasons = [];
          let approvalNotes = "";
          if (this.orderNeedsApproval) {
            if (this.accountStatusNeedsApproval) {
              approvalReasons.push("Account Status");
            }
            if (this.productsOnSale) {
              approvalReasons.push(`Products on Sale`);
            }
            if (this.accountHasBalance) {
              approvalReasons.push("Account Balance is greater than zero");
            }
            approvalNotes = ` | Reason${
              approvalReasons.length === 1 ? "" : "s"
            } for approval requirement: ${approvalReasons.join(", ")}`;

            const userDoc = doc(firestore, "users", this.getUser.uid);
            const userResponse = await getDoc(userDoc);

            if (userResponse.data().reportsTo?.length > 0) {
              //  Send notification to currentUser.reportsTo

              const userDoc = doc(
                firestore,
                "users",
                userResponse.data().reportsTo
              );
              const response = await getDoc(userDoc);

              let reportsTo = null;

              let notificationNotes = `Order Approval Required`;

              if (response.exists()) {
                reportsTo = {
                  name: response.data().name,
                  id: userResponse.data().reportsTo,
                };
                batch.set(
                  doc(collection(firestore, "notifications")),
                  {
                    created: serverTimestamp(),
                    updated: serverTimestamp(),
                    assignedTo: {
                      name: reportsTo.name,
                      uid: reportsTo.id,
                    },
                    createdBy: {
                      name: this.getUser.name,
                      uid: this.getUser.uid,
                    },
                    lastUpdated: {
                      name: this.getUser.name,
                      uid: this.getUser.uid,
                    },
                    notes: notificationNotes,
                    relatedTo: {
                      id: salesOrderDoc.id,
                      resource: "salesOrder",
                      name: `${this.$options.filters.capitalize(
                        this.rightDrawer.data.accountDetails.account.name
                      )} #${
                        this.rightDrawer.data.accountDetails.brandStats.metrics
                          .orders.total + 1
                      }`,
                      type: "sales order",
                    },
                    viewed: false,
                  },
                  { merge: true }
                );
              }
            }
          }

          batch.set(
            doc(collection(firestore, "ledger")),
            {
              created: serverTimestamp(),
              relatedID: salesOrderDoc.id,
              relatedTo: "salesOrders",
              action: `<span class="text-capitalize">${
                this.getUser.name.first
              } ${
                this.getUser.name.last
              }</span> created Sales Order for <span class="text-capitalize">${
                this.rightDrawer.data.accountDetails.account.name
              }</span> | <span class="text-uppercase">status</span>: <span class="text-capitalize font-weight-bold">${
                this.orderNeedsApproval ? "awaiting approval" : "submitted"
              }</span>${approvalNotes}`,
              user: {
                name: this.getUser.name,
                uid: this.getUser.uid,
              },
            },
            { merge: true }
          );
          await batch.commit();
        }
      }

      batch = writeBatch(firestore);
      let leaflinkIDError = false;
      if (!abort) {
        try {
          if (isExternal) {
            // Create External Order
            switch (externalSystem) {
              case "leaflink":
                if (this.accountHasLeaflinkID) {
                  externalOrder = await this.submitLeaflinkOrder(
                    payableTo,
                    lineItems
                  );
                } else {
                  leaflinkIDError = true;
                  throw new Error("Leaflink ID not found");
                }
                let externalIDs = {};
                externalIDs = {
                  leaflink: externalOrder.number,
                };
                batch.set(
                  salesOrderDoc,
                  {
                    lineItems,
                    externalIDs,
                    orderNumber: {
                      external: externalOrder.short_id ?? "",
                    },
                  },
                  { merge: true }
                );
                if (this.response.orderDoc) {
                  this.response.orderDoc.orderNumber.external =
                    externalOrder.short_id ?? "";
                }
                break;
              default:
                break;
            }
          }

          // Line Items
          lineItems.forEach((lineItem) => {
            batch.set(
              doc(collection(firestore, "salesOrders_lineItems")),
              {
                created: serverTimestamp(),
                updated: serverTimestamp(),
                ...lineItem,
              },
              { merge: true }
            );

            // Add to reserved at location

            batch.set(
              locationRef,
              {
                manufacturing: {
                  [lineItem.categoryID]: {
                    reserved: {
                      [lineItem.productID]: increment(lineItem.qty),
                    },
                  },
                },
              },
              { merge: true }
            );
          });
          await batch.commit();
        } catch (error) {
          console.error(error);
          abort = true;
        }
      }

      // Delete Internal Order if need to abort
      this.loading.submit = false;
      this.$emit("cancel-submit");
      if (abort) {
        await deleteDoc(salesOrderDoc);
        if (leaflinkIDError) {
          this.leaflinkIDAlert = true;
        } else {
          this.errorDialog = true;
        }
      } else {
        this.reviewDialog = true;
        let savingsStyle =
          this.totalDiscounts > 0 ? "color: #556B2F" : "color: #000000";
        let savings = `<span style="${savingsStyle}">${this.$options.filters.currency(
          this.totalDiscounts
        )}</span>`;

        let contactHasEmail = contact?.email?.length > 0;
        if (payableTo.flags.orderEmails && contactHasEmail && this.sendEmail) {
          let cc = new Set();
          if (
            this.rightDrawer.data.accountDetails.brandStats.staff.manager.email
              .length > 0
          ) {
            cc.add(
              this.rightDrawer.data.accountDetails.brandStats.staff.manager
                .email
            );
          }
          if (
            this.rightDrawer.data.accountDetails.brandStats.staff.executive
              .email.length > 0
          ) {
            cc.add(
              this.rightDrawer.data.accountDetails.brandStats.staff.executive
                .email
            );
          }
          if (payableTo.staff.inventoryManager.uid) {
            let inventoryManagerUser = doc(
              firestore,
              "users",
              payableTo.staff.inventoryManager.uid
            );
            const inventoryManagerRes = await getDoc(inventoryManagerUser);
            cc.add(inventoryManagerRes.data().email);
          }
          if (payableTo.staff.accountManager.uid) {
            let accountManagerUser = doc(
              firestore,
              "users",
              payableTo.staff.accountManager.uid
            );
            const accountManagerRes = await getDoc(accountManagerUser);
            cc.add(accountManagerRes.data().email);
          }
          cc = Array.from(cc);

          await orderSubmissionEmail({
            sendgridOrderTemplate: "d-4eb8577c54cb43b1b7bc17654d71140d",
            cc,
            dynamic_template_data: {
              subject: `Your ${this.$options.filters.capitalize(
                this.rightDrawer.data.accountDetails.brandStats.brand.name
              )} order has been submitted | ${orderNumber.toUpperCase()}`,
              disposition: `Your order has been submitted by the ${this.$options.filters.capitalize(
                this.rightDrawer.data.accountDetails.brandStats.brand.name
              )} team. This will be shown in your "Active" orders tab in mytrace.`,
              brand: this.$options.filters.capitalize(
                this.rightDrawer.data.accountDetails.brandStats.brand.name
              ),
              name: this.$options.filters.capitalize(contact.name.first),
              toEmail: contact.email,
              paymentTerm: this.getRightDrawer.data.paymentTerm.text,
              totalQty: this.$options.filters.addComma(this.totalUnits),
              savings,
              subtotal: this.$options.filters.currency(this.orderTotal),
              shipping: this.$options.filters.currency(0),
              totalSum: this.$options.filters.currency(this.orderTotal),
              storeName: this.$options.filters.capitalize(
                this.getRightDrawer.data.accountDetails.account.name
              ),
              storeAddress: this.$options.filters.capitalize(
                this.shippingAddress
              ),
              deliveryPreferences:
                this.getRightDrawer.data.accountDetails.account
                  .deliveryPreferences.notes.length > 0
                  ? this.getRightDrawer.data.accountDetails.account
                      .deliveryPreferences.notes
                  : "-",
              notes:
                this.getRightDrawer.data.orderDetails.notes.length > 0
                  ? this.getRightDrawer.data.orderDetails.notes
                  : "-",
              licenseNumber:
                this.getRightDrawer.data.accountDetails.account.license.number.toUpperCase(),
              licenseExpiration: moment(
                this.getRightDrawer.data.accountDetails.account.license
                  .expiration.seconds * 1000
              ).format("MM/DD/YYYY"),
              lineItems: `<table width="100%">
                <thead>
                  <tr>
                    <th width="55%">Product</th>
                    <th width="15%">QTY</th>
                    <th width="15%">Price</th>
                    <th width="15%">Total</th>
                  </tr>
                </thead>
                <tbody>
                  ${lineItems.map(
                    (lineItem) => `<tr>
                    <td>${this.$options.filters.capitalize(
                      lineItem.name
                    )}<span style="display: block; font-size: 10px;">${this.$options.filters.capitalize(
                      lineItem.categoryName
                    )}</span></td>
                    <td>${this.$options.filters.addComma(lineItem.qty)}</td>
                    <td>${this.$options.filters.currency(lineItem.amount)}</td>
                    <td>${this.$options.filters.currency(
                      lineItem.amount * lineItem.qty
                    )}</td>
                  </tr>`
                  )}
                </tbody>
              </table>`,
            },
          });
        }

        // Replace with review dialog to view order/account
        // this.$router.push({
        //   name: "salesOrder-view",
        //   params: {
        //     id: salesOrderDoc.id,
        //   },
        // });
        this.reviewDialog = true;
      }
      this.loading.submit = false;
      this.contact = null;
      this.approvalConfirmation = null;
      this.shippingFee = 0;
      this.notes = "";
      this.activeViews.approvalConfirmation = true;
    },
    async submitLeaflinkOrder(payableTo, lineItems) {
      let externalOrder = null;
      const createLeafLinkOrder = httpsCallable(functions, "externalAPI");
      const { data } = await createLeafLinkOrder({
        method: "POST",
        headers: {
          Authorization: `App ${payableTo?.integrations.leaflink.api}`,
        },
        url: `${process.env.VUE_APP_LEAFLINK}api/v2/orders-received/`,
        data: {
          brand: payableTo?.integrations.leaflink.brandID,
          seller: payableTo?.integrations.leaflink.seller,
          customer: {
            id: Number(
              this.rightDrawer.data.accountDetails.account.externalIDs.leaflink
            ),
          },
          notes: this.getRightDrawer.data.orderDetails.notes,
          internal_notes: this.internalNotes,
          delivery_preferences: this.deliveryPrefs,
          payment_term: this.rightDrawer.data.paymentTerm.text,
          status: "Submitted",
          shipping_charge: {
            amount: this.shippingFee,
            currency: "USD",
          },
          line_items: lineItems.map((item) => {
            return {
              product: item.externalIDs.leaflink,
              quantity: item.qty,
              ordered_unit_price: {
                amount: item.amount,
                currency: "USD",
              },
              sale_price: {
                amount: item.amount,
                currency: "USD",
              },
              notes: item.notes,
              is_sample: item.sample,
            };
          }),
        },
      });

      const { data: data2 } = await createLeafLinkOrder({
        method: "GET",
        headers: {
          Authorization: `App ${payableTo?.integrations.leaflink.api}`,
        },
        url: `${process.env.VUE_APP_LEAFLINK}api/v2/orders-received/${data.number}/?include_children=line_items`,
      });

      externalOrder = data2;

      lineItems.forEach((lineItem) => {
        let leaflinkLineItem = data2.line_items.find((item) => {
          return (
            item.bulk_units === lineItem.qty &&
            item.product === lineItem.externalIDs.leaflink &&
            item.ordered_unit_price.amount === lineItem.amount
          );
        });

        lineItem.externalIDs = {
          ...lineItem.externalIDs,
          leaflinkLineItemID: leaflinkLineItem?.id || null,
        };
      });

      //  Leaflink API automatically sets the order's payment term to the default Leaflink payment option.
      //
      //  So, if we need to place an order with a term other than the default payment option on Leaflink,
      //  we've been advised by the Leaflink Integrations Team to patch the order using
      //  `available_payment_options` from the response of the API call

      let paymentOption = externalOrder.available_payment_options.find(
        (option) =>
          this.rightDrawer.data.paymentTerm.value.replace(/\s/g, "") ===
          option.payment_term.code
      );
      if (paymentOption) {
        const patchLeafLinkOrder = httpsCallable(functions, "externalAPI");
        const { patch } = await patchLeafLinkOrder({
          method: "PATCH",
          headers: {
            Authorization: `App ${payableTo?.integrations.leaflink.api}`,
          },
          url: `${process.env.VUE_APP_LEAFLINK}api/v2/orders-received/${externalOrder.order_number}`,
          data: {
            selected_payment_option: {
              payment_method_id: 1,
              payment_term_id: paymentOption.payment_term.id,
            },
          },
        });
      }
      return externalOrder;
    },
    async downloadInvoice() {
      this.loading.invoice = true;

      // Payable To
      const payableToRef = collection(firestore, "payableTo");
      const state = where(
          "location.state.stateID",
          "==",
          this.getRightDrawer.data.accountDetails.account.location.shipping
            .state.stateID
        ),
        active = where("active", "==", true),
        brandID = where(
          "brandID",
          "==",
          this.getRightDrawer.data.accountDetails.brandStats.brand.id
        );
      const q = query(payableToRef, active, state, brandID);
      const response = await getDocs(q, limit(1));

      const selectedState = response.docs[0].data();
      let payableTo = response.docs[0].data();
      // Brand
      const brandResponse = await getDoc(
        doc(
          firestore,
          "companyBrands",
          this.getRightDrawer.data.accountDetails.brandStats.brand.id
        )
      );

      let brand = {
        id: brandResponse.id,
        ...brandResponse.data(),
      };

      let externalOrder =
        selectedState.flags.leaflink &&
        this.getRightDrawer.data.accountDetails.account.externalIDs.leaflink;

      const selectedAccount =
        this.getRightDrawer.data.accountDetails.brandStats;
      // const brand = this.getRightDrawer.data.accountDetails.brandStats;

      const externalOrderNumber = "-";
      const internalOrderNumber = "-";
      const invoice = new jsPDF({ format: "letter" });
      let date = moment().format("M/D/YYYY");
      const fileName = `${selectedAccount.account.name.replace(
        /(\b[a-z](?!\s))/g,
        (l) => l.toUpperCase()
      )} - Draft ${date}`;

      const pageWidth = invoice.internal.pageSize.getWidth();

      // Header

      if (brand.flags.hasLogo) {
        let logoRef = ref(storage, `brand/${brand.id}/logo`);

        const bytes = await getBytes(logoRef);
        const metadata = await getMetadata(logoRef);

        const img =
          "data:" +
          metadata.contentType +
          ";base64," +
          new Buffer.from(bytes).toString("base64");

        invoice.addImage(img, 94, 10, 35, 35, "logo", "FAST");
      } else {
        invoice.setFontSize(24);
        invoice.setFont("helvetica", "bold");
        let brandName = brand.name.replace(/\b\w/g, (l) => l.toUpperCase());
        invoice.text(brandName, 110, 30, "center");
      }

      // Customer
      invoice.setFontSize(10);
      invoice.setFont("helvetica", "bold");
      invoice.text("Store Information:", 10, 55);
      invoice.setFontSize(10);
      invoice.setFont("helvetica", "normal");
      const accountName =
        this.getRightDrawer.data.accountDetails.account.name.replace(
          /(\b[a-z](?!\s))/g,
          (l) => l.toUpperCase()
        );

      let phone = "";
      var cleaned = (
        "" + this.getRightDrawer.data.accountDetails.account.phone
      ).replace(/\D/g, "");
      var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        phone = "(" + match[1] + ") " + match[2] + "-" + match[3];
      }
      const phoneNumber = "\n" + phone;

      const license =
        "\n" +
        this.getRightDrawer.data.accountDetails.account.license.number.toUpperCase();

      //  the .splitTextToSize() method is able to wrap text based on the max-length
      //  given as the second param
      //    - this is necessary for our 3-column invoice format, as some account names
      //      are long enough to overlap the second column
      //    - using this method, we're able to join the account's name, phone number,
      //      and license number into one string and use line breaks to force them
      //      to their own lines
      //        - we have to do this to make the store information consistent regardless
      //          of the length of the account's name

      var wrappedStoreInformation = invoice.splitTextToSize(
        accountName + phoneNumber + license,
        53
      );
      invoice.text(wrappedStoreInformation, 10, 65);
      // Customer - Shipping Address
      const accountShippingAddress = `${
        this.getRightDrawer.data.accountDetails.account.location.shipping.street
          .number
      } ${this.getRightDrawer.data.accountDetails.account.location.shipping.street.name.replace(
        /\b\w/g,
        (l) => l.toUpperCase()
      )}`;
      const accountShippingCityZipCode = `${this.getRightDrawer.data.accountDetails.account.location.shipping.city.name.replace(
        /\b\w/g,
        (l) => l.toUpperCase()
      )}, ${this.getRightDrawer.data.accountDetails.account.location.shipping.state.abbreviation.toUpperCase()} ${
        this.getRightDrawer.data.accountDetails.account.location.shipping
          .zipCode
      }`;
      invoice.setFont("helvetica", "bold");
      invoice.setFontSize(10);
      invoice.text("Delivery Address:", 10, 85);
      invoice.setFontSize(10);
      invoice.setFont("helvetica", "normal");
      var wrappedShippingAddress = invoice.splitTextToSize(
        accountShippingAddress + `\n${accountShippingCityZipCode}`,
        53
      );
      invoice.text(wrappedShippingAddress, 10, 95);
      // Customer - Billing Address
      const accountBillingAddress = `${
        this.getRightDrawer.data.accountDetails.account.location.billing.street
          .number
      } ${this.getRightDrawer.data.accountDetails.account.location.billing.street.name.replace(
        /\b\w/g,
        (l) => l.toUpperCase()
      )}`;
      const accountBillingCityZipCode = `${this.getRightDrawer.data.accountDetails.account.location.billing.city.name.replace(
        /\b\w/g,
        (l) => l.toUpperCase()
      )}, ${this.getRightDrawer.data.accountDetails.account.location.billing.state.abbreviation.toUpperCase()} ${
        this.getRightDrawer.data.accountDetails.account.location.billing.zipCode
      }`;
      invoice.setFontSize(10);
      invoice.setFont("helvetica", "bold");
      invoice.text("Billing Address:", 10, 110);
      invoice.setFontSize(10);
      invoice.setFont("helvetica", "normal");
      if (this.getRightDrawer.data.accountDetails.account.flags.sameBilling) {
        invoice.text("Same as Delivery Address", 10, 120);
      } else {
        var wrappedBillingAddress = invoice.splitTextToSize(
          accountBillingAddress + `\n${accountBillingCityZipCode}`,
          53
        );
        invoice.text(wrappedBillingAddress, 10, 120);
      }
      let contact = this.getRightDrawer.data.contacts.find(
        (contact) =>
          contact.value === this.getRightDrawer.data?.orderDetails?.contact
      );
      if (this.getRightDrawer.data?.orderDetails?.contact?.length && contact) {
        // Buyer - Contact Information
        let startY = this.getRightDrawer.data.accountDetails.account.flags
          .sameBilling
          ? 130
          : 135;
        invoice.setFontSize(10);
        invoice.setFont("helvetica", "bold");
        invoice.text("Buyer Information:", 10, startY);
        invoice.setFontSize(10);
        invoice.setFont("helvetica", "normal");
        let buyerName = contact.fullName.replace(/\b\w/g, (l) =>
          l.toUpperCase()
        );
        invoice.text(buyerName, 10, startY + 10);
        invoice.text(contact.email, 10, startY + 15);
      }

      // Vertical Divider
      invoice.setDrawColor("#F2F5F6");
      invoice.line(70, 65, 70, 135, "S");
      invoice.stroke();

      // Licensee
      invoice.setFontSize(10);
      invoice.setFont("helvetica", "bold");
      invoice.text("Licensee:", 80, 55);
      invoice.setFontSize(10);
      invoice.setFont("helvetica", "normal");
      const payableToName = payableTo.name.replace(/\b\w/g, (l) =>
        l.toUpperCase()
      );

      let payableToPhone = "";
      var cleaned = ("" + payableTo.phoneNumber).replace(/\D/g, "");
      var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        payableToPhone = "(" + match[1] + ") " + match[2] + "-" + match[3];
      }
      const payableToPhoneNumber = "\n" + payableToPhone;

      const payableToLicense = "\n" + payableTo.license.number.toUpperCase();

      var wrappedPayableToInformation = invoice.splitTextToSize(
        payableToName + payableToPhoneNumber + payableToLicense,
        50
      );
      invoice.text(wrappedPayableToInformation, 80, 65);
      // Payable To - Address
      const payableToStreetAddress =
        !payableTo.location.street?.name || !payableTo.location.street?.number
          ? "[No Address Set]"
          : `${
              payableTo.location.street.number
            } ${payableTo.location.street.name.replace(/\b\w/g, (l) =>
              l.toUpperCase()
            )}`;
      invoice.setFont("helvetica", "bold");
      invoice.setFontSize(10);
      invoice.text("Address:", 80, 85);
      invoice.setFontSize(10);
      invoice.setFont("helvetica", "normal");

      let payableToAddress = `${payableToStreetAddress}\n${payableTo.location.city.replace(
        /\b\w/g,
        (l) => l.toUpperCase()
      )}, ${payableTo.location.state.abbreviation.toUpperCase()} ${
        payableTo.location.zipCode
      }`;

      var wrappedPayableToAddress = invoice.splitTextToSize(
        payableToAddress,
        50
      );
      invoice.text(wrappedPayableToAddress, 80, 95);

      // Vertical Divider
      invoice.setDrawColor("#F2F5F6");
      invoice.line(135, 65, 135, 135, "S");
      invoice.stroke();

      // Overview
      invoice.setFontSize(10);
      invoice.setFont("helvetica", "bold");
      invoice.text("Brand:", 145, 55);
      invoice.setFont("helvetica", "normal");
      invoice.setFontSize(10);
      invoice.text("Payment Term", 145, 65);

      let paymentTerm = this.getRightDrawer.data.paymentTerm;
      invoice.text(paymentTerm.text, 205, 65, {
        align: "right",
      });

      invoice.text("External Invoice", 145, 70);
      invoice.setFont("helvetica", "normal");

      invoice.text(externalOrderNumber, 205, 70, {
        align: "right",
      });
      invoice.text("Ship Date", 145, 75);
      invoice.setFont("helvetica", "normal");
      invoice.text("-", 205, 75, {
        align: "right",
      });
      invoice.text("Created", 145, 80);
      invoice.setFont("helvetica", "normal");
      invoice.text(moment().format("MMM DD, YYYY"), 205, 80, {
        align: "right",
      });
      invoice.setFont("helvetica", "normal");
      invoice.text("Created By", 145, 85);
      invoice.setFont("helvetica", "normal");
      const createdByName =
        this.getUser.name.first + " " + this.getUser.name.last;
      invoice.text(
        this.firstNameWithLastInitialFromValue(createdByName),
        205,
        85,
        {
          align: "right",
        }
      );
      invoice.setFontSize(10);
      invoice.setFont("helvetica", "bold");
      invoice.text("Account:", 145, 95);
      invoice.setFontSize(10);
      invoice.setFont("helvetica", "normal");

      let hasExecutive =
        selectedAccount.staff.executive.id &&
        selectedAccount.staff.executive.id.length > 0;
      let hasManager =
        selectedAccount.staff.manager.id &&
        selectedAccount.staff.manager.id.length > 0;

      if (hasExecutive) {
        const userDoc = doc(
          firestore,
          "users",
          selectedAccount.staff.executive.id
        );
        const response = await getDoc(userDoc);

        let executivePhoneNumber = "-";

        if (response.exists()) {
          executivePhoneNumber = response.data().phoneNumber
            ? this.$options.filters.phoneNumber(response.data().phoneNumber)
            : "-";
        }

        invoice.text("Executive", 145, 105);
        invoice.setFont("helvetica", "normal");
        invoice.text(
          this.firstNameWithLastInitialFromValue(
            selectedAccount.staff.executive.name
          ),
          205,
          105,
          {
            align: "right",
          }
        );
        invoice.text(executivePhoneNumber, 205, 110, {
          align: "right",
        });
        invoice.text(selectedAccount.staff.executive.email, 205, 115, {
          align: "right",
        });
      }
      invoice.setFont("helvetica", "normal");
      if (hasManager) {
        const userDoc = doc(
          firestore,
          "users",
          selectedAccount.staff.manager.id
        );
        const response = await getDoc(userDoc);

        let managerPhoneNumber = "-";

        if (response.exists()) {
          managerPhoneNumber = response.data().phoneNumber
            ? this.$options.filters.phoneNumber(response.data().phoneNumber)
            : "-";
        }

        invoice.text("Manager", 145, hasExecutive ? 125 : 105);
        invoice.setFont("helvetica", "normal");
        invoice.text(
          this.firstNameWithLastInitialFromValue(
            selectedAccount.staff.manager.name
          ),
          205,
          hasExecutive ? 125 : 105,
          {
            align: "right",
          }
        );
        invoice.text(managerPhoneNumber, 205, hasExecutive ? 130 : 110, {
          align: "right",
        });
        invoice.text(
          selectedAccount.staff.manager.email,
          205,
          hasExecutive ? 135 : 115,
          {
            align: "right",
          }
        );
      }

      // Divider
      let productsTableDividerY =
        !this.getRightDrawer.data.accountDetails.account.flags.sameBilling &&
        this.getRightDrawer.data?.orderDetails?.contact?.length
          ? 160
          : 150;

      invoice.setDrawColor("#F2F5F6");
      invoice.line(10, productsTableDividerY, 205, productsTableDividerY, "S");
      invoice.stroke();
      //   Products
      invoice.setFontSize(10);
      invoice.setFont("helvetica", "bold");
      invoice.text("Products:", 10, productsTableDividerY + 10);

      let addedProducts = this.rightDrawer.data.products
        .slice()
        .filter((item) => item.qty.case > 0 || item.qty.single > 0);

      let addedProductsCopy = addedProducts.map((item) => {
        return {
          ...item,
        };
      }); // fix cart items' originalPrice being set to listPrice if downloading invoice before submission

      this.response.priceOverrides = [];
      this.response.categories = [];

      await this.getProductCategories();
      await this.getPriceOverrides();

      let getListPriceForProduct = (product) => {
        let result = product.originalPrice;

        let category = this.response.categories.find(
          (item) => item.id === product.categoryID
        );

        let termNeeded =
          this.getRightDrawer.data.paymentTerm.value === "cod" ? "cod" : "net";

        if (category?.listPrice?.[termNeeded]) {
          result = category.listPrice?.[termNeeded];
        }

        let priceOverride = this.getPriceOverrideForProduct(product);

        if (priceOverride?.listPrice?.[termNeeded]) {
          result = priceOverride.listPrice?.[termNeeded];
        }
        return result;
      };

      let products = addedProductsCopy
        .filter((item) => !item.promo)
        .sort((a, b) => {
          if (a.category > b.category) {
            return 1;
          } else if (a.category === b.category && a.name > b.name) {
            return 1;
          } else if (a.category === b.category && a.name === b.name) {
            return 0;
          } else if (a.category === b.category && a.name < b.name) {
            return -1;
          } else if (a.category < b.category) {
            return -1;
          }
        })
        .map((item) => {
          let units = item.qty.case * item.caseQTY + item.qty.single;

          let productName = this.$options.filters.capitalize(item.name);
          let categoryName = this.$options.filters.capitalize(item.category);
          let price = item.discountApplied
            ? item.price - this.discountedValueOfItem(item)
            : item.price;

          let notes = item.notes?.length > 0 ? item.notes : `                 `;

          item.originalPrice = getListPriceForProduct(item);
          return [
            `${productName}\n${categoryName}`,
            item.sample ? `SAMPLE\n${notes}` : notes,
            // `${item.sample ? "Yes" : "No"}`,
            this.$options.filters.addComma(item.qty.case),
            this.$options.filters.addComma(item.qty.single),
            this.$options.filters.addComma(units),
            Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "usd",
            }).format(item.originalPrice),
            Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "usd",
            }).format(price),
            Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "usd",
            }).format(units * price),
            item.discount?.value ?? 0, // Case Discount Value
          ];
        });

      let promoUnitProducts = addedProductsCopy.filter((item) => item.promo);

      if (promoUnitProducts.length > 0) {
        promoUnitProducts = promoUnitProducts.sort((a, b) => {
          if (a.category > b.category) {
            return 1;
          } else if (a.category === b.category && a.name > b.name) {
            return 1;
          } else if (a.category === b.category && a.name === b.name) {
            return 0;
          } else if (a.category === b.category && a.name < b.name) {
            return -1;
          } else if (a.category < b.category) {
            return -1;
          }
        });
        products.push([
          {
            content: "",
            colSpan: 8,
            styles: { halign: "center", fillColor: 255 },
          },
        ]);
        products.push([
          {
            content: "PROMO UNITS",
            colSpan: 8,
            styles: {
              halign: "center",
              fillColor: 255,
              textColor: 0,
              fontStyle: "bold",
            },
          },
        ]);
        promoUnitProducts.forEach((item) => {
          let units = item.qty.case * item.caseQTY + item.qty.single;

          let productName = this.$options.filters.capitalize(item.name);
          let categoryName = this.$options.filters.capitalize(item.category);

          let notes = item.notes?.length > 0 ? item.notes : `                 `;

          item.originalPrice = getListPriceForProduct(item);
          products.push([
            `${productName}\n${categoryName}`,
            item.sample ? `SAMPLE\n${notes}` : notes,
            // `${item.sample ? "Yes" : "No"}`,
            this.$options.filters.addComma(item.qty.case),
            this.$options.filters.addComma(item.qty.single),
            this.$options.filters.addComma(units),
            Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "usd",
            }).format(item.originalPrice),
            Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "usd",
            }).format(item.price),
            Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "usd",
            }).format(units * item.price),
            0, // Case Discount Value: Promo Items can not receive case discounts
          ]);
        });
      }
      invoice.autoTable({
        columns: [
          { dataKey: "item", header: "Item" },
          { dataKey: "notes", header: "Notes" },
          { dataKey: "cases", header: "Cases" },
          { dataKey: "singles", header: "Singles" },
          { dataKey: "units", header: "Units" },
          { dataKey: "listPrice", header: "List Price" },
          { dataKey: "salePrice", header: "Sale Price" },
          { dataKey: "total", header: "Total" },
        ],
        body: products,
        startY: productsTableDividerY + 15,
        margin: {
          right: 10,
          left: 10,
          bottom: 15,
          top: 20,
        },
        theme: "striped",
        headStyles: {
          fillColor: "black",
          textColor: "white",
          fontSize: 9,
        },
        columnStyles: {
          0: { halign: "left" },
          1: { halign: "left" },
          2: { halign: "right" },
          3: { halign: "right" },
          4: { halign: "right" },
          5: { halign: "right" },
          6: { halign: "right" },
          7: { halign: "right" },
        },
        bodyStyles: {
          fontSize: 9,
        },
        didParseCell: (data) => {
          if (data.section === "head") {
            if (data.column.index > 1) {
              data.cell.styles.halign = "right";
            }
          }
        },
        willDrawCell: (data) => {
          if (data.section === "body") {
            if (data.column.index === 0) {
              const cell = data.cell;
              cell.text = [];
            }
          }
        },
        didDrawCell: (data) => {
          if (
            data.section === "body" &&
            data.column.dataKey === "total" &&
            data.row.cells.listPrice
          ) {
            let currencyFormat = (value) => {
              return Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "usd",
              }).format(value);
            };
            let listPrice = Number(
              parseFloat(data.row.cells.listPrice.raw.replace("$", "")).toFixed(
                2
              )
            );
            let salePrice = Number(
              parseFloat(data.row.cells.salePrice.raw.replace("$", "")).toFixed(
                2
              )
            );
            // console.log(data.row.cells.item.raw, listPrice, salePrice);
            if (salePrice < listPrice) {
              let discountAmount = Number(
                parseFloat(listPrice - salePrice).toFixed(2)
              );
              let totalSaved = currencyFormat(
                discountAmount *
                  Number(
                    parseFloat(
                      data.row.cells.units.raw.replace(",", "")
                    ).toFixed(2)
                  )
              );
              let caseDiscountAppliedAmount = data.row.raw[8];
              let discountString =
                caseDiscountAppliedAmount > 0
                  ? `${currencyFormat(
                      caseDiscountAppliedAmount
                    )} CASE DISCOUNT / TOTAL DISCOUNT: ${totalSaved}`
                  : `TOTAL DISCOUNT: ${totalSaved}`;
              invoice.setFont("helvetica", "bolditalic");
              invoice.setFontSize(6);
              invoice.text(
                data.cell.x + data.cell.width - 2,
                data.cell.y + 9,
                discountString,
                { align: "right" }
              );
            }
          } else if (data.section === "body" && data.column.index === 0) {
            const cell = data.cell;
            const text = cell.raw ?? "";
            // cell.text = [];
            let splitInfo = cell.colSpan > 1 ? [] : text.split("\n");
            let isHeaderRow = cell.colSpan > 1;

            let { x, y, width } = cell;
            if (isHeaderRow) {
              invoice.setFont("helvetica", "bold");
              invoice.text(text.content, pageWidth / 2, y + 5, {
                align: "center",
              });
            } else {
              let cursorX = x;
              splitInfo.forEach((part, i) => {
                if (i === 0) {
                  invoice.setFont("helvetica", "normal");
                  invoice.text(part, cursorX + 2, y + 4.5);
                  y += invoice.getTextDimensions(part).h;
                } else {
                  invoice.setFont("helvetica", "italic");

                  invoice.setFontSize(8);
                  invoice.text(part, cursorX + 2, y + 5);
                  invoice.setFont("helvetica", "normal");

                  invoice.setFontSize(9);
                }
              });
            }
          }
        },
      });

      //  We need to check the `pageNumber` property of the products table to see if it
      //    overflowed past page 1.
      //
      //    - If it did, jspdf's `previousAutoTable` has a `finalY`
      //    property that we can add to the y values of all of the content after the
      //    product's table to place the content below the products table correctly.
      //
      //    - If the table did not overflow , we set this value to 0 as we
      //    do not have to modify the position of anything on the second page

      if (
        invoice.previousAutoTable.pageNumber === 1 ||
        invoice.previousAutoTable.finalY > 160
      ) {
        invoice.addPage();
        invoice.setPage(
          invoice.previousAutoTable.finalY > 160
            ? invoice.previousAutoTable.pageNumber + 1
            : invoice.previousAutoTable.pageNumber
        );
      }

      const productsTableFinalY =
        invoice.previousAutoTable.pageNumber === 1 ||
        invoice.previousAutoTable.finalY > 160
          ? 0
          : invoice.previousAutoTable.finalY;

      // Notes
      invoice.setFont("helvetica", "bold");
      invoice.setFontSize(10);
      invoice.text(10, 25 + productsTableFinalY, `Notes:`);
      invoice.setFont("helvetica", "italic");
      invoice.setFontSize(9);

      var wrappedNotesForDimensions = invoice.splitTextToSize(
        this.getRightDrawer.data.orderDetails.notes,
        100
      );
      let notesDimensions = invoice.getTextDimensions(
        wrappedNotesForDimensions
      );
      let notesSubstring =
        this.getRightDrawer.data.orderDetails.notes.length < 600 &&
        notesDimensions.h <= 52.5
          ? this.getRightDrawer.data.orderDetails.notes
          : this.getRightDrawer.data.orderDetails.notes.substr(0, 600) + "...";

      var wrappedNotes = invoice.splitTextToSize(notesSubstring, 100);
      invoice.text(wrappedNotes, 10, 35 + productsTableFinalY);

      // Delivery Preferences
      invoice.setFont("helvetica", "bold");
      invoice.setFontSize(10);
      invoice.text(10, 85 + productsTableFinalY, `Delivery Preferences:`);
      invoice.setFont("helvetica", "italic");
      invoice.setFontSize(9);

      var wrappedDeliveryPreferences = invoice.splitTextToSize(
        this.getRightDrawer.data.accountDetails.account.deliveryPreferences
          .notes,
        pageWidth - 20
      );
      let deliveryPreferencesDimensions = invoice.getTextDimensions(
        wrappedDeliveryPreferences
      );
      invoice.text(wrappedDeliveryPreferences, 10, 95 + productsTableFinalY);

      let totalSaved = addedProductsCopy
        .filter((item) => item.price < item.originalPrice)
        .reduce((sum, item) => {
          let price = item.discountApplied
            ? item.price - this.discountedValueOfItem(item)
            : item.price;
          let discountAmount = item.originalPrice - price;

          let units = item.qty.case * item.caseQTY + item.qty.single;
          return sum + discountAmount * units;
        }, 0);
      let totalCaseDiscounts = addedProductsCopy
        .filter((item) => item.discount?.value > 0)
        .reduce((sum, item) => {
          let discountAmount = item.discount.value;

          let units = item.qty.case * item.caseQTY + item.qty.single;
          return sum + discountAmount * units;
        }, 0);
      // Balance

      invoice.setFontSize(10);
      invoice.setFont("helvetica", "normal");
      invoice.text(
        `Subtotal (Qty: ${this.$options.filters.addComma(this.totalUnits)})`,
        125,
        22.5 + productsTableFinalY
      );
      invoice.text(
        Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "usd",
        }).format(this.orderTotal + totalSaved - this.shippingFee),
        205,
        22.5 + productsTableFinalY,
        {
          align: "right",
        }
      );
      invoice.setDrawColor("#E5E7EB");
      invoice.line(
        120,
        25 + productsTableFinalY,
        205,
        25 + productsTableFinalY,
        "S"
      );
      invoice.text(`List Price Discounts`, 125, 30 + productsTableFinalY);

      invoice.text(
        `${totalSaved - totalCaseDiscounts > 0 ? "-" : ""}${Intl.NumberFormat(
          "en-US",
          {
            style: "currency",
            currency: "usd",
          }
        ).format(totalSaved - totalCaseDiscounts)}`,
        205,
        30 + productsTableFinalY,
        {
          align: "right",
        }
      );
      invoice.line(
        120,
        32.5 + productsTableFinalY,
        205,
        32.5 + productsTableFinalY,
        "S"
      );
      invoice.stroke();
      invoice.text(`Case Discounts`, 125, 37.5 + productsTableFinalY);

      invoice.text(
        `${totalCaseDiscounts > 0 ? "-" : ""}${Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "usd",
        }).format(totalCaseDiscounts)}`,
        205,
        37.5 + productsTableFinalY,
        {
          align: "right",
        }
      );
      invoice.line(
        120,
        40 + productsTableFinalY,
        205,
        40 + productsTableFinalY,
        "S"
      );
      invoice.stroke();
      invoice.text("Shipping", 125, 45 + productsTableFinalY);
      invoice.text(
        Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "usd",
        }).format(this.shippingFee),
        205,
        45 + productsTableFinalY,
        {
          align: "right",
        }
      );
      invoice.setDrawColor("#E5E7EB");
      invoice.line(
        120,
        47.5 + productsTableFinalY,
        205,
        47.5 + productsTableFinalY,
        "S"
      );
      invoice.stroke();

      // Total
      invoice.setFont("helvetica", "normal");
      invoice.setFontSize(10);
      invoice.text("Total", 125, 52.5 + productsTableFinalY);
      invoice.text(
        Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "usd",
        }).format(this.orderTotal),
        205,
        52.5 + productsTableFinalY,
        {
          align: "right",
        }
      );

      let startYPosition =
        115 + productsTableFinalY + deliveryPreferencesDimensions.h;
      if (payableTo.invoiceURLData?.url?.length) {
        if (startYPosition > 225) {
          invoice.addPage();
          invoice.setPage(invoice.internal.getNumberOfPages());
        }

        startYPosition = startYPosition > 225 ? 20 : startYPosition;

        // Divider
        invoice.setDrawColor("#F2F5F6");
        invoice.line(10, startYPosition, 205, startYPosition, "S");
        invoice.stroke();

        //   External Links
        invoice.setFontSize(10);
        invoice.setFont("helvetica", "bold");
        invoice.text("External Links:", 10, startYPosition + 10);

        let QRCode = require("qrcode");
        let opts = {
          errorCorrectionLevel: "H",
          type: "image/jpeg",
          quality: 0.3,
          margin: 1,
          color: {
            dark: "#000000FF",
            light: "#FFFFFFFF",
          },
        };

        QRCode.toDataURL(
          payableTo.invoiceURLData.url,
          opts,
          function (err, url) {
            if (err) throw err;

            invoice.addImage(
              url,
              10,
              startYPosition + 15,
              20,
              20,
              "licenseeURL",
              "FAST"
            );
          }
        );

        invoice.setFont("helvetica", "normal");
        invoice.setFontSize(9);

        invoice.text(payableTo.invoiceURLData.url, 10, startYPosition + 40);

        let wrappedDescription = invoice.splitTextToSize(
          payableTo.invoiceURLData.description,
          pageWidth - 20
        );
        invoice.text(wrappedDescription, 10, startYPosition + 45);
      }

      // END
      var pageCount = invoice.internal.getNumberOfPages();
      for (let i = 1; i <= pageCount; i++) {
        invoice.setPage(i);
        invoice.setFontSize(10);
        invoice.setFont("helvetica", "bold");
        invoice.text("invoice - draft".toUpperCase(), 10, 10);
        invoice.setFont("helvetica", "normal");
        invoice.setFontSize(10);
        invoice.text(10, 15, `This order is pending submission.`);
        invoice.setFont("helvetica", "normal");
        invoice.setFontSize(8);
        invoice.text(
          110,
          270,
          `Page ${
            invoice.internal.getCurrentPageInfo().pageNumber
          } / ${pageCount}`,
          {
            align: "center",
          }
        );

        // Powered by MyTrace
        var img = new Image();
        img.src = "/img/myTrace_fullLogo.png";
        invoice.addImage(
          img,
          "png",
          pageWidth - 28,
          5,
          20,
          9,
          "mytraceLogo",
          "FAST"
        );
        invoice.setFont("helvetica", "normal");
        invoice.setFontSize(7);
        invoice.text(pageWidth - 26, 10, `Powered by`, {
          align: "right",
        });

        if (i === 1) {
          let QRCode = require("qrcode");
          let opts = {
            errorCorrectionLevel: "H",
            type: "image/jpeg",
            quality: 0.3,
            margin: 1,
            color: {
              dark: "#000000FF",
              light: "#FFFFFFFF",
            },
          };

          // QRCode.toDataURL(
          //   "https://app.mytrace.com",
          //   opts,
          //   function (err, url) {
          //     if (err) throw err;

          //     invoice.addImage(
          //       url,
          //       pageWidth - 25,
          //       12.5,
          //       15,
          //       15,
          //       "mytraceURL",
          //       "FAST"
          //     );
          //   }
          // );
          // invoice.setFontSize(6);
          // let wrappedQRText = invoice.splitTextToSize(
          //   "Scan to place an order today!",
          //   15
          // );
          // invoice.text(pageWidth - 17.5, 30, wrappedQRText, {
          //   align: "center",
          // });
        }
      }

      // invoice.output("dataurlnewwindow");      //  Opens in a new tab, doesn't work on iOS/Safari
      invoice.save(`${fileName}.pdf`);
      this.loading.invoice = false;
    },
    /* Main */
    async setup() {
      this.loading.submit = false;
      this.contact = null;
      this.approvalConfirmation = null;
      this.shippingFee = 0;

      if (this.getRightDrawer.data.accountDetails.brandStats.metrics.balance) {
        this.activeViews.approvalConfirmation = true;
      }
    },
    traverse(action, params) {
      switch (action) {
        case "nextStep-view":
          this.$router.push({
            name: "salesOrder-view",
            params: {
              id: this.response.orderDoc.id,
            },
          });
          break;
        case "nextStep-newOrder":
          this.$router.go(0);
          break;
      }
    },
    removeItem(item) {
      const index = this.rightDrawer.data.products.indexOf(item);
      if (index > -1) {
        this.rightDrawer.data.products.splice(index, 1);
      }
      if (!this.rightDrawer.data.products.length) this.$emit("cancel-edit");
    },
    async openPrice(item) {
      if (item.promo || this.orderType === "sample" || !this.editMode) return;
      item.menu.price = true;
      item.newPrice = this.$options.filters.currency(item.price).substring(1);
      let element = this.$refs[this.refForItem(item)];
      this.$nextTick(() => {
        if (element) {
          setTimeout(() => {
            element?.$el?.focus();
            element?.$refs?.input?.select();
          }, 250);
        }
      });
    },
    openQTY(item) {
      if (!this.editMode) return;
      item.newQTY = item.totalQTY;
      item.menu.qty = true;
    },
    closePrice(item, type) {
      switch (type) {
        case "save":
          let price =
            Number(item.newPrice) > 0.01
              ? Number(item.newPrice)
              : Number(item.price);
          item.price = price;
          item.newPrice = item.price;
          item.menu.price = false;
          break;
        case "cancel":
          item.newPrice = Number(item.price);
          item.menu.price = false;
          break;
      }
    },
    refForItem(item) {
      return `priceInput_${item.id}${item.promo ? "_promo" : ""}`;
    },
    async handleProductQTYChange(item, val) {
      let idx = _.findIndex(this.rightDrawer.data.products, item);
      if (val === null) {
        item.unitWindow = 1;
        item.newQTY = item.totalQTY;
        return;
      }
      let start = val;
      let startAmount = start;
      if (this.orderType === "sample") {
        item.qty.single = startAmount;
        item.totalQTY = startAmount;
        item.unitWindow = 1;
        return;
      }
      item.qty.case = 0;
      item.totalQTY = startAmount;
      if (item.caseQTY) {
        item.qty.case = Math.floor(startAmount / item.caseQTY);
        startAmount %= item.caseQTY;
      }
      item.qty.single = startAmount;

      item.unitWindow = 1;

      this.rightDrawer.data.products.splice(idx, 1, item);
    },
    async decrement(type, item) {
      let idx = _.findIndex(this.rightDrawer.data.products, item);
      switch (type) {
        case "single":
          if ((item.soldByCase || item.caseQTY) && this.orderType === "sales") {
            if (item.qty.single == 0) {
              //  Set single qty to caseQTY - 1, decrement case qty by 1
              item.qty.single = item.caseQTY - 1;
              item.qty.case -= 1;
            } else {
              item.qty.single -= 1;
            }
          } else {
            item.qty.single -= 1;
          }
          break;
        case "case":
          item.qty.case -= 1;
          break;
      }
      item.totalQTY = item.qty.single + item.qty.case * item.caseQTY;

      this.rightDrawer.data.products.splice(idx, 1, item);
    },
    async increment(type, item) {
      let idx = _.findIndex(this.rightDrawer.data.products, item);
      switch (type) {
        case "single":
          if ((item.soldByCase || item.caseQTY) && this.orderType === "sales") {
            if (item.qty.single == item.caseQTY - 1) {
              //  Reset single qty to 0, increment case qty by 1
              item.qty.single = 0;
              item.qty.case += 1;
            } else {
              item.qty.single += 1;
            }
          } else {
            item.qty.single += 1;
          }
          break;
        case "case":
          item.qty.case += 1;
          break;
      }
      item.totalQTY = item.qty.single + item.qty.case * item.caseQTY;
      this.rightDrawer.data.products.splice(idx, 1, item);
    },
    availableQTYForItem(item) {
      let totalInCart = this.rightDrawer.data.products
        .filter(
          (product) => product.id === item.id && product.promo !== item.promo
        )
        .reduce(
          (sum, product) =>
            sum + product.qty.single + product.qty.case * product.caseQTY,
          0
        );
      return item.available - totalInCart;
    },
    clearCart() {
      this.rightDrawer.data.products.splice(0);
    },
    enableSubmit() {
      this.$emit("disable-submit-button", false);
    },
    disableSubmit() {
      this.$emit("disable-submit-button", true);
    },
    firstNameWithLastInitialFromValue(name) {
      if (name.length > 0) {
        let capitalizedName = name
          .split(" ")
          .map((word) => `${word.charAt(0).toUpperCase()}${word.slice(1)}`);
        return `${capitalizedName[0]} ${capitalizedName[1].charAt(0)}.`;
      } else {
        return "[Name Not Set]";
      }
    },
    async handleContactSubmit(submit = false) {
      if (submit) {
        this.loading.submit = true;
        let batch = writeBatch(firestore);

        const contactDoc = doc(collection(firestore, "contacts"));

        let location = null;
        let externalIDs = {};
        let unitNumber = "";
        if (
          this.newContact.location.address_components.find((component) =>
            component.types.includes("subpremise")
          )
        ) {
          unitNumber = this.newContact.location.address_components.find(
            (component) => component.types.includes("subpremise")
          ).long_name;
        }
        const streetNumber = this.newContact.location.address_components
            .find((component) => component.types.includes("street_number"))
            .long_name.toLowerCase(),
          street = this.newContact.location.address_components
            .find((component) => component.types.includes("route"))
            .long_name.toLowerCase(),
          city =
            this.newContact.location.address_components
              .find((component) => component.types.includes("locality"))
              ?.long_name.toLowerCase() ??
            this.newContact.location.address_components
              .find((component) => component.types.includes("sublocality"))
              ?.long_name.toLowerCase() ??
            this.newContact.location.address_components
              .find((component) =>
                component.types.includes("administrative_area_level_3")
              )
              ?.long_name.toLowerCase() ??
            this.newContact.location.address_components
              .find((component) => component.types.includes("neighborhood"))
              ?.long_name.toLowerCase(),
          state = this.newContact.location.address_components
            .find((component) =>
              component.types.includes("administrative_area_level_1")
            )
            .long_name.toLowerCase(),
          stateAbbreviation = this.newContact.location.address_components
            .find((component) =>
              component.types.includes("administrative_area_level_1")
            )
            .short_name.toLowerCase(),
          country = this.newContact.location.address_components
            .find((component) => component.types.includes("country"))
            .long_name.toLowerCase(),
          zipCode = this.newContact.location.address_components
            .find((component) => component.types.includes("postal_code"))
            .long_name.toLowerCase();

        location = {
          street: {
            number: streetNumber,
            name: street,
          },
          city,
          state: {
            name: state,
            abbreviation: stateAbbreviation,
          },
          country,
          zipCode,
          unitNumber,
        };
        externalIDs = {
          google_places: this.newContact.location.place_id,
        };

        batch.set(
          contactDoc,
          {
            active: true,
            created: serverTimestamp(),
            updated: serverTimestamp(),
            createdBy: {
              name: this.getUser.name,
              uid: this.getUser.uid,
            },
            lastUpdated: {
              name: this.getUser.name,
              uid: this.getUser.uid,
            },
            related: {
              id: this.rightDrawer.data.accountDetails.account.id,
              name: this.rightDrawer.data.accountDetails.account.name,
              collection: "accounts",
            },
            phase: "sales",
            relatedID: this.rightDrawer.data.accountDetails.account.id,
            relatedTo: "accounts",
            name: {
              first: this.newContact.name.first.toLowerCase(),
              last: this.newContact.name.last.toLowerCase(),
            },
            externalIDs,
            location,
            email: this.newContact.email.toLowerCase(),
            phoneNumber: this.newContact.phoneNumber?.length
              ? this.cleanedPhoneNumber(this.newContact.phoneNumber)
              : "",
            title: this.newContact.title.value,
            contactPreference: this.newContact.contactPreference || "",
            notes: this.newContact.notes,
            phase: "sales",
            phaseView: "accounts",
          },
          { merge: true }
        );

        batch.set(
          doc(collection(firestore, "ledger")),
          {
            created: serverTimestamp(),
            date: serverTimestamp(),
            relatedID: contactDoc.id,
            relatedTo: "contacts",
            type: "status",
            action: `<span class="text-capitalize">${this.getUser.name.first} ${this.getUser.name.last}</span> created <span class="font-weight-bold text-capitalize">${this.newContact.name.first} ${this.newContact.name.last}</span> as an <span class="text-uppercase font-weight-bold" style="color: ${this.$vuetify.theme.themes.dark.success};">active</span> contact`,
            user: {
              name: this.getUser.name,
              uid: this.getUser.uid,
            },
          },
          { merge: true }
        );
        await batch.commit();

        let item = { ...this.newContact };

        item.expanded = false;
        item.fullName = `${this.newContact.name.first} ${this.newContact.name.last}`;
        item.isParentContact = false;
        item.selected = false;
        item.text = item.fullName;
        item.id = contactDoc.id;
        item.value = item.id;

        this.rightDrawer.data.contacts.splice(0, 0, item);
        this.loading.submit = false;
      }
      this.contactDialog = false;
      this.resetNewContact();
    },
    resetNewContact() {
      this.newContact = {
        name: {
          first: "",
          last: "",
        },
        phoneNumber: "",
        email: "",
        address: null,
      };
    },
    async openContactDialog() {
      let account = this.rightDrawer.data.accountDetails.account.location;
      this.newContact.locationInput = this.fullAddressFromAccount(account);
      this.contactDialog = true;
    },
    async setupGoogleAPI() {
      const google = await gmapsInit();
      this.google = google.maps;

      const geocoder = new this.google.Geocoder();
      this.geocoder = geocoder;
    },
    async placeSearch(payload) {
      if (payload) {
        const { results } = await this.geocoder.geocode({
          address: payload.text,
        });

        this.newContact.location = results[0];
        this.newContact.locationInput = results[0].formatted_address;
      } else {
        this.newContact.locationInput = "";
        this.newContact.location = null;
      }
    },
    fullAddressFromAccount(account) {
      let streetNumber = account.shipping.street.number || "";
      let streetName =
        account.shipping.street.name.replace(/\b\w/g, (l) => l.toUpperCase()) ||
        "";
      let city =
        account.shipping.city.name.replace(/\b\w/g, (l) => l.toUpperCase()) ||
        "";
      let state = account.shipping.state.abbreviation.toUpperCase() || "";
      let zipCode = account.shipping.zipCode || "";

      return `${streetNumber} ${streetName}, ${city}, ${state}  ${zipCode}`;
    },
    discountedValueOfItem(item) {
      // let result = 0;
      // if (item.discount.value.target !== "units") return result;
      // switch (item.discount.value.format) {
      //   case "dollar":
      //     result = item.discount.value.value;
      //     break;
      //   case "percentage":
      //     result = item.price * (item.discount.value.value / 100);
      //     break;
      // }
      return item.discount.value;
    },
    getPriceOverrideForProduct(product) {
      let overriddenCategoryIDs = this.response.priceOverrides.map(
        (override) => override.category.id
      );
      if (overriddenCategoryIDs.includes(product.categoryID)) {
        //  Price Override
        let override;
        if (
          this.response.priceOverrides
            .filter(
              (item) =>
                item.category.id === product.categoryID &&
                item.product &&
                item.product.id === product.productID
            )
            .find(
              (option) =>
                option.productSpecific &&
                option.product.id === product.productID
            )
        ) {
          // SKU Specific Price Override
          override = this.response.priceOverrides
            .filter((item) => item.category.id === product.categoryID)
            .find(
              (option) =>
                option.productSpecific &&
                option.product.id === product.productID
            );
        } else {
          // Payable To Price Override
          override = this.response.priceOverrides.find(
            (item) =>
              item.category.id === product.categoryID &&
              item.accountSpecific === false &&
              item.productSpecific === false
          );
        }
        if (override) {
          return override;
        } else {
          return null;
        }
      }
    },
  },
  computed: {
    ...mapGetters([
      "getAppName",
      "getUser",
      "getRightDrawer",
      "getContactTitles",
      "getAccountStatus",
      "getPaymentTerms",
    ]),
    rightDrawer: {
      get: function () {
        return this.getRightDrawer;
      },
      set: function (value) {
        this._rightDrawer({
          ...this.getRightDrawer,
          active: value,
        });
      },
    },
    shippingAddress() {
      return `${
        this.getRightDrawer.data.accountDetails.account.location.shipping.street
          .number
      } ${
        this.getRightDrawer.data.accountDetails.account.location.shipping.street
          .name
      }, ${
        this.getRightDrawer.data.accountDetails.account.location.shipping.city
          .name
      }, ${this.getRightDrawer.data.accountDetails.account.location.shipping.state.abbreviation.toUpperCase()} ${
        this.getRightDrawer.data.accountDetails.account.location.shipping
          .zipCode
      }`;
    },
    productsOnSale() {
      return this.rightDrawer.data.products.some(
        (item) => item.price < item.originalPrice && !item.promo
      );
    },
    accountStatusNeedsApproval() {
      let needsApprovalStatuses = ["active_delinquent", "credit_hold"];
      return needsApprovalStatuses.includes(
        this.getRightDrawer.data.accountDetails.brandStats.status.value
      );
    },
    orderNeedsApproval() {
      return (
        this.accountStatusNeedsApproval ||
        this.productsOnSale ||
        this.accountHasBalance
      );
    },
    productsTotal() {
      return this.rightDrawer.data.products
        .map((item) => {
          let price = item.discountApplied
            ? item.price - this.discountedValueOfItem(item)
            : item.price;
          return (item.qty.case * item.caseQTY + item.qty.single) * price;
        })
        .reduce((a, b) => a + b, 0);
    },
    totalDiscounts() {
      return (
        this.rightDrawer.data.products
          ?.filter((item) => item.discountApplied)
          .reduce(
            (a, b) =>
              a +
              this.discountedValueOfItem(b) *
                (b.qty.single + b.qty.case * b.caseQTY),
            0
          ) ?? 0
      );
    },
    forSaleUnitValue() {
      let amount = 0;
      if (!this.response.orderDoc) return amount;
      const forSaleUnits = this.response.orderDoc?.lineItems.filter(
        (lineItem) => !lineItem.promo
      );
      if (forSaleUnits.length > 0) {
        amount = forSaleUnits
          .map((lineItem) => lineItem.qty * lineItem.amount)
          .reduce((a, b) => a + b);
      }
      return amount;
    },
    totalPromotionDiscounts() {
      let amount = 0;
      if (!this.response.orderDoc) return amount;
      const promoLineItems = this.response.orderDoc?.lineItems.filter(
        (lineItem) => lineItem.promo
      );
      if (promoLineItems.length > 0) {
        amount = promoLineItems
          .map((lineItem) => lineItem.qty * lineItem.price.normal)
          .reduce((a, b) => a + b);
      }
      return amount;
    },
    orderTotal() {
      return Number(
        parseFloat(this.productsTotal + Number(this.shippingFee ?? 0)).toFixed(
          2
        )
      );
    },
    totalUnits() {
      return this.rightDrawer.data.products.reduce(
        (sum, item) => sum + (item.qty.single + item.qty.case * item.caseQTY),
        0
      );
    },
    promoPercentage() {
      let total = this.getRightDrawer.data?.products
        .filter((item) => !item.promo)
        .reduce((sum, item) => {
          let itemPrice = item.price - (item.discount?.value ?? 0);
          return (
            sum + itemPrice * (item.qty.single + item.qty.case * item.caseQTY)
          );
        }, 0);
      let promo = this.getRightDrawer.data?.products
        .filter((item) => item.promo)
        .reduce(
          (sum, item) =>
            sum +
            item.originalPrice *
              (item.qty.single + item.qty.case * item.caseQTY),
          0
        );

      return (promo / total) * 100;
    },
    accountHasBalance() {
      return (
        this.getRightDrawer.data.accountDetails.brandStats.metrics.balance > 0
      );
    },
    orderNotesSet() {
      let required = this.accountHasBalance;
      let notesSet = this.getRightDrawer.data?.orderDetails?.notes?.length > 0;
      return (required && notesSet) || !required;
    },
    contactSet() {
      return this.getRightDrawer.data?.orderDetails?.contact?.length;
    },
    approvalConfirmationSet() {
      return (
        (this.orderNeedsApproval && this.approvalConfirmation) ||
        !this.orderNeedsApproval
      );
    },
    invalidSubmission() {
      let result =
        this.orderNotesSet && this.contactSet && this.approvalConfirmationSet
          ? false
          : true;

      this.rightDrawer.data.disableSubmission = result;
      return result;
    },
    isDownloading() {
      return this.loading.invoice;
    },
    // Contact Submit
    newContactHasEmailOrPhone() {
      return (
        this.newContact.phoneNumber?.length || this.newContact.email?.length
      );
    },
    newContactHasValidEmail() {
      return (
        this.newContact.email?.length &&
        this.isValidEmail(this.newContact.email)
      );
    },
    validContact() {
      return (
        this.newContact.name.first?.length > 0 &&
        this.newContact.title?.value !== null &&
        this.newContact.location &&
        this.newContactHasValidEmail
      );
    },
    deliveryPrefs() {
      return (
        this.rightDrawer.data.accountDetails?.account?.deliveryPreferences
          ?.notes ?? ""
      );
    },
    internalNotes() {
      return this.rightDrawer.data?.accountDetails?.account?.notes ?? "";
    },
    productsUnavailable() {
      return (
        this.rightDrawer.data.products
          ?.filter((product) => {
            let matchingProducts = this.rightDrawer.data.products?.filter(
              (item) => product.id === item.id
            );
            let totalQTY = matchingProducts.reduce((sum, item) => {
              let itemQTY = item.qty.single + item.qty.case * item.caseQTY;
              return sum + itemQTY;
            }, 0);
            return totalQTY > product.available;
          })
          .map((item) => item.id) ?? []
      );
    },
    accountHasLeaflinkID() {
      return this.rightDrawer.data.accountDetails.account?.externalIDs
        .leaflink &&
        String(
          this.rightDrawer.data.accountDetails.account?.externalIDs.leaflink
        ).length > 0
        ? true
        : false;
    },
  },
};
</script>

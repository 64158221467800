<template>
  <v-form>
    <v-row>
      <v-col
        v-if="
          getRightDrawer.data.status.value === 'draft' &&
          getRightDrawer.data.approvalReasons?.length > 0
        "
        cols="12"
        class="pb-0"
      >
        <v-col cols="auto" class="pa-0 d-flex justify-center">
          <v-chip
            key="reasons"
            dark
            small
            color="deep-orange lighten-1"
            style="height: 100%"
          >
            <div class="d-flex flex-column align-center py-2">
              <div class="d-flex flex-row align-center">
                <v-icon small class="mr-1">mdi-information</v-icon>
                <span
                  class="font-weight-bold text-caption text-center text-uppercase"
                  >Reasons for Approval Requirement</span
                >
              </div>
              <div class="d-flex flex-column">
                <span class="d-block text-caption text-center text-wrap">{{
                  getRightDrawer.data.approvalReasons
                }}</span>
              </div>
            </div>
          </v-chip>
        </v-col>
      </v-col>
      <v-col cols="12">
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header class="px-3">
              <v-toolbar-title>Line Items</v-toolbar-title>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-data-table
                :headers="response.headers.lineItems"
                :items="groupedLineItems"
                hide-default-footer
                group-by="categoryName"
                mobile-breakpoint="0"
                :items-per-page="-1"
              >
                <template
                  v-slot:group.header="{
                    group,
                    items,
                    isOpen,
                    toggle,
                    remove,
                    headers,
                  }"
                >
                  <GroupByHeaderRow
                    :headers="headers"
                    :group="group"
                    :items="items"
                    :isOpen="isOpen"
                    :showRemoveIcon="false"
                    @toggle="toggle"
                    @remove="remove"
                  />
                </template>
                <template v-slot:item.number="{ item }">
                  <span>{{
                    groupedLineItems.findIndex(
                      (lineItem) =>
                        (lineItem.id && lineItem.id === item.id) ||
                        lineItem.sku.toLowerCase() === item.sku.toLowerCase()
                    ) + 1
                  }}</span>
                </template>
                <template v-slot:item.name="{ item }">
                  <span class="text-capitalize font-weight-bold d-block">{{
                    item.name
                  }}</span>
                  <span
                    class="text-uppercase text-caption text-no-wrap d-block"
                    >{{ item.sku }}</span
                  >
                  <span
                    v-if="item.promo"
                    class="text-caption text-no-wrap d-block my-1"
                  >
                    <span
                      class="font-weight-bold text-caption pa-1 rounded"
                      :style="`background-color: ${
                        $vuetify.theme.themes.dark.promoUnit
                      }; color: ${getTextColor(
                        $vuetify.theme.themes.dark.promoUnit
                      )}`"
                      >Promo
                      <v-icon
                        small
                        :style="`color: ${getTextColor(
                          $vuetify.theme.themes.dark.promoUnit
                        )}`"
                        >mdi-seal</v-icon
                      >
                    </span>
                  </span>
                  <span
                    v-if="getRightDrawer.data.sample"
                    class="text-caption text-no-wrap d-block my-1"
                  >
                    <span
                      class="font-weight-bold text-caption pa-1 rounded"
                      :style="`background-color: ${$vuetify.theme.themes.dark.review}; color: white`"
                      >Sample
                    </span>
                  </span>
                  <span
                    v-if="item.flags.fulfilled || item.flags.delivered"
                    class="text-caption text-no-wrap d-block my-1"
                  >
                    <span
                      class="font-weight-bold text-caption pa-1 rounded"
                      :style="`background-color: ${
                        item.flags.delivered
                          ? $vuetify.theme.themes.dark.shipping
                          : $vuetify.theme.themes.dark.products
                      }; color: ${getTextColor(
                        item.flags.fulfilled
                          ? $vuetify.theme.themes.dark.shipping
                          : $vuetify.theme.themes.dark.products
                      )}`"
                      >{{
                        item.flags.delivered ? "Delivered" : "Fulfilled"
                      }}</span
                    >
                  </span>
                </template>
                <template v-slot:item.sku="{ item }">
                  <span class="text-uppercase">{{ item.sku }}</span>
                </template>
                <template v-slot:item.categoryName="{ item }">
                  <span class="text-capitalize">{{ item.categoryName }}</span>
                </template>
                <template v-slot:item.sample="{ item }">
                  <template v-if="item.sample">
                    <v-icon :color="$vuetify.theme.themes.dark.review"
                      >mdi-alpha-s-circle
                    </v-icon>
                  </template>
                  <template v-else>
                    <span class="text--disabled">-</span>
                  </template>
                </template>
                <template v-slot:item.price="{ item }">
                  {{ item.amount | currency }}
                </template>
                <template v-slot:item.totalUnits="{ item }">
                  <span class="font-weight-bold">{{
                    item.qty | addComma
                  }}</span>
                </template>
                <template v-slot:item.total="{ item }">
                  <span>{{ (item.amount * item.qty) | currency }}</span>
                </template>
              </v-data-table>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
      <v-col cols="12" md="12" sm="12">
        <v-btn
          block
          rounded
          large
          :style="`background: ${$vuetify.theme.themes.dark.review}; color: ${$vuetify.theme.themes.dark.secondary};`"
          :loading="loading.invoice"
          @click="generateInvoice"
        >
          <v-icon class="mr-1">mdi-download</v-icon>
          <span>invoice</span>
        </v-btn>
      </v-col>
      <v-col cols="6" md="6">
        <v-text-field
          label="Total Units"
          :color="$vuetify.theme.themes.dark.primary"
          :value="getRightDrawer.data.units | addComma"
          hide-details
          readonly
        >
        </v-text-field>
      </v-col>
      <v-col cols="6" md="6" sm="12">
        <v-text-field
          label="Total Sum"
          :color="$vuetify.theme.themes.dark.primary"
          :value="getRightDrawer.data.total | currency"
          readonly
          hide-details
        ></v-text-field>
      </v-col>
      <v-col cols="6" md="6">
        <v-text-field
          label="Promo Units"
          :color="$vuetify.theme.themes.dark.primary"
          :background-color="
            promoUnitQty === 0 ? undefined : 'purple lighten-4'
          "
          :value="promoUnitQty | addComma"
          hide-details
          readonly
          :disabled="promoUnitQty === 0"
        >
        </v-text-field>
      </v-col>
      <v-col cols="6" md="6" sm="12">
        <v-text-field
          label="Promo Unit Value"
          :color="$vuetify.theme.themes.dark.primary"
          :value="promoUnitValue | currency"
          readonly
          :hint="`Percentage: ${Number(
            parseFloat(promoUnitPercentage).toFixed(2)
          )}%`"
          persistent-hint
          :disabled="promoUnitQty === 0"
        ></v-text-field>
      </v-col>
      <v-col cols="6" md="6">
        <v-text-field
          label="Status"
          :color="$vuetify.theme.themes.dark.primary"
          :value="getRightDrawer.data.status.text"
          hide-details
          readonly
        >
          <template v-slot:prepend-inner>
            <v-badge
              inline
              dot
              :color="getRightDrawer.data.status.color"
            ></v-badge>
          </template>
        </v-text-field>
      </v-col>
      <v-col
        cols="6"
        :md="
          !['draftOrder', 'mytrace_draft'].includes(
            getRightDrawer.data.status.value
          )
            ? 3
            : 6
        "
        sm="12"
      >
        <v-text-field
          label="Payment Term"
          :color="$vuetify.theme.themes.dark.primary"
          :value="getRightDrawer.data.paymentTermDef.text"
          readonly
          hide-details
        ></v-text-field>
      </v-col>
      <v-col
        v-if="
          !['draftOrder', 'mytrace_draft'].includes(
            getRightDrawer.data.status.value
          )
        "
        cols="6"
        md="3"
        sm="12"
      >
        <v-text-field
          label="Ship Date"
          :color="$vuetify.theme.themes.dark.primary"
          :value="
            getRightDrawer.data.shipDate
              ? $options.filters.moment(
                  getRightDrawer.data.shipDate,
                  'MM/DD/YYYY'
                )
              : null
          "
          readonly
          hide-details
          :disabled="!getRightDrawer.data.shipDate"
        ></v-text-field>
      </v-col>
      <v-col cols="6" md="6" sm="12">
        <v-text-field
          label="Internal Order #"
          :color="$vuetify.theme.themes.dark.primary"
          :value="
            getRightDrawer.data.orderNumber.internal
              ? String(getRightDrawer.data.orderNumber.internal).toUpperCase()
              : '-'
          "
          readonly
          hide-details
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" sm="12">
        <v-text-field
          label="External Order #"
          :color="$vuetify.theme.themes.dark.primary"
          :value="
            getRightDrawer.data.orderNumber.external?.length > 0
              ? getRightDrawer.data.orderNumber.external
              : '-'
          "
          readonly
          :disabled="getRightDrawer.data.orderNumber.external?.length === 0"
          hide-details
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="12" sm="12">
        <v-text-field
          label="Account"
          :color="$vuetify.theme.themes.dark.primary"
          :value="getRightDrawer.data.accountData?.name | capitalize"
          hide-details
          readonly
        >
          <template v-slot:append-outer>
            <v-btn
              small
              rounded
              :style="`background: ${$vuetify.theme.themes.dark.review}; color: ${$vuetify.theme.themes.dark.secondary};`"
              @click="
                traverse('account-view-newTab', {
                  id: getRightDrawer.data.accountID,
                })
              "
            >
              <span class="mr-1">view</span>
              <v-icon>{{ $vuetify.icons.values.view }}</v-icon>
            </v-btn>
          </template>
        </v-text-field>
      </v-col>
      <v-col cols="6" md="6" sm="12">
        <v-text-field
          label="Account Balance"
          :color="$vuetify.theme.themes.dark.primary"
          :value="
            $options.filters.currency(
              this.getRightDrawer.data.accountData.balance
            )
          "
          readonly
          hide-details
        ></v-text-field>
      </v-col>
      <v-col cols="6" md="6" sm="12">
        <v-text-field
          label="Account Past Due Balance"
          :color="$vuetify.theme.themes.dark.primary"
          :background-color="
            getRightDrawer.data.accountData.pastDueBalance === 0
              ? undefined
              : 'red lighten-4'
          "
          :value="
            $options.filters.currency(
              this.getRightDrawer.data.accountData.pastDueBalance ?? 0
            )
          "
          readonly
          hide-details
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="12" sm="12">
        <v-text-field
          label="License Number"
          :background-color="licenseExpired ? 'red lighten-3' : ''"
          :color="$vuetify.theme.themes.dark.primary"
          :value="getRightDrawer.data.accountData.license.number.toUpperCase()"
          :hint="`Expires: ${
            getRightDrawer.data.accountData.license?.expiration
              ? $options.filters.moment(
                  getRightDrawer.data.accountData.license.expiration.seconds *
                    1000,
                  'MM/DD/YYYY'
                )
              : '-'
          }`"
          persistent-hint
          readonly
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="12" sm="12">
        <v-text-field
          label="Phone Number"
          :color="$vuetify.theme.themes.dark.primary"
          readonly
          :value="getRightDrawer.data.accountData.phoneNumber | phoneNumber"
          hide-details
        >
          <template v-slot:append-outer>
            <v-btn
              v-if="getRightDrawer.data.accountData.phoneNumber?.length > 0"
              small
              rounded
              :style="`background: ${$vuetify.theme.themes.dark.review}; color: ${$vuetify.theme.themes.dark.secondary};`"
              @click="
                viewResource(
                  'phone',
                  getRightDrawer.data.accountData.phoneNumber
                )
              "
            >
              <span class="mr-1">call</span>
              <v-icon>{{ $vuetify.icons.values.phoneNumber }}</v-icon>
            </v-btn>
          </template>
        </v-text-field>
      </v-col>
      <v-col cols="12" md="12" sm="12">
        <v-text-field
          label="Email"
          hide-details
          :color="$vuetify.theme.themes.dark.primary"
          :value="getRightDrawer.data.accountData.email"
          readonly
          :disabled="getRightDrawer.data.accountData.email.length === 0"
        >
          <template v-slot:append-outer>
            <v-btn
              v-if="getRightDrawer.data.accountData.email.length > 0"
              small
              rounded
              :style="`background: ${$vuetify.theme.themes.dark.review}; color: ${$vuetify.theme.themes.dark.secondary};`"
              @click="
                viewResource('email', getRightDrawer.data.accountData.email)
              "
            >
              <span class="mr-1">email</span>
              <v-icon>{{ $vuetify.icons.values.email }}</v-icon>
            </v-btn>
          </template>
        </v-text-field>
      </v-col>
      <v-col cols="12" md="12" sm="12">
        <v-text-field
          label="Address"
          :color="$vuetify.theme.themes.dark.primary"
          :value="deliveryAddress"
          readonly
          hide-details
        >
          <template v-slot:append-outer>
            <v-btn
              small
              rounded
              :style="`background: ${$vuetify.theme.themes.dark.review}; color: ${$vuetify.theme.themes.dark.secondary};`"
              @click="loadAddress"
            >
              <span class="mr-1">map</span>
              <v-icon>{{ $vuetify.icons.values.map }}</v-icon>
            </v-btn>
          </template>
        </v-text-field>
      </v-col>
      <v-col cols="12" md="12" sm="12">
        <v-textarea
          label="Order Notes"
          :color="$vuetify.theme.themes.dark.primary"
          outlined
          :value="getRightDrawer.data.notes"
          readonly
          hide-details
          :disabled="getRightDrawer.data.notes.length === 0"
        ></v-textarea>
      </v-col>
      <v-col cols="12" md="6" sm="12">
        <v-textarea
          label="Internal Notes"
          :color="$vuetify.theme.themes.dark.primary"
          outlined
          :value="getRightDrawer.data.internalNotes"
          readonly
          hide-details
          :disabled="getRightDrawer.data.internalNotes.length === 0"
        ></v-textarea>
      </v-col>
      <v-col cols="12" md="6" sm="12">
        <v-textarea
          label="Delivery Preferences"
          outlined
          hide-details
          background-color="grey lighten-3"
          :color="$vuetify.theme.themes.dark.primary"
          :value="getRightDrawer.data.deliveryPreferences"
          readonly
          :disabled="
            getRightDrawer.data.deliveryPreferences &&
            getRightDrawer.data.deliveryPreferences.length === 0
          "
        ></v-textarea>
      </v-col>
      <v-col cols="6" md="6">
        <v-text-field
          label="Created"
          :color="$vuetify.theme.themes.dark.primary"
          :value="getRightDrawer.data.created | moment('MM/DD/YYYY @ hh:mm A')"
          persistent-hint
          :hint="`created by: ${getRightDrawer.data?.createdBy}` | capitalize"
          readonly
        >
        </v-text-field>
      </v-col>
      <v-col cols="6" md="6" sm="12">
        <v-text-field
          label="Updated"
          :color="$vuetify.theme.themes.dark.primary"
          :value="getRightDrawer.data.updated | moment('MM/DD/YYYY @ hh:mm A')"
          persistent-hint
          :hint="
            `last updated: ${getRightDrawer.data?.lastUpdated}` | capitalize
          "
          readonly
        ></v-text-field>
      </v-col>
      <template
        v-if="
          getRightDrawer.data.status.value === 'draft' &&
          getUser.accountType >= 3
        "
      >
        <v-col cols="12" class="d-flex justify-space-around">
          <v-col cols="6" class="pa-0 pr-1">
            <v-btn
              block
              rounded
              large
              :disabled="loading.submit || rejectionMenuActive"
              :loading="loading.approveOrder"
              :style="`background: ${$vuetify.theme.themes.dark.success}; color: ${$vuetify.theme.themes.dark.secondary};`"
              @click="approveOrder"
            >
              <span class="mr-1">approve</span>
              <v-icon>{{ $vuetify.icons.values.submit }}</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="6" class="pa-0 pl-1">
            <v-container fluid class="pa-0">
              <v-menu
                top
                class="rounded-lg"
                offset-y
                :close-on-content-click="false"
                ref="rejectionMenu"
                v-model="rejectionMenuActive"
              >
                <template v-slot:activator="{ on, props }">
                  <v-btn
                    v-on="on"
                    v-bind="props"
                    block
                    rounded
                    large
                    :disabled="
                      loading.submit ||
                      loading.approveOrder ||
                      rejectionMenuActive
                    "
                    :style="`background: ${$vuetify.theme.themes.dark.error}; color: ${$vuetify.theme.themes.dark.secondary};`"
                  >
                    <span class="mr-1">reject</span>
                    <v-icon>{{ $vuetify.icons.values.cancel }}</v-icon>
                  </v-btn>
                </template>
                <v-card class="rounded-lg">
                  <v-card-title>
                    <span class="text-capitalize">rejection notes</span>
                  </v-card-title>
                  <v-card-text>
                    <v-textarea
                      outlined
                      hide-details
                      :background-color="
                        rejectionNotes.length > 0 ? '' : 'yellow lighten-3'
                      "
                      :color="$vuetify.theme.themes.dark.primary"
                      v-model.trim="rejectionNotes"
                      label="Reason for rejection"
                    ></v-textarea>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn
                      small
                      rounded
                      :style="`background: ${$vuetify.theme.themes.dark.error}; color: ${$vuetify.theme.themes.dark.secondary};`"
                      @click="
                        $refs.rejectionMenu.save();
                        rejectionNotes = '';
                      "
                      :disabled="loading.submit"
                    >
                      <v-icon small class="mr-1">{{
                        $vuetify.icons.values.cancel
                      }}</v-icon>
                      <span>cancel</span>
                    </v-btn>
                    <v-spacer />
                    <v-btn
                      small
                      rounded
                      :style="`background: ${$vuetify.theme.themes.dark.success}; color: ${$vuetify.theme.themes.dark.secondary};`"
                      :loading="loading.submit"
                      @click="rejectOrder"
                      :disabled="rejectionNotes.length === 0"
                    >
                      <span class="mr-1">submit</span>
                      <v-icon small>{{ $vuetify.icons.values.submit }}</v-icon>
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-menu>
            </v-container>
          </v-col>
        </v-col>
      </template>
    </v-row>
  </v-form>
</template>

<script>
// Libraries
import { mapActions, mapGetters } from "vuex";
import {
  // Firestore
  firestore,
  collection,
  onSnapshot,
  getDoc,
  getDocs,
  doc,
  updateDoc,
  serverTimestamp,
  query,
  where,
  writeBatch,
  or,
  increment,
  limit,
  // Functions
  functions,
  httpsCallable,
  // Storage
  storage,
  ref,
  getDownloadURL,
  getMetadata,
  getBytes,
  uploadBytes,
} from "@/firebase/init";
import moment from "moment";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { Buffer } from "buffer";

export default {
  name: "SalesOrderPreview",
  data: () => ({
    loading: {
      submit: false,
      approveOrder: false,
      invoice: false,
    },
    response: {
      headers: {
        lineItems: [
          { text: "#", value: "number", sortable: false, groupable: false },
          { text: "Name", value: "name", sortable: false, groupable: false },
          { text: "Price", value: "price", sortable: false, groupable: false },
          {
            text: "Units",
            value: "totalUnits",
            sortable: false,
            groupable: false,
            align: "center",
          },
          {
            text: "Total",
            value: "total",
            sortable: false,
            groupable: false,
            align: "center",
          },
        ],
      },
      payableTo: null,
      brand: null,
      ordersToCollect: [],
      brandMetrics: null,
      lineItems: [],
      ordersForPreviousAccountBalance: [],
    },
    rejectionNotes: "",
    rejectionMenuActive: false,
  }),
  methods: {
    ...mapActions(["_rightDrawer"]),
    /* API */
    // GET
    // POST
    async approveOrder() {
      this.loading.approveOrder = true;

      const batch = writeBatch(firestore);

      let order = this.rightDrawer.data;

      const orderDoc = doc(firestore, "salesOrders", order.id);

      const oldValue = this.getOrderStatus.find(
          (status) => status.value === order.status.value
        ),
        newValue = this.getOrderStatus.find(
          (status) => status.value === "submitted"
        );

      // Update order
      batch.set(
        orderDoc,
        {
          updated: serverTimestamp(),
          lastUpdated: {
            name: this.getUser.name,
            uid: this.getUser.uid,
          },
          status: newValue.value,
          records: {
            submitted: moment().startOf("day").add(12, "hours").toDate(),
          },
        },
        { merge: true }
      );

      let updateString = `<span class="text-uppercase">approved</span>`;

      // Ledger
      batch.set(
        doc(collection(firestore, "ledger")),
        {
          created: serverTimestamp(),
          date: serverTimestamp(),
          relatedID: order.id,
          relatedTo: "salesOrders",
          action: `<span class="text-capitalize">${this.getUser.name.first} ${this.getUser.name.last}</span> ${updateString} Sales Order | <span class="text-capitalize">status</span> - <span class="text-uppercase">old</span>: <span class="font-weight-bold" style="color: ${oldValue.color};">${oldValue.text}</span> => <span class="text-uppercase">new</span>: <span class="font-weight-bold" style="color: ${newValue.color};">${newValue.text}</span>`,
          user: {
            name: this.getUser.name,
            uid: this.getUser.uid,
          },
        },
        { merge: true }
      );
      await batch.commit();
      this.loading.approveOrder = false;

      const notificationsRef = collection(firestore, "notifications");
      const related = where("relatedTo.id", "==", this.rightDrawer.data.id);
      const notes = where("notes", "==", "Order Approval Required");
      const viewed = where("viewed", "==", false);

      const q = query(notificationsRef, related, notes, viewed);

      const response = await getDocs(q);

      if (!response.empty) {
        await updateDoc(
          doc(firestore, "notifications", response.docs[0].id),
          {
            updated: serverTimestamp(),
            lastUpdated: {
              name: this.getUser.name,
              uid: this.getUser.uid,
            },
            viewed: true,
          },
          { merge: true }
        );
      }

      this._rightDrawer({
        active: false,
        title: null,
        type: null,
        data: {},
      });
      this.$emit("success-snackbar", {
        active: true,
        msg: "Successfully approved order!",
      });
      this.rejectionMenuActive = false;
    },
    async rejectOrder() {
      this.loading.submit = true;

      const batch = writeBatch(firestore);

      const stateDoc = doc(
        firestore,
        "states",
        this.rightDrawer.data.filters.state
      );
      const stateResponse = await getDoc(stateDoc);

      let fulfillmentLocation = stateResponse.data().fulfillmentLocation;

      const locationRef = doc(
        firestore,
        "locationManufacturing",
        `${fulfillmentLocation}_${this.rightDrawer.data.filters.brand}`
      );

      let order = this.rightDrawer.data;

      const orderDoc = doc(firestore, "salesOrders", order.id);

      const oldValue = this.getOrderStatus.find(
          (status) => status.value === order.status.value
        ),
        newValue = this.getOrderStatus.find(
          (status) => status.value === "cancelled"
        );

      // Update order
      batch.set(
        orderDoc,
        {
          updated: serverTimestamp(),
          lastUpdated: {
            name: this.getUser.name,
            uid: this.getUser.uid,
          },
          status: newValue.value,
          records: {
            cancelled: moment().startOf("day").add(12, "hours").toDate(),
          },
        },
        { merge: true }
      );

      let orderIsExternal = (order) => {
        return order.flags?.external;
      };

      let orderIsLeaflinkOrder = (order) => {
        return order.externalIDs?.leaflink?.length > 0;
      };

      let externalOrderType = (order) => {
        if (orderIsExternal(order)) {
          if (orderIsLeaflinkOrder(order)) {
            return "leaflink";
          }
        }
        return null;
      };

      if (orderIsExternal(order)) {
        switch (externalOrderType(order)) {
          case "leaflink":
            await this.transitionLeaflinkOrder("cancel", order);
            break;
          default:
            break;
        }
      }

      order.lineItems.forEach((item) => {
        batch.set(
          locationRef,
          {
            manufacturing: {
              [item.categoryID]: {
                reserved: {
                  [item.productID]: increment(-item.qty),
                },
              },
            },
          },
          { merge: true }
        );
      });

      let updateString = `<span class="text-uppercase">rejected</span>`;
      let rejectionNotes =
        this.rejectionNotes.length > 0
          ? ` | Notes: ${this.rejectionNotes}`
          : "";

      // Ledger
      batch.set(
        doc(collection(firestore, "ledger")),
        {
          created: serverTimestamp(),
          date: serverTimestamp(),
          relatedID: order.id,
          relatedTo: "salesOrders",
          action: `<span class="text-capitalize">${this.getUser.name.first} ${this.getUser.name.last}</span> ${updateString} Sales Order | <span class="text-capitalize">status</span> - <span class="text-uppercase">old</span>: <span class="font-weight-bold" style="color: ${oldValue.color};">${oldValue.text}</span> => <span class="text-uppercase">new</span>: <span class="font-weight-bold" style="color: ${newValue.color};">${newValue.text}</span>${rejectionNotes}`,
          user: {
            name: this.getUser.name,
            uid: this.getUser.uid,
          },
        },
        { merge: true }
      );

      await batch.commit();
      this.loading.submit = false;
      this.$refs.rejectionMenu.save();
      this.rejectionNotes = "";

      const notificationsRef = collection(firestore, "notifications");
      const related = where("relatedTo.id", "==", this.rightDrawer.data.id);
      const notes = where("notes", "==", "Order Approval Required");
      const viewed = where("viewed", "==", false);

      const q = query(notificationsRef, related, notes, viewed);

      const response = await getDocs(q);

      if (!response.empty) {
        await updateDoc(
          doc(firestore, "notifications", response.docs[0].id),
          {
            updated: serverTimestamp(),
            lastUpdated: {
              name: this.getUser.name,
              uid: this.getUser.uid,
            },
            viewed: true,
          },
          { merge: true }
        );
      }

      this._rightDrawer({
        active: false,
        title: null,
        type: null,
        data: {},
      });

      this.$emit("success-snackbar", {
        active: true,
        msg: "Successfully rejected order!",
      });

      this.rejectionMenuActive = false;
    },
    async transitionLeaflinkOrder(transition, order) {
      console.log("Transition Leaflink Order status:", transition);
      try {
        const updateOrderStatus = await httpsCallable(functions, "externalAPI");
        const { data } = await updateOrderStatus({
          method: "POST",
          headers: {
            Authorization: `App ${this.rightDrawer.data.payableTo.leaflink.api}`,
          },
          url: `${process.env.VUE_APP_LEAFLINK}api/v2/orders-received/${order.externalIDs.leaflink}/transition/${transition}/`,
        });
      } catch (e) {
        console.log(e);
        throw new Error(e);
      }
    },
    /* Main */
    async traverse(action, params) {
      let link;
      switch (action) {
        case "account-view-newTab":
          link = this.$router.resolve({
            name: "accounts-view",
            params: {
              id: params.id,
            },
          });
          window.open(link.href, "_blank");
          break;
      }
    },
    async getPayableTo() {
      let order = this.rightDrawer.data;
      const payableToRef = collection(firestore, "payableTo");
      const state = where(
          "location.state.stateID",
          "==",
          order.location.shipping.state.stateID
        ),
        active = where("active", "==", true),
        brand = where("brandID", "==", order.brand.id);
      const q = query(payableToRef, state, brand, active);
      const response = await getDocs(q, limit(1));

      this.response.payableTo = response.docs[0].data();
    },
    async getBrand() {
      let order = this.rightDrawer.data;
      const brandDoc = doc(firestore, "companyBrands", order.brand.id);
      const response = await getDoc(brandDoc);

      if (response.exists()) {
        this.response.brand = response.data();
      }
    },
    async getOrdersToCollect() {
      let order = this.rightDrawer.data;
      this.response.ordersToCollect = [];

      const ordersDoc = collection(firestore, "salesOrders");
      const relatedAccount = where("account.id", "==", order.accountID),
        relatedBrand = where("brand.id", "==", order.brand.id),
        status = where("status", "==", "shipped");
      const q = query(ordersDoc, relatedAccount, relatedBrand, status);

      onSnapshot(q, (response) => {
        if (!response.empty) {
          this.response.ordersToCollect = response.docs
            .filter(
              (doc) =>
                Number(parseFloat(doc.data().balance.due).toFixed(2)) > 0 &&
                doc.id !== order.id
            )
            .map((salesOrder) => {
              const status = this.getOrderStatus.find(
                (status) => status.value === salesOrder.data().status
              );

              const term = this.getPaymentTerms.find(
                (paymentTerm) =>
                  paymentTerm.value === salesOrder.data().paymentTerm
              );

              var paymentDueDate = moment(
                salesOrder.data().shipDate?.seconds * 1000
              )
                .add(term.due, "days")
                .toDate();

              return {
                id: salesOrder.id,
                created: moment(
                  salesOrder.data().created?.seconds * 1000
                ).toDate(),
                updated: moment(
                  salesOrder.data().updated?.seconds * 1000
                ).toDate(),
                internal: String(salesOrder.data().orderNumber.internal),
                external: salesOrder.data().orderNumber.external,
                createdBy: `${salesOrder.data().createdBy.name.first} ${
                  salesOrder.data().createdBy.name.last
                }`,
                balance: Number(salesOrder.data().balance.due),
                total: Number(salesOrder.data().balance.total),
                status,
                shipDate: moment(
                  salesOrder.data().shipDate?.seconds * 1000
                ).toDate(),
                units: salesOrder.data().units,
                account: salesOrder.data().account.name,
                paymentTerm: salesOrder.data().paymentTerm,
                paymentDueDate: paymentDueDate,
              };
            })
            .sort((a, b) => (a.created > b.created ? 1 : -1))
            .filter((order) => {
              const term = this.getPaymentTerms.find(
                (paymentTerm) => paymentTerm.value === order.paymentTerm
              );
              var shouldCollect =
                moment(order.shipDate).add(term.due, "days").toDate() <=
                moment().toDate();
              return shouldCollect;
            });
        } else {
          this.response.ordersToCollect = [];
        }
      });
    },
    async getOrdersForPreviousAccountBalance() {
      let order = this.rightDrawer.data;
      this.response.ordersForPreviousAccountBalance = [];

      const ordersDoc = collection(firestore, "salesOrders");
      const relatedAccount = where("account.id", "==", order.accountID),
        relatedBrand = where("brand.id", "==", order.brand.id),
        status = where("status", "in", ["shipped", "complete"]);
      const q = query(ordersDoc, relatedAccount, relatedBrand, status);

      const response = await getDocs(q);

      if (!response.empty) {
        this.response.ordersForPreviousAccountBalance = response.docs
          .filter(
            (doc) =>
              Number(parseFloat(doc.data().balance.due).toFixed(2)) > 0 &&
              doc.id !== order.id
          )
          .map((salesOrder) => {
            const status = this.getOrderStatus.find(
              (status) => status.value === salesOrder.data().status
            );

            const term = this.getPaymentTerms.find(
              (paymentTerm) =>
                paymentTerm.value === salesOrder.data().paymentTerm
            );

            var paymentDueDate = moment(
              salesOrder.data().shipDate?.seconds * 1000
            )
              .add(term.due, "days")
              .toDate();

            return {
              id: salesOrder.id,
              created: moment(
                salesOrder.data().created?.seconds * 1000
              ).toDate(),
              updated: moment(
                salesOrder.data().updated?.seconds * 1000
              ).toDate(),
              internal: `${salesOrder.data().account.name} - #${
                salesOrder.data().orderNumber.internal
              }`,
              external: salesOrder.data().orderNumber.external,
              createdBy: `${salesOrder.data().createdBy.name.first} ${
                salesOrder.data().createdBy.name.last
              }`,
              balance: Number(salesOrder.data().balance.due),
              total: Number(salesOrder.data().balance.total),
              status,
              shipDate: moment(
                salesOrder.data().shipDate?.seconds * 1000
              ).toDate(),
              units: salesOrder.data().units,
              account: salesOrder.data().account.name,
              paymentTerm: salesOrder.data().paymentTerm,
              paymentDueDate: paymentDueDate,
            };
          })
          .sort((a, b) => (a.created > b.created ? 1 : -1))
          .filter((order) => {
            const term = this.getPaymentTerms.find(
              (paymentTerm) => paymentTerm.value === order.paymentTerm
            );
            var shouldCollect =
              moment(order.shipDate).add(term.due, "days").toDate() <=
              moment().toDate();
            return shouldCollect;
          });
      } else {
        this.response.ordersForPreviousAccountBalance = [];
      }
    },
    async getBrandMetrics() {
      let order = this.rightDrawer.data;
      this.response.brandMetrics = null;

      const accountDoc = doc(
        firestore,
        "brandStats",
        `${order.accountData.id}_${order.brand.id}`
      );
      const response = await getDoc(accountDoc);

      if (response.exists()) {
        this.response.brandMetrics = {
          ...response.data(),
          paymentTerms: response.data().paymentTerms || "[Not Set]",
        };
        if (
          response.data().paymentTerms &&
          this.getPaymentTerms.find(
            (item) => item.value === response.data().paymentTerms
          )
        ) {
          this.response.brandMetrics.paymentTerms = this.getPaymentTerms.find(
            (item) => item.value === response.data().paymentTerms
          ).text;
        }
      }
    },
    async getLineItems() {
      let order = this.rightDrawer.data;
      this.response.lineItems = [];

      const lineItems = collection(firestore, "salesOrders_lineItems");
      const related = where("relatedID", "==", order.id);
      const q = query(lineItems, related);
      const response = await getDocs(q);

      if (!response.empty) {
        this.response.lineItems = response.docs
          .map((lineItem) => {
            return {
              ...lineItem.data(),
              id: lineItem.id,
              notes: lineItem.data().notes || "",
            };
          })
          .sort((a, b) => (a.sku > b.sku ? 1 : -1));
      }
    },
    async generateInvoice() {
      let order = this.rightDrawer.data;
      this.loading.invoice = true;

      await this.getBrand();
      await this.getPayableTo();
      await this.getBrandMetrics();
      await this.getLineItems();
      await this.getOrdersToCollect();
      await this.getOrdersForPreviousAccountBalance();

      const externalOrderNumber =
        order.flags.external &&
        !["draftOrder", "mytrace_draft"].includes(order.status.value)
          ? order.orderNumber.external.toUpperCase()
          : "-";
      const internalOrderNumber = String(
        order.orderNumber.internal
      ).toUpperCase();
      const invoice = new jsPDF({ format: "letter" });
      let fileName = `${order.accountData.name.replace(
        /(\b[a-z](?!\s))/g,
        (l) => l.toUpperCase()
      )} - #${internalOrderNumber}`;

      if (["draftOrder", "mytrace_draft"].includes(order.status.value)) {
        let date = moment().format("M/D/YYYY");
        fileName = `${order.accountData.name.replace(/(\b[a-z](?!\s))/g, (l) =>
          l.toUpperCase()
        )} - Draft ${date}`;
      }

      const pageWidth = invoice.internal.pageSize.getWidth();

      // Header

      if (this.response.brand.flags.hasLogo) {
        let logoRef = ref(storage, `brand/${order.brand.id}/logo`);

        const bytes = await getBytes(logoRef);
        const metadata = await getMetadata(logoRef);

        const img =
          "data:" +
          metadata.contentType +
          ";base64," +
          new Buffer.from(bytes).toString("base64");

        invoice.addImage(img, 94, 0, 35, 35, "logo", "FAST");
        // invoice.rect(
        //   94,
        //   0,
        //   35,
        //   35,
        //   "D"
        // );
      } else {
        invoice.setFontSize(24);
        invoice.setFont("helvetica", "bold");
        let brandName = order.brand.name.replace(/\b\w/g, (l) =>
          l.toUpperCase()
        );
        invoice.text(brandName, 110, 15, "center");
        // invoice.setFontSize(20);
        // invoice.text("INVOICE", 105, 35, "center");
      }

      // Customer
      invoice.setFontSize(10);
      invoice.setFont("helvetica", "bold");
      // invoice.text("Store Information:", 10, 55);
      invoice.text("Store Information:", 10, 45);
      invoice.setFontSize(10);
      invoice.setFont("helvetica", "normal");
      const accountName = order.accountData.name.replace(
        /(\b[a-z](?!\s))/g,
        (l) => l.toUpperCase()
      );

      let phone = "";
      var cleaned = ("" + order.accountData.phoneNumber).replace(/\D/g, "");
      var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        phone = "(" + match[1] + ") " + match[2] + "-" + match[3];
      }
      const phoneNumber = "\n" + phone;

      const license = "\n" + order.accountData.license.number.toUpperCase();

      //  the .splitTextToSize() method is able to wrap text based on the max-length
      //  given as the second param
      //    - this is necessary for our 3-column invoice format, as some account names
      //      are long enough to overlap the second column
      //    - using this method, we're able to join the account's name, phone number,
      //      and license number into one string and use line breaks to force them
      //      to their own lines
      //        - we have to do this to make the store information consistent regardless
      //          of the length of the account's name

      var wrappedStoreInformation = invoice.splitTextToSize(
        accountName +
          `\n${
            order.accountData?.dba?.replace(/(\b[a-z](?!\s))/g, (l) =>
              l.toUpperCase()
            ) || "-"
          }` +
          phoneNumber +
          license,
        53
      );
      // invoice.text(wrappedStoreInformation, 10, 65);
      invoice.text(wrappedStoreInformation, 10, 50);
      // Customer - Shipping Address
      const accountShippingAddress = `${
        order.location.shipping.street.number
      } ${order.location.shipping.street.name.replace(/\b\w/g, (l) =>
        l.toUpperCase()
      )}`;
      const accountShippingCityZipCode = `${order.location.shipping.city.replace(
        /\b\w/g,
        (l) => l.toUpperCase()
      )}, ${order.location.shipping.state.abbreviation.toUpperCase()} ${
        order.location.shipping.zipCode
      }`;
      invoice.setFont("helvetica", "bold");
      invoice.setFontSize(10);
      // invoice.text("Delivery Address:", 10, 85);
      invoice.text("Delivery Address:", 10, 70);
      invoice.setFontSize(10);
      invoice.setFont("helvetica", "normal");
      var wrappedShippingAddress = invoice.splitTextToSize(
        accountShippingAddress + `\n${accountShippingCityZipCode}`,
        53
      );
      // invoice.text(wrappedShippingAddress, 10, 95);
      invoice.text(wrappedShippingAddress, 10, 75);
      // Customer - Billing Address
      const accountBillingAddress = `${
        order.location.billing.street.number
      } ${order.location.billing.street.name.replace(/\b\w/g, (l) =>
        l.toUpperCase()
      )}`;
      const accountBillingCityZipCode = `${order.location.billing.city.replace(
        /\b\w/g,
        (l) => l.toUpperCase()
      )}, ${order.location.billing.state.abbreviation.toUpperCase()} ${
        order.location.billing.zipCode
      }`;
      invoice.setFontSize(10);
      invoice.setFont("helvetica", "bold");
      // invoice.text("Billing Address:", 10, 110);
      invoice.text("Billing Address:", 10, 90);
      invoice.setFontSize(10);
      invoice.setFont("helvetica", "normal");
      if (order.accountData.flags.sameBilling) {
        // invoice.text("Same as Delivery Address", 10, 120);
        invoice.text("Same as Delivery Address", 10, 95);
      } else {
        var wrappedBillingAddress = invoice.splitTextToSize(
          accountBillingAddress + `\n${accountBillingCityZipCode}`,
          53
        );
        // invoice.text(wrappedBillingAddress, 10, 120);
        invoice.text(wrappedBillingAddress, 10, 95);
      }
      if (order.buyerInformation) {
        // Buyer - Contact Information
        // let startY = this.response.account.flags.sameBilling ? 130 : 135;
        let startY = order.accountData.flags.sameBilling ? 105 : 110;
        invoice.setFontSize(10);
        invoice.setFont("helvetica", "bold");
        invoice.text("Buyer Information:", 10, startY);
        invoice.setFontSize(10);
        invoice.setFont("helvetica", "normal");
        let buyerName = order.buyerInformation.name.replace("PARENT - ", "");
        buyerName = buyerName.replace(/\b\w/g, (l) => l.toUpperCase());
        // invoice.text(buyerName, 10, startY + 10);
        invoice.text(buyerName, 10, startY + 5);
        invoice.text(order.buyerInformation.email, 10, startY + 10);
      }

      // Vertical Divider
      invoice.setDrawColor("#F2F5F6");
      invoice.line(70, 45, 70, 115, "S");
      invoice.stroke();

      // Licensee
      invoice.setFontSize(10);
      invoice.setFont("helvetica", "bold");
      // invoice.text("Licensee:", 80, 55);
      invoice.text("Licensee:", 80, 45);
      invoice.setFontSize(10);
      invoice.setFont("helvetica", "normal");
      const payableToName = this.response.payableTo.name.replace(/\b\w/g, (l) =>
        l.toUpperCase()
      );

      let payableToPhone = "";
      var cleaned = ("" + this.response.payableTo.phoneNumber).replace(
        /\D/g,
        ""
      );
      var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        payableToPhone = "(" + match[1] + ") " + match[2] + "-" + match[3];
      }
      const payableToPhoneNumber = "\n" + payableToPhone;

      const payableToLicense =
        "\n" + this.response.payableTo.license.number.toUpperCase();

      var wrappedPayableToInformation = invoice.splitTextToSize(
        payableToName + payableToPhoneNumber + payableToLicense,
        50
      );
      // invoice.text(wrappedPayableToInformation, 80, 65);
      invoice.text(wrappedPayableToInformation, 80, 50);
      // Payable To - Address
      const payableToStreetAddress =
        !this.response.payableTo.location.street?.name ||
        !this.response.payableTo.location.street?.number
          ? "[No Address Set]"
          : `${
              this.response.payableTo.location.street.number
            } ${this.response.payableTo.location.street.name.replace(
              /\b\w/g,
              (l) => l.toUpperCase()
            )}`;
      invoice.setFont("helvetica", "bold");
      invoice.setFontSize(10);
      // invoice.text("Address:", 80, 85);
      invoice.text("Address:", 80, 70);
      invoice.setFontSize(10);
      invoice.setFont("helvetica", "normal");
      // invoice.text(payableToAddress, 80, 95);

      let payableToAddress = `${payableToStreetAddress}\n${this.response.payableTo.location.city.replace(
        /\b\w/g,
        (l) => l.toUpperCase()
      )}, ${this.response.payableTo.location.state.abbreviation.toUpperCase()} ${
        this.response.payableTo.location.zipCode
      }`;

      // invoice.text(
      //   `\n${this.response.payableTo.location.city.replace(/\b\w/g, (l) =>
      //     l.toUpperCase()
      //   )}, ${this.response.payableTo.location.state.abbreviation.toUpperCase()} ${
      //     this.response.payableTo.location.zipCode
      //   }`,
      //   80,
      //   100
      // );

      var wrappedPayableToAddress = invoice.splitTextToSize(
        payableToAddress,
        50
      );
      // invoice.text(wrappedPayableToAddress, 80, 95);
      invoice.text(wrappedPayableToAddress, 80, 75);

      // Vertical Divider
      invoice.setDrawColor("#F2F5F6");
      invoice.line(135, 45, 135, 115, "S");
      invoice.stroke();

      // Overview
      invoice.setFontSize(10);
      invoice.setFont("helvetica", "bold");
      // invoice.text("Brand:", 145, 55);
      invoice.text("Brand:", 145, 45);
      invoice.setFont("helvetica", "normal");
      invoice.setFontSize(10);
      // invoice.text("Payment Term", 145, 65);
      invoice.text("Payment Term", 145, 50);
      invoice.setFont("helvetica", "normal");

      // invoice.text(order.paymentTermDef.text, 205, 65, {
      invoice.text(order.paymentTermDef.text, 205, 50, {
        align: "right",
      });
      // invoice.text("External Invoice", 145, 70);
      invoice.text("External Invoice", 145, 55);
      invoice.setFont("helvetica", "normal");

      // invoice.text(externalOrderNumber, 205, 70, {
      invoice.text(externalOrderNumber, 205, 55, {
        align: "right",
      });
      // invoice.text("Ship Date", 145, 75);
      invoice.text("Ship Date", 145, 60);
      invoice.setFont("helvetica", "normal");
      if (order.shipDate) {
        invoice.text(
          moment(order.shipDate).format("MMM DD, YYYY"),
          205,
          // 75,
          60,
          {
            align: "right",
          }
        );
      } else {
        // invoice.text("-", 205, 75, {
        invoice.text("-", 205, 60, {
          align: "right",
        });
      }
      // invoice.text("Created", 145, 80);
      invoice.text("Created", 145, 65);
      invoice.setFont("helvetica", "normal");
      invoice.text(
        moment(order.created).format("MMM DD, YYYY"),
        205,
        // 80,
        65,
        {
          align: "right",
        }
      );
      invoice.setFont("helvetica", "normal");
      // invoice.text("Created By", 145, 85);
      invoice.text("Created By", 145, 70);
      invoice.setFont("helvetica", "normal");
      const createdByName = order.createdBy;
      invoice.text(
        this.firstNameWithLastInitialFromValue(createdByName),
        205,
        // 85,
        70,
        {
          align: "right",
        }
      );
      invoice.setFontSize(10);
      invoice.setFont("helvetica", "bold");
      // invoice.text("Account:", 145, 95);
      invoice.text("Account:", 145, 80);
      invoice.setFontSize(10);
      invoice.setFont("helvetica", "normal");

      let hasExecutive =
        this.response.brandMetrics.staff.executive.id &&
        this.response.brandMetrics.staff.executive.id.length > 0;
      let hasManager =
        this.response.brandMetrics.staff.manager.id &&
        this.response.brandMetrics.staff.manager.id.length > 0;

      if (hasExecutive) {
        const userDoc = doc(
          firestore,
          "users",
          this.response.brandMetrics.staff.executive.id
        );
        const response = await getDoc(userDoc);

        let executivePhoneNumber = "-";

        if (response.exists()) {
          executivePhoneNumber = response.data().phoneNumber
            ? this.$options.filters.phoneNumber(response.data().phoneNumber)
            : "-";
        }

        // invoice.text("Executive", 145, 105);
        invoice.text("Executive", 145, 85);
        invoice.setFont("helvetica", "normal");
        invoice.text(
          this.firstNameWithLastInitialFromValue(
            this.response.brandMetrics.staff.executive.name
          ),
          205,
          // 105,
          85,
          {
            align: "right",
          }
        );
        // invoice.text(executivePhoneNumber, 205, 110, {
        invoice.text(executivePhoneNumber, 205, 90, {
          align: "right",
        });
        invoice.text(
          this.response.brandMetrics.staff.executive.email,
          205,
          // 115,
          95,
          {
            align: "right",
          }
        );
      }
      invoice.setFont("helvetica", "normal");
      if (hasManager) {
        const userDoc = doc(
          firestore,
          "users",
          this.response.brandMetrics.staff.manager.id
        );
        const response = await getDoc(userDoc);

        let managerPhoneNumber = "-";

        if (response.exists()) {
          managerPhoneNumber = response.data().phoneNumber
            ? this.$options.filters.phoneNumber(response.data().phoneNumber)
            : "-";
        }

        invoice.text("Manager", 145, hasExecutive ? 105 : 85);
        invoice.setFont("helvetica", "normal");
        invoice.text(
          this.firstNameWithLastInitialFromValue(
            this.response.brandMetrics.staff.manager.name
          ),
          205,
          hasExecutive ? 105 : 85,
          {
            align: "right",
          }
        );
        invoice.text(managerPhoneNumber, 205, hasExecutive ? 110 : 90, {
          align: "right",
        });
        invoice.text(
          this.response.brandMetrics.staff.manager.email,
          205,
          hasExecutive ? 115 : 95,
          {
            align: "right",
          }
        );
      }

      // Divider
      let productsTableDividerY =
        !order.accountData.flags.sameBilling && order.buyerInformation
          ? 130
          : 120;

      invoice.setDrawColor("#F2F5F6");
      invoice.line(10, productsTableDividerY, 205, productsTableDividerY, "S");
      invoice.stroke();
      //   Products
      invoice.setFontSize(10);
      invoice.setFont("helvetica", "bold");
      invoice.text("Products:", 10, productsTableDividerY + 10);

      let lineItems = this.response.lineItems;
      if (["draftOrder", "mytrace_draft"].includes(order.status.value)) {
        lineItems = order.lineItems;
      }

      let getListPriceForProduct = (product) => {
        let termNeeded =
          this.getRightDrawer.data.paymentTermDef.value === "cod"
            ? "cod"
            : "net";

        if (product.listPrice?.[termNeeded]) {
          return product.listPrice?.[termNeeded];
        }

        return product.price.normal;
      };

      let products = lineItems
        .filter((item) => !item.promo)
        .sort((a, b) => {
          if (a.categoryName > b.categoryName) {
            return 1;
          } else if (a.categoryName === b.categoryName && a.name > b.name) {
            return 1;
          } else if (a.categoryName === b.categoryName && a.name === b.name) {
            return 0;
          } else if (a.categoryName === b.categoryName && a.name < b.name) {
            return -1;
          } else if (a.categoryName < b.categoryName) {
            return -1;
          }
        })
        .map((item) => {
          let productName = this.$options.filters.capitalize(item.name);
          let categoryName = this.$options.filters.capitalize(
            item.categoryName
          );
          let notes = item.notes?.length > 0 ? item.notes : `                 `;
          item.price.normal = getListPriceForProduct(item);
          return [
            `${productName}\n${categoryName}`,
            item.sample ? `SAMPLE\n${notes}` : notes,
            // `${item.sample ? "Yes" : "No"}`,
            this.$options.filters.addComma(item.cases),
            this.$options.filters.addComma(item.singles),
            this.$options.filters.addComma(item.qty),
            this.$options.filters.currency(item.price.normal),
            this.$options.filters.currency(item.amount),
            this.$options.filters.currency(item.qty * item.amount),
            item.discount?.value ?? 0, // Case Discount Value
          ];
        });
      let promoUnits = lineItems.filter((item) => item.promo);
      if (promoUnits.length > 0) {
        promoUnits = promoUnits.sort((a, b) => {
          if (a.categoryName > b.categoryName) {
            return 1;
          } else if (a.categoryName === b.categoryName && a.name > b.name) {
            return 1;
          } else if (a.categoryName === b.categoryName && a.name === b.name) {
            return 0;
          } else if (a.categoryName === b.categoryName && a.name < b.name) {
            return -1;
          } else if (a.categoryName < b.categoryName) {
            return -1;
          }
        });

        products.push([
          {
            content: "",
            colSpan: 8,
            styles: { halign: "center", fillColor: 255 },
          },
        ]);
        products.push([
          {
            content: "PROMO UNITS",
            colSpan: 8,
            styles: {
              halign: "center",
              fillColor: 255,
              textColor: 0,
              fontStyle: "bold",
            },
          },
        ]);
      }
      promoUnits.forEach((item) => {
        let productName = this.$options.filters.capitalize(item.name);
        let categoryName = this.$options.filters.capitalize(item.categoryName);

        let notes = item.notes?.length > 0 ? item.notes : `                 `;
        item.price.normal = getListPriceForProduct(item);
        products.push([
          `${productName}\n${categoryName}`,
          item.sample ? `SAMPLE\n${notes}` : notes,
          // `${item.sample ? "Yes" : "No"}`,
          this.$options.filters.addComma(item.cases),
          this.$options.filters.addComma(item.singles),
          this.$options.filters.addComma(item.qty),
          this.$options.filters.currency(item.price.normal),
          this.$options.filters.currency(item.amount),
          this.$options.filters.currency(item.qty * item.amount),
          0, // Case Discount Value: Promo Items can not receive case discounts
        ]);
      });
      invoice.autoTable({
        columns: [
          { dataKey: "item", header: "Item" },
          { dataKey: "notes", header: "Notes" },
          { dataKey: "cases", header: "Cases" },
          { dataKey: "singles", header: "Singles" },
          { dataKey: "units", header: "Units" },
          { dataKey: "listPrice", header: "List Price" },
          { dataKey: "salePrice", header: "Sale Price" },
          { dataKey: "total", header: "Total" },
        ],
        body: products,
        startY: productsTableDividerY + 15,
        margin: {
          right: 10,
          left: 10,
          bottom: 15,
          top: 20,
        },
        theme: "striped",
        headStyles: {
          fillColor: "black",
          textColor: "white",
          fontSize: 9,
        },
        columnStyles: {
          0: { halign: "left" },
          1: { halign: "left" },
          2: { halign: "right" },
          3: { halign: "right" },
          4: { halign: "right" },
          5: { halign: "right" },
          6: { halign: "right" },
          7: { halign: "right" },
        },
        bodyStyles: {
          fontSize: 9,
        },
        didParseCell: (data) => {
          if (data.section === "head") {
            if (data.column.index > 1) {
              data.cell.styles.halign = "right";
            }
          }
        },
        willDrawCell: (data) => {
          if (data.section === "body") {
            if (data.column.index === 0) {
              const cell = data.cell;
              cell.text = [];
            }
          }
        },
        didDrawCell: (data) => {
          if (data.section === "body" && data.row.cells.salePrice) {
            data.row.cells.salePrice.styles.fontStyle = "bold";
          }

          if (
            data.section === "body" &&
            data.column.dataKey === "total" &&
            data.row.cells.listPrice
          ) {
            let listPrice = Number(
              parseFloat(data.row.cells.listPrice.raw.replace("$", "")).toFixed(
                2
              )
            );
            let salePrice = Number(
              parseFloat(data.row.cells.salePrice.raw.replace("$", "")).toFixed(
                2
              )
            );

            if (salePrice < listPrice) {
              let discountAmount = Number(
                parseFloat(listPrice - salePrice).toFixed(2)
              );
              let totalSaved = this.$options.filters.currency(
                discountAmount *
                  Number(
                    parseFloat(
                      data.row.cells.units.raw.replace(",", "")
                    ).toFixed(2)
                  )
              );
              let caseDiscountAppliedAmount = data.row.raw[8];
              let discountString =
                caseDiscountAppliedAmount > 0
                  ? `${this.$options.filters.currency(
                      caseDiscountAppliedAmount
                    )} CASE DISCOUNT / TOTAL DISCOUNT: ${totalSaved}`
                  : `TOTAL DISCOUNT: ${totalSaved}`;
              invoice.setFont("helvetica", "bolditalic");
              invoice.setFontSize(6);
              invoice.text(
                data.cell.x + data.cell.width - 2,
                data.cell.y + 9,
                discountString,
                { align: "right" }
              );
            }
          } else if (data.section === "body" && data.column.index === 0) {
            const cell = data.cell;
            const text = cell.raw ?? "";
            let splitInfo = cell.colSpan > 1 ? [] : text.split("\n");
            let isHeaderRow = cell.colSpan > 1;

            let { x, y, width } = cell;
            if (isHeaderRow) {
              invoice.setFont("helvetica", "normal");
              invoice.text(text.content, pageWidth / 2, y + 5, {
                align: "center",
              });
            } else {
              let cursorX = x;
              splitInfo.forEach((part, i) => {
                if (i === 0) {
                  invoice.setFont("helvetica", "bold");
                  invoice.text(part, cursorX + 2, y + 4.5);
                  y += invoice.getTextDimensions(part).h;
                } else {
                  invoice.setFont("helvetica", "italic");

                  invoice.setFontSize(8);
                  invoice.text(part, cursorX + 2, y + 5);
                  invoice.setFont("helvetica", "normal");

                  invoice.setFontSize(9);
                }
              });
            }
          }
        },
      });

      //  We need to check the `pageNumber` property of the products table to see if it
      //    overflowed past page 1.
      //
      //    - If it did, jspdf's `previousAutoTable` has a `finalY`
      //    property that we can add to the y values of all of the content after the
      //    product's table to place the content below the products table correctly.
      //
      //    - If the table did not overflow , we set this value to 0 as we
      //    do not have to modify the position of anything on the second page

      if (
        invoice.previousAutoTable.pageNumber === 1 ||
        invoice.previousAutoTable.finalY > 160
      ) {
        invoice.addPage();
        invoice.setPage(invoice.previousAutoTable.pageNumber + 1);
      }

      const productsTableFinalY =
        invoice.previousAutoTable.pageNumber === 1 ||
        invoice.previousAutoTable.finalY > 160
          ? 10
          : invoice.previousAutoTable.finalY;

      // Notes
      invoice.setFont("helvetica", "bold");
      invoice.setFontSize(10);
      invoice.text(10, 15 + productsTableFinalY, `Notes:`);
      invoice.setFont("helvetica", "italic");
      invoice.setFontSize(9);

      var wrappedNotesForDimensions = invoice.splitTextToSize(order.notes, 100);
      let notesDimensions = invoice.getTextDimensions(
        wrappedNotesForDimensions
      );
      let notesSubstring =
        order.notes.length < 600 && notesDimensions.h <= 52.5
          ? order.notes
          : order.notes.substr(0, 600) + "...";

      var wrappedNotes = invoice.splitTextToSize(notesSubstring, 100);
      invoice.text(wrappedNotes, 10, 25 + productsTableFinalY);

      // Delivery Preferences
      invoice.setFont("helvetica", "bold");
      invoice.setFontSize(10);
      invoice.text(10, 85 + productsTableFinalY, `Delivery Preferences:`);
      invoice.setFont("helvetica", "italic");
      invoice.setFontSize(9);

      var wrappedDeliveryPreferences = invoice.splitTextToSize(
        order.accountData.deliveryPreferences.notes,
        pageWidth - 20
      );
      let deliveryPreferencesDimensions = invoice.getTextDimensions(
        wrappedDeliveryPreferences
      );
      invoice.text(wrappedDeliveryPreferences, 10, 95 + productsTableFinalY);

      // Balance
      invoice.setFont("helvetica", "normal");
      invoice.setFontSize(10);

      invoice.text(
        `Subtotal (Qty: ${this.$options.filters.addComma(
          lineItems.reduce((a, b) => a + b.qty, 0)
        )})`,
        125,
        17.5 + productsTableFinalY
      );

      invoice.text(
        Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "usd",
        }).format(
          lineItems.reduce((a, b) => a + b.amount * b.qty + b.tax, 0) +
            this.totalSaved
        ),
        205,
        17.5 + productsTableFinalY,
        {
          align: "right",
        }
      );
      invoice.setDrawColor("#E5E7EB");
      invoice.line(
        120,
        20 + productsTableFinalY,
        205,
        20 + productsTableFinalY,
        "S"
      );

      invoice.text(`List Price Discounts`, 125, 25 + productsTableFinalY);

      invoice.text(
        `${
          this.totalSaved - this.totalCaseDiscounts > 0 ? "-" : ""
        }${Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "usd",
        }).format(this.totalSaved - this.totalCaseDiscounts)}`,
        205,
        25 + productsTableFinalY,
        {
          align: "right",
        }
      );
      invoice.line(
        120,
        27.5 + productsTableFinalY,
        205,
        27.5 + productsTableFinalY,
        "S"
      );
      invoice.stroke();
      // if (!["draftOrder", "mytrace_draft"].includes(order.status.value)) {
      invoice.text("Case Discounts", 125, 32.5 + productsTableFinalY);
      invoice.text(
        `${this.totalCaseDiscounts > 0 ? "-" : ""}${Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "usd",
        }).format(this.totalCaseDiscounts)}`,
        205,
        32.5 + productsTableFinalY,
        {
          align: "right",
        }
      );
      invoice.setDrawColor("#E5E7EB");
      invoice.line(
        120,
        35 + productsTableFinalY,
        205,
        35 + productsTableFinalY,
        "S"
      );
      invoice.stroke();
      // }
      invoice.text("Shipping", 125, 40 + productsTableFinalY);
      invoice.text(
        Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "usd",
        }).format(order.fullBalance.shipping),
        205,
        40 + productsTableFinalY,
        {
          align: "right",
        }
      );
      invoice.setDrawColor("#E5E7EB");
      invoice.line(
        120,
        42.5 + productsTableFinalY,
        205,
        42.5 + productsTableFinalY,
        "S"
      );
      invoice.stroke();
      if (!["draftOrder", "mytrace_draft"].includes(order.status.value)) {
        invoice.text("Payments Received", 125, 47.5 + productsTableFinalY);
        invoice.text(
          `${order.total - order.balance > 0 ? "-" : ""}${Intl.NumberFormat(
            "en-US",
            {
              style: "currency",
              currency: "usd",
            }
          ).format(
            Number(
              parseFloat(
                this.response.lineItems.reduce(
                  (a, b) => a + b.amount * b.qty + b.tax,
                  0
                ) +
                  order.fullBalance.excise +
                  order.fullBalance.shipping
              ).toFixed(2)
            ) - order.balance
          )}`,
          205,
          47.5 + productsTableFinalY,
          {
            align: "right",
          }
        );
        invoice.setDrawColor("#E5E7EB");
        invoice.line(
          120,
          50 + productsTableFinalY,
          205,
          50 + productsTableFinalY,
          "S"
        );
        invoice.stroke();
      }
      invoice.text(
        !["draftOrder", "mytrace_draft"].includes(order.status.value)
          ? "Total Due"
          : "Total",
        125,
        !["draftOrder", "mytrace_draft"].includes(order.status.value)
          ? 55 + productsTableFinalY
          : 47.5 + productsTableFinalY
      );
      invoice.text(
        Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "usd",
        }).format(order.balance),
        205,
        !["draftOrder", "mytrace_draft"].includes(order.status.value)
          ? 55 + productsTableFinalY
          : 47.5 + productsTableFinalY,
        {
          align: "right",
        }
      );
      // invoice.setDrawColor("#E5E7EB");
      // invoice.line(
      //   120,
      //   !["draftOrder", "mytrace_draft"].includes(order.status.value)
      //     ? 57 + productsTableFinalY
      //     : 37 + productsTableFinalY,
      //   205,
      //   !["draftOrder", "mytrace_draft"].includes(order.status.value)
      //     ? 57 + productsTableFinalY
      //     : 37 + productsTableFinalY,
      //   "S"
      // );
      // invoice.stroke();

      if (!["draftOrder", "mytrace_draft"].includes(order.status.value)) {
        // Account Balance Rect
        invoice.setDrawColor(0);
        invoice.setFillColor("#F2F5F6");
        invoice.roundedRect(
          122.5,
          65 + productsTableFinalY,
          85,
          15,
          2,
          2,
          "FD"
        );

        invoice.setFont("helvetica", "normal");
        invoice.setFontSize(10);
        invoice.text(125, 70 + productsTableFinalY, `Previous Account Balance`);
        invoice.text(
          this.$options.filters.currency(
            this.response.ordersForPreviousAccountBalance.reduce(
              (a, b) => a + b.balance,
              0
            )
          ),
          205,
          70 + productsTableFinalY,
          {
            align: "right",
          }
        );
        invoice.setFont("helvetica", "bold");
        invoice.setFontSize(10);
        invoice.text(125, 77.5 + productsTableFinalY, `New Account Balance`);
        invoice.text(
          Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "usd",
          }).format(
            this.response.ordersForPreviousAccountBalance.reduce(
              (a, b) => a + b.balance,
              0
            ) + Number(order.balance)
          ),
          205,
          77.5 + productsTableFinalY,
          {
            align: "right",
          }
        );

        invoice.setFont("helvetica", "italic");
        invoice.setFontSize(9);
        let wrappedDisclaimer = invoice.splitTextToSize(
          "If a payment has been submitted in the last 10 days, the account balance may not reflect until it's been processed.",
          85
        );

        invoice.text(wrappedDisclaimer, 165, 85 + productsTableFinalY, {
          align: "center",
        });
      }

      let startYPosition =
        115 + productsTableFinalY + deliveryPreferencesDimensions.h;

      if (startYPosition > 225) {
        invoice.addPage();
        invoice.setPage(invoice.internal.getNumberOfPages() + 1);
        startYPosition = 20;
      }

      //   Open Invoices

      if (!["draftOrder", "mytrace_draft"].includes(order.status.value)) {
        // Divider
        invoice.setDrawColor("#F2F5F6");
        invoice.line(10, startYPosition, 205, startYPosition, "S");
        invoice.stroke();

        invoice.setFontSize(10);
        invoice.setFont("helvetica", "bold");
        invoice.text("Open Invoices:", 10, startYPosition + 10);
        const orders = this.response.ordersToCollect.map((item) => {
          var shipDate = moment(item.shipDate).format("MMM DD, YYYY");
          var paymentDueDate = moment(item.paymentDueDate).format(
            "MMM DD, YYYY"
          );
          var paymentTerm = this.getPaymentTerms.find(
            (paymentTerm) => paymentTerm.value === item.paymentTerm
          );

          let externalOrderNumber =
            item.external && item.external.length > 0
              ? item.external.toUpperCase()
              : "-";

          return [
            shipDate,
            paymentDueDate,
            paymentTerm.text,
            Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "usd",
            }).format(item.balance),
            item.internal.toUpperCase(),
            externalOrderNumber,
          ];
        });
        if (orders.length > 0) {
          invoice.autoTable({
            head: [
              [
                "Ship Date",
                "Payment Due Date",
                "Payment Term",
                "Balance",
                "Internal Order #",
                "External Order #",
              ],
            ],
            body: orders,
            startY: startYPosition + 15,
            margin: {
              right: 10,
              left: 10,
              bottom: 15,
              top: 20,
            },
            theme: "striped",
            headStyles: {
              fillColor: "#4261c7",
              textColor: "white",
            },
            bodyStyles: {
              fontSize: 9,
            },
          });
          startYPosition = invoice.previousAutoTable.finalY + 10;
        } else {
          invoice.setTextColor(100);
          invoice.setFont("helvetica", "normal");
          invoice.setFontSize(10);
          invoice.text("No Open Invoices", 110, startYPosition + 20, {
            align: "center",
          });
          invoice.setTextColor("black");
          startYPosition = startYPosition + 30;
        }
      } else {
        startYPosition = startYPosition + 10;
      }

      if (this.response.payableTo.invoiceURLData?.url?.length) {
        if (startYPosition > 225) {
          invoice.addPage();
          invoice.setPage(invoice.internal.getNumberOfPages());
        }

        startYPosition = startYPosition > 225 ? 20 : startYPosition;

        // Divider
        invoice.setDrawColor("#F2F5F6");
        invoice.line(10, startYPosition, 205, startYPosition, "S");
        invoice.stroke();

        //   External Links
        invoice.setFontSize(10);
        invoice.setFont("helvetica", "bold");
        invoice.text("External Links:", 10, startYPosition + 10);

        let QRCode = require("qrcode");
        let opts = {
          errorCorrectionLevel: "H",
          type: "image/jpeg",
          quality: 0.3,
          margin: 1,
          color: {
            dark: "#000000FF",
            light: "#FFFFFFFF",
          },
        };

        QRCode.toDataURL(
          this.response.payableTo.invoiceURLData.url,
          opts,
          function (err, url) {
            if (err) throw err;

            invoice.addImage(
              url,
              10,
              startYPosition + 15,
              20,
              20,
              "licenseeURL",
              "FAST"
            );
          }
        );

        invoice.setFont("helvetica", "normal");
        invoice.setFontSize(9);

        invoice.text(
          this.response.payableTo.invoiceURLData.url,
          10,
          startYPosition + 40
        );

        let wrappedDescription = invoice.splitTextToSize(
          this.response.payableTo.invoiceURLData.description,
          pageWidth - 20
        );
        invoice.text(wrappedDescription, 10, startYPosition + 45);
      }

      // END
      var pageCount = invoice.internal.getNumberOfPages();
      for (let i = 1; i <= pageCount; i++) {
        invoice.setPage(i);
        invoice.setFontSize(10);
        invoice.setFont("helvetica", "bold");
        if (["draftOrder", "mytrace_draft"].includes(order.status.value)) {
          invoice.text("invoice - Draft".toUpperCase(), 10, 10);
          invoice.setFont("helvetica", "normal");
          invoice.setFontSize(10);
          var orderNumber = invoice.splitTextToSize(
            `This order is pending submission.`,
            70
          );
          invoice.text(10, 15, orderNumber);
        } else {
          invoice.text("invoice".toUpperCase(), 10, 10);
          invoice.setFont("helvetica", "normal");
          invoice.setFontSize(10);
          var orderNumber = invoice.splitTextToSize(
            `Order #: ${internalOrderNumber}`,
            70
          );
          invoice.text(10, 15, orderNumber);
        }
        invoice.setFont("helvetica", "normal");
        invoice.setFontSize(8);
        invoice.text(
          110,
          270,
          `Page ${
            invoice.internal.getCurrentPageInfo().pageNumber
          } / ${pageCount}`,
          {
            align: "center",
          }
        );

        // Powered by MyTrace
        var img = new Image();
        img.src = "/img/myTrace_fullLogo.png";
        invoice.addImage(
          img,
          "png",
          pageWidth - 28,
          5,
          20,
          9,
          "mytraceLogo",
          "FAST"
        );
        invoice.setFont("helvetica", "normal");
        invoice.setFontSize(7);
        invoice.text(pageWidth - 26, 10, `Powered by`, {
          align: "right",
        });

        if (i === 1) {
          let QRCode = require("qrcode");
          let opts = {
            errorCorrectionLevel: "H",
            type: "image/jpeg",
            quality: 0.3,
            margin: 1,
            color: {
              dark: "#000000FF",
              light: "#FFFFFFFF",
            },
          };

          // QRCode.toDataURL(
          //   "https://app.mytrace.com",
          //   opts,
          //   function (err, url) {
          //     if (err) throw err;

          //     invoice.addImage(
          //       url,
          //       pageWidth - 25,
          //       12.5,
          //       15,
          //       15,
          //       "mytraceURL",
          //       "FAST"
          //     );
          //   }
          // );
          // invoice.setFontSize(6);
          // let wrappedQRText = invoice.splitTextToSize(
          //   "Scan to place an order today!",
          //   15
          // );
          // invoice.text(pageWidth - 17.5, 30, wrappedQRText, {
          //   align: "center",
          // });
        }
      }

      let externalNumber = order.flags.external
        ? ` (${order.orderNumber.external.toUpperCase()})`
        : "";

      fileName = `${order.accountData.name.replace(/(\b[a-z](?!\s))/g, (l) =>
        l.toUpperCase()
      )} - #${internalOrderNumber}${externalNumber}`;

      if (["draftOrder", "mytrace_draft"].includes(order.status.value)) {
        let date = moment().format("M/D/YYYY");
        fileName = `${order.accountData.name.replace(/(\b[a-z](?!\s))/g, (l) =>
          l.toUpperCase()
        )} - Draft ${date}`;
      }

      // invoice.output("dataurlnewwindow");      //  Opens in a new tab, doesn't work on iOS/Safari
      invoice.save(`${fileName}.pdf`);
      this.loading.invoice = false;
    },
    /* Misc */
    viewResource(type, value) {
      switch (type) {
        case "phone":
          window.open(`tel:${value}`);
          break;
        case "email":
          window.open(`mailto:${value}`);
          break;
        case "url":
          window.open(value, "_blank");
          break;
      }
    },
    loadAddress() {
      window.open(
        `https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(
          this.deliveryAddress
        )}`,
        "_blank"
      );
    },
    firstNameWithLastInitialFromValue(name) {
      if (name.length > 0) {
        let capitalizedName = name
          .split(" ")
          .map((word) => `${word.charAt(0).toUpperCase()}${word.slice(1)}`);
        return `${capitalizedName[0]} ${capitalizedName[1].charAt(0)}.`;
      } else {
        return "[Name Not Set]";
      }
    },
  },
  computed: {
    ...mapGetters([
      "getAppName",
      "getUser",
      "getRightDrawer",
      "getOrderStatus",
      "getOrderStatus",
      "getPaymentTerms",
    ]),
    rightDrawer: {
      get: function () {
        return this.getRightDrawer;
      },
      set: function (value) {
        this._rightDrawer({
          ...this.getRightDrawer,
          active: value,
        });
      },
    },
    licenseExpired() {
      let expirationDate = "",
        daysUntilExpired = 0;
      if (this.getRightDrawer.data.accountData.license.expiration) {
        expirationDate = moment(
          this.getRightDrawer.data.accountData.license.expiration.seconds * 1000
        ).format("MM/DD/YYYY");
        daysUntilExpired = moment().diff(expirationDate, "days");
      }

      return daysUntilExpired > 0 ? true : false;
    },
    deliveryAddress() {
      if (!this.getRightDrawer.data?.location) return null;
      const unitNumber = this.getRightDrawer.data?.location?.shipping
        ?.unitNumber
        ? `${this.getRightDrawer.data.location.shipping.unitNumber} `
        : "";

      return `${unitNumber}${
        this.getRightDrawer.data.location.shipping.street.number
      } ${this.$options.filters.capitalize(
        this.getRightDrawer.data.location.shipping.street.name
      )}, ${this.$options.filters.capitalize(
        this.getRightDrawer.data.location.shipping.city
      )} ${this.getRightDrawer.data.location.shipping.state.abbreviation.toUpperCase()}, ${
        this.getRightDrawer.data.location.shipping.zipCode
      }`;
    },
    totalSaved() {
      let lineItems = this.response.lineItems.length
        ? this.response.lineItems
        : this.rightDrawer?.data?.lineItems ?? [];
      return lineItems
        .filter((item) => item.amount < item.price.normal)
        .reduce((sum, item) => {
          let discountAmount = item.price.normal - item.amount;
          return sum + discountAmount * item.qty;
        }, 0);
    },
    totalCaseDiscounts() {
      let lineItems = this.response.lineItems.length
        ? this.response.lineItems
        : this.rightDrawer?.data?.lineItems ?? [];
      return lineItems
        .filter((item) => item.discount?.value > 0)
        .reduce((sum, item) => {
          return sum + item.discount.value * item.qty;
        }, 0);
    },
    groupedLineItems() {
      return (
        this.rightDrawer?.data?.lineItems?.slice().sort((a, b) => {
          if (a.categoryName > b.categoryName) {
            return 1;
          } else if (a.categoryName === b.categoryName && a.name > b.name) {
            return 1;
          } else if (a.categoryName === b.categoryName && a.name === b.name) {
            return 0;
          } else if (a.categoryName === b.categoryName && a.name < b.name) {
            return -1;
          } else if (a.categoryName < b.categoryName) {
            return -1;
          }
        }) ?? []
      );
    },
    promoUnitQty() {
      if (!this.rightDrawer?.data) return 0;
      return this.rightDrawer.data.lineItems
        .filter((item) => item.promo)
        .reduce((sum, item) => sum + item.qty, 0);
    },
    promoUnitValue() {
      if (!this.rightDrawer?.data) return 0;
      return this.rightDrawer.data.lineItems
        .filter((item) => item.promo)
        .reduce((sum, item) => sum + item.qty * item.price.normal, 0);
    },
    promoUnitPercentage() {
      if (!this.rightDrawer?.data) return 0;
      let promo = this.rightDrawer.data.lineItems
        .filter((item) => item.promo)
        .reduce((sum, item) => sum + item.qty * item.price.normal, 0);
      let sale = this.rightDrawer.data.lineItems
        .filter((item) => !item.promo)
        .reduce((sum, item) => sum + item.qty * item.price.sale, 0);

      return (promo / sale) * 100;
    },
  },
};
</script>
